import { get } from 'lodash'

import { cms, tidyName } from '../../helper'
import { store } from '../../Redux/Config/configureStore'

export const getTabNameForLogs = (parentPageName, tabName) => {
  const cmsPage = store.getState().CMS.coreData?.page
  let page = cmsPage?.[parentPageName]
  let currentTab = page?.component_call_buttons?.find((tab) => tab?.name === tabName)
  if (currentTab) {
    return cms(currentTab?.cms_tab_key)
  } else {
    return tabName
  }
}

export const getFieldsAndLabelByTab = (parentPageName, tabName) => {
  const cmsPage = store.getState().CMS.coreData?.page
  let page = cmsPage?.[parentPageName]
  let currentTab = page?.component_call_buttons?.find((tab) => tab?.name === tabName)

  let fields = []
  currentTab?.component_call_form?.[0]?.component_call_fields?.map((field) => {
    fields?.push({
      field_id: field?.field_id,
      label: cms(field?.cms_key),
      type: field?.component_name,
    })
  })
  return fields
}

export const getValueByFieldTypeNid = (data, type, field_id) => {
  switch (type) {
    case 'toggle_switch':
      const v = get(data, field_id)
      if (v) {
        return <span class='badge badge-pill badge-success'>On</span>
      } else {
        return <span class='badge badge-pill badge-danger'>Off</span>
      }
    case 'select':
      if (field_id === 'role_id') return data?.role?.name
      return get(data, `${field_id}_label`) || tidyName(get(data, `${field_id}`))
    default:
      return get(data, field_id)
  }
}

export const checkIsDataUpdated = (oldData, newData, field_id) => {
  console.log('is data updated::::::::', oldData, get(oldData, field_id), get(newData, field_id))
  if (get(oldData, field_id) != get(newData, field_id)) {
    return true
  }
  return false
}

export const checkIsFieldHide = (field_id) =>{
  const fields = ['password', 'password_confirmation', 'merged_user']
  if(fields?.includes(field_id)){
    return true
  }
  return false
}

export const checkIsDataUpdatedInLog = (oldData,newData,fields) =>{
  let isDataUpdated = false
  fields?.map((field)=>{
    if(!checkIsFieldHide(field?.field_id)){
      if(checkIsDataUpdated(oldData,newData,field?.field_id)){
        isDataUpdated = true
      }
    }
  })
  return isDataUpdated

}

import useMediaQuery from '../../CustomHook/useMediaQuery'
import { primary_color } from '../../Helper/uiHelper'
import { cms } from '../../helper'
import { useRef, useState } from 'react'
import CMSWidgetModal from '../CMSWidget/cmsWidgetModal'
import CMSHeroSearch from '../CMSWidget/HeroSearch/cmsHeroSearch'
import CMSCompanyInfo from '../CMSWidget/PaymentSuccess/cmsCompanyInfo'
import { useSelector } from 'react-redux'

function _getStudentData(data, component_name, fields) {
  const unitData = data?.units
  const studentData = []
  const companyData = []
  if (data?.domain_name && data?.domain_address) {
    companyData.push(
      {
        label: 'Name',
        value: data.domain_name,
      },
      {
        label: 'Address',
        value: data.domain_address,
      }
    )
  }

  const nameField = fields?.find((item) => item?.field_id === 'questions')
  const bookingIdField = fields?.find((item) => item?.field_id === 'reservation_id')
  const discountField = fields?.find((item) => item?.field_id === 'discount_applied')
  const costField = fields?.find((item) => item?.field_id === 'total_cost')
  studentData.push(
    [
      {
        label: cms(nameField?.cms_key) || 'Name',
        value: data?.user_name,
      },
      {
        label: cms(bookingIdField?.cms_key) || 'Booking ID',
        value: data?.booking_id,
      },
    ],
    // [
    //   {
    //     label: cms(discountField?.cms_key) || 'Discount Applied',
    //     value: data?.total_discount,
    //   },
    // ],
    [
      {
        label: cms(costField?.cms_key) || 'Total Cost',
        value: data?.total_cost,
      },
    ]
  )

  if (component_name === 'student_details') return studentData
  else return companyData
}

const StudentDetails = ({ bookingDetails, widgets, data, widget_uuid }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  const sectionRef = useRef(null)
  const editMode = useSelector((state) => state.CMS.editMode)

  const [modalPosition, setModalPosition] = useState()

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        //  top: rect.top + window.scrollY - 50,
        top: 200,
        left: widgets?.component_name === 'company_details' ? -400 : -300,

        //  left: rect.left + window.scrollX + rect.width / 2 - 10, // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: widgets?.component_name === 'company_details' ? '50vw' : '60vw',
      })
    }
  }

  const reservedData = _getStudentData(bookingDetails?.bookingReservation, widgets?.component_name, data?.form?.[0]?.component_call_fields)

  let heading
  if (widgets?.component_name === 'student_details') {
    heading = data?.heading || '<p><b>Booking Details</b></p>'
  } else {
    heading = data?.heading || '<p><b>School Details</b></p>'
  }

  console.log('student details::::::::', data)
  return (
    <div
      className='mt-5'
      onClick={handleSectionClick}
      ref={sectionRef}
      style={widgets?.component_name === 'company_details' && isDesktop ? { paddingRight: '20%' } : { paddingInline: '1rem' }}>
      <div class='h4 font-weight-bold mb-3 body1' style={{ color: primary_color }} dangerouslySetInnerHTML={{ __html: heading }}>
        {/* {widgets?.component_name ? cms(widgets?.component_name) : 'Student Details'}
        {widgets?.component_name === 'student_details' ? 'Booking Details' : 'Company Info'} */}
      </div>
      {widgets?.component_name === 'student_details' ? (
        <>
          {reservedData?.map((element) => {
            return (
              <>
                {element?.map((item) => {
                  return (
                    <div className='mb-3'>
                      <div className='mb-1 font-weight-bold subtitle1'>{item?.label}</div>
                      <div className='subtitle1'>{item?.value}</div>
                    </div>
                  )
                })}
              </>
            )
          })}
        </>
      ) : (
        reservedData?.map((item) => {
          return (
            <div className='mb-3'>
              <div className='mb-1 font-weight-bold subtitle1'>{item?.label}</div>
              <div className='subtitle1'>{item?.value}</div>
            </div>
          )
        })
      )}
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => setModalPosition(null)}>
          <CMSCompanyInfo
            data={data}
            toggleModal={() => {
              console.log('clicked')
              setModalPosition(null)
            }}
            widget_uuid={widget_uuid}
          />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default StudentDetails

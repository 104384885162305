
import { useEffect, useRef, useState } from 'react'

const TemplatePreview = ({html}) => {
  const [iframeHeight, setIframeHeight] = useState('0px')
  const iframeRef = useRef(null)
  const updateIframeHeight = () => {
    const iframe = iframeRef.current
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
    setIframeHeight(`${iframeDoc.body.scrollHeight}px`)
  }

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument
      iframeDoc.open()
      iframeDoc.write(html)
      iframeDoc.close()

      iframeRef.current.addEventListener('load', updateIframeHeight)
      updateIframeHeight()
    }
    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', updateIframeHeight)
      }
    }
  }, [html, iframeRef.current])



  return (
    <div>
      <iframe ref={iframeRef} style={{ width: '100%', height: iframeHeight, border: 'none' }} title='HTML Renderer' />
      {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}
    </div>
  )
}

export default TemplatePreview

import { useSelector } from 'react-redux'
import { getPermissionBlockColor, updatePermission } from '../../Helper/permissionHelper'
import { primary_color } from '../../Helper/uiHelper'

const PermisionBlock = ({
  text = 'View',
  viewPermission,
  item,
  isMainPageViewEnabled,
  role_id,
  isCreatingNewRole,
  onClickPermissionForNewRole,
  selectedPermissionToCreateRole,
}) => {
  const Queue = useSelector((state) => state.Queue.queues)
  let { bgColor, textColor, border, isDisabled } = getPermissionBlockColor(
    item,
    viewPermission,
    isMainPageViewEnabled,
    role_id,
    isCreatingNewRole,
    selectedPermissionToCreateRole
  )


  return (
    <span
      className={`badge badge-pill permission-block`}
      onClick={() => {
        if (isCreatingNewRole) {
          onClickPermissionForNewRole(item?.permission_id)
        } else {
          updatePermission(role_id, item?.permission_id, item?.enabled)
        }
      }}
      style={{ backgroundColor: bgColor, color: textColor, border: border, cursor: !isDisabled && 'pointer', pointerEvents: isDisabled && 'none' }}>
      {text}
    </span>
  )
}

export default PermisionBlock

import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useRef, useState } from 'react'
import { primary_color } from '../../Helper/uiHelper'
import Icon from '../../CMSComponent/icon'
import ConfirmButton from '../../CMSComponent/Common/confirmButton'
import RoleBadge from './RoleBadge'

const DetailPanel = ({
  roles,
  activeRole,
  onDeleteRole,
  isCreatingNewRole,
  setSelectedPermissionToCreateRole,
  setIsCreatingNewRole,
  setActiveRole,
  setActiveTab,
  onSaveRole,
  form,
  updateForm,
}) => {
  const currentRole = roles?.find((role) => role?.role_id === activeRole)

  const [isWarning, setIsWarning] = useState()
  const warningItem = useRef(null)

  console.log('current role:::::::', currentRole, form, roles)
  useEffect(() => {
    updateForm({
      name: currentRole?.role || '',
      description: currentRole?.description || '',
      is_default: currentRole?.is_default,
      is_guest: currentRole?.is_guest,
    })
  }, [currentRole?.role_id, currentRole?.role, currentRole?.name])

  const onDetailSubmit = (e, value) => {}

  const handleNameOnChange = (value) => {
    updateForm((data) => {
      return {
        ...data,
        name: value,
      }
    })
  }

  const handleDespOnChange = (value) => {
    updateForm((data) => {
      return {
        ...data,
        description: value,
      }
    })
  }

  const handleRoleType = (e) => {
    const { name, checked } = e.target
    updateForm((data) => {
      if(checked){
        if(name == 'is_guest'){
            return {
              ...data,
              [name]: checked,
              'is_default': false
            }
        }else{
           return {
             ...data,
             [name]: checked,
             is_guest: false,
           }
        }
      }else{
          return {
            ...data,
            [name]: checked,
          }
      }
    
    })
  }

  const onSubmit = () => {
    if (!currentRole?.is_default && form?.is_default) {
      warningItem.current = 'Default'
      setIsWarning(true)
    } else if (!currentRole?.is_guest && form?.is_guest) {
      warningItem.current = 'Guest'
      setIsWarning(true)
    } else {
      onSaveRole()
    }
  }
  return (
    <div className='detail-panel'>
      <div className='d-flex align-items-center w-100 justify-content-between panel-header'>
        <h5 style={{ color: primary_color, fontWeight: 800 }}>{isCreatingNewRole ? 'Create new role' : 'Details'}</h5>
        {!isCreatingNewRole && (
          <div className='d-flex justify-content-center align-items-center' style={{ height: 44, width: 44, borderRadius: 8, cursor: 'pointer' }}>
            <ConfirmButton
              color='danger'
              id={'role'}
              clickEvent={() => {
                onDeleteRole(activeRole)
              }}
              modalBodyText='Are you sure you want to delete the role?'
              modalTitle={'Delete Role'}>
              <Icon icon='far fa-trash' style={{ fontSize: 21 }} color={'light'} />
            </ConfirmButton>
          </div>
        )}
      </div>
      <AvForm
        onSubmit={(e, value) => {
          e.preventDefault()
          onDetailSubmit(e, value)
        }}
        className={'d-flex flex-column'}>
        <AvField
          name='text'
          label='Name'
          type='text'
          value={form?.name}
          onChange={(e) => handleNameOnChange(e.target.value)}
          style={{ border: '1px solid #8E8E8E', height: 40, borderRadius: '6px' }}
          required
        />
        <AvField
          name='textarea'
          label='Description'
          type='textarea'
          value={form?.description}
          onChange={(e) => handleDespOnChange(e.target.value)}
          style={{ border: '1px solid #8E8E8E', minHeight: 120, borderRadius: '8px' }}
        />
        <div className='d-flex'>
          <div className='d-flex mr-4 justify-content-center  align-items-center'>
            <input type='checkbox' className='mr-2' name='is_default' checked={form?.is_default} onChange={handleRoleType}></input>
            <div className='mr-2'>DEFAULT USER GROUP</div>
            <div>
              <RoleBadge bgColor={'#FFCE00'} text={'Default'} />
            </div>
          </div>
          <div className='d-flex mr-4 justify-content-center align-items-center'>
            <input type='checkbox' className='mr-2' name='is_guest' checked={form?.is_guest} onChange={handleRoleType}></input>
            <div className='mr-2'>Guest User</div>
            <div>
              <RoleBadge bgColor={'#059669'} text={'Guest'} />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          {isCreatingNewRole && (
            <Button
              className='mx-2 subtitle1 rounded-pill'
              onClick={() => {
                setIsCreatingNewRole(false)
                setSelectedPermissionToCreateRole([])
                setActiveTab('1')
                setActiveRole(roles?.[0]?.role_id)
              }}
              style={{
                backgroundColor: primary_color,
                paddingInline: '3.5rem',
                borderColor: primary_color,
                borderRadius: '40px',
                fontWeight: 700,
                // fontSize: 18,
              }}>
              Cancel
            </Button>
          )}
          <Button
            className='mx-2 subtitle1 rounded-pill'
            onClick={() => onSubmit()}
            style={{
              backgroundColor: primary_color,
              paddingInline: '3.5rem',
              borderColor: primary_color,
              borderRadius: '40px',
              fontWeight: 700,
              // fontSize: 18,
            }}>
            Save
          </Button>
        </div>

        <Modal
          isOpen={isWarning}
          centered={true}
          toggle={() => {
            setIsWarning(false)
            warningItem.current = null
          }}
          style={{ width: '50rem', boxShadow: 'none', maxWidth: '50rem' }}>
          <ModalHeader style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem' }}>
            <div className='p font-weight-bold' style={{ color: primary_color }}>
              {warningItem?.current !== 'Guest'
                ? 'You are about to set the default role for all newly registered users. This action will assign the specified role to every new user upon registration. Please ensure that the default role has appropriate permissions and access levels, as this setting will affect the entire user base moving forward.'
                : 'You are setting the "Guest" role. All users with the "Guest" role, as well as users who are not logged in, will have the same access and view permissions. Ensure that the content and features available to guests are appropriate for public access and do not contain sensitive or restricted information.'}
            </div>
          </ModalHeader>
          <ModalBody>
            <div className='d-flex justify-content-between'>
              <button
                className='btn text-white rounded-pill mt-2'
                type='button'
                onClick={() => {
                  setIsWarning(false)
                  console.log('warning item::::::', warningItem)
                  onSaveRole()
                  warningItem.current = null
                }}
                style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
                Yes
              </button>
              <button
                className='btn rounded-pill mt-2 text-danger border-danger'
                type='button'
                onClick={() => {
                  if (warningItem?.current === 'Guest') {
                    updateForm((data) => {
                      return {
                        ...data,
                        is_guest: false,
                      }
                    })
                  } else {
                    updateForm((data) => {
                      return {
                        ...data,
                        is_default: false,
                      }
                    })
                  }
                  setIsWarning(false)
                  warningItem.current = null
                }}
                style={{ fontWeight: 700, width: '48%' }}>
                Cancel
              </button>
            </div>
          </ModalBody>
        </Modal>
      </AvForm>
    </div>
  )
}

export default DetailPanel

import React from 'react'
import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import './Vendor/fontawesome-pro/css/all.min.css'
import './polyfills'
import './assets/base.scss'
import Main from './Pages'
import * as serviceWorker from './serviceWorker'
import { persistor, store } from './Redux/Config/configureStore'

const queryClient = new QueryClient()

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

const renderApp = (Component) => {
  root.render(
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Component />
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

renderApp(Main)

serviceWorker.unregister()

import React, { useEffect, useState } from 'react'
import { request } from '../../../Redux/Sagas/requests/api'
import { clearNotification, setNotification } from '../../../Redux/Reducers/notificationHandling'
import { useDispatch, useSelector } from 'react-redux'
import countries from '../../../assets/utils/data/country-cities'
import { cms } from '../../../helper'
import PublicUnAuthAddress from '../PublicUnAuthAddress'
import RoundedSelect from '../../Form/Fields/roundedSelect'
import AddressForm from './AddressForm'
import { primary_color } from '../../../Helper/uiHelper'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { FaPlus } from 'react-icons/fa'
import Select from 'react-select'
import { useLocation } from 'react-router-dom'

const AddressDetails = ({ userAddressValidation, setUserAddressValidation, form, data, userAddress, setUserAddress }) => {
  const [AllAddress, setAllAddress] = useState([])
  const isDesktop = useMediaQuery('(min-width:768px)')
  const dispatch = useDispatch()

  useEffect(() => {
    fetchAllAddress()
  }, [])

  const fetchAllAddress = () => {
    request('user/address/all', 'GET')
      .then((res) => {
        setAllAddress(res.data)
      })
      .catch((e) => console.log(e))
  }
  const [showAddressFields, setShowAddressFields] = useState(false)

  const additionalSubmit = () => {
    setShowAddressFields(false)
    fetchAllAddress()
  }

  const onAddressSelect = (id) => {
    dispatch(clearNotification())
    const selectedAddress = AllAddress?.find((add) => {
      if (add?.id === parseInt(id)) {
        return true
      } else {
        return false
      }
    })

    if (selectedAddress) {
      setUserAddress(selectedAddress)
      setUserAddressValidation('Success')
    }
  }
  return (
    <div className='col-md-12 ml-auto mr-auto' style={{ marginTop: '2rem' }}>
      <div class='border-bottom pb-4 ' style={{ marginLeft: 'auto' }}>
        <div class='mb-3'>
          <div class='h4 font-weight-bold mb-3 body1' style={{ color: primary_color }}>
            {data.heading ? cms(data?.heading) : 'Address'}
          </div>
          {!showAddressFields && (
            <>
              <Select
                classNamePrefix='rounded-select'
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: '50px',
                    borderColor: userAddressValidation && !userAddress.address1 ? 'red' : baseStyles.borderColor,
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: userAddressValidation && !userAddress.address1 ? 'red' : 'rgba(0, 0, 0, 0.2)',
                  }),
                }}
                placeholder='Select Address'
                onChange={(e) => onAddressSelect(e.value)}
                options={AllAddress.map((e) => {
                  return { label: `${e?.address1}, ${e?.city}, ${e?.country}`, value: e?.id }
                })}
              />

              {userAddressValidation && !userAddress.address1 && <div className='text-danger text-left pl-2'>Please select address.</div>}
            </>
          )}

          {showAddressFields ? (
            <AddressForm
              setShowAddressFields={setShowAddressFields}
              userAddress={userAddress}
              setUserAddress={setUserAddress}
              additionalSubmit={additionalSubmit}
            />
          ) : (
            <></>
          )}
          {!showAddressFields && (
            <button
              type='button'
              onClick={() => setShowAddressFields(true)}
              className='btn mt-3 rounded-pill subtitle1 px-5 d-flex justify-content-center'
              style={{
                borderRadius: '20px',
                background: !showAddressFields ? primary_color : 'transparent',
                color: !showAddressFields ? 'white' : primary_color,
                border: `1px solid ${primary_color}`,
                width: isDesktop ? 'auto' : '100%',
              }}>
              {data?.button_text || (
                <span className='d-flex align-items-center subtitle1'>
                  <FaPlus style={{ marginBottom: '0.2rem' }} className='mr-1' /> New Address
                </span>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

const AddressWidget = ({ form, data, userAddress, setUserAddress, userAddressValidation, setUserAddressValidation }) => {
  const publicUser = useSelector((state) => state.User.publicUser)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isAdminFlow = searchParams.get('is_admin')

  useEffect(() => {
    if (!publicUser?.email) {
      setUserAddress({
        address1: '',
        country: 'United Kingdom',
        display: 1,
        city: '',
        post_code: '',
      })
    }
    setUserAddressValidation(false)
  }, [publicUser?.email])

  if (isAdminFlow) return
  if (publicUser?.email) {
    return (
      <AddressDetails
        userAddressValidation={userAddressValidation}
        setUserAddressValidation={setUserAddressValidation}
        form={form}
        data={data}
        userAddress={userAddress}
        setUserAddress={setUserAddress}
      />
    )
  } else {
    return (
      <PublicUnAuthAddress
        userAddressValidation={userAddressValidation}
        setUserAddressValidation={setUserAddressValidation}
        userAddress={userAddress}
        setUserAddress={setUserAddress}
      />
    )
  }
}
export default AddressWidget

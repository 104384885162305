import { useDispatch } from 'react-redux'
import { primary_color } from '../../../../Helper/uiHelper'
import useUpdateEmailTemplateWidget from '../../../../Queries/EmailTemplate/useUpdateEmailTemplateWidget'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { FileUploader } from 'react-drag-drop-files'
import IconSet from '../../../../CMSComponent/icon'
import { useEffect, useState } from 'react'
import { request } from '../../../../Redux/Sagas/requests/api'
import { getCookie } from '../../../../Helper/cookieHelper'

const fileTypes = ['JPG', 'JPEG', 'PNG']

const HeaderWidgetEdit = ({ data, closeModal }) => {
  const [files, setFiles] = useState()
  const [uploadedPath, setUploadedPath] = useState()

  const [setting, updateSetting] = useState({})

  const updateWidget = useUpdateEmailTemplateWidget()
  const dispatch = useDispatch()

  useEffect(() => {
    if (data?.common_setting) {
      updateSetting(JSON.parse(data?.common_setting)?.[0])
    }
  }, [JSON.stringify(data)])

  console.log('header widget:::::::::', JSON.parse(data?.common_setting))
  const handleChange = (files) => {
    setFiles(files)

    const formData = new FormData()
    formData.append('file', files)

    const token = getCookie('AUTH_TOKEN')

    fetch(process.env.REACT_APP_API_URL + '/file-upload/header-template-logo', {
      method: 'POST',
      body: formData,
      headers: {
        authorization: token ? `Bearer ${token}` : 'Bearer token',
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setUploadedPath(data?.path)
            updateSetting({
              ...setting,
              Value: data?.path,
            })
          })

          dispatch(setNotification({ type: 'success', message: 'File Uploaded successfully' }))
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        }
      })
      .catch((error) => console.error('Error uploading image:', error))
  }

  const onSaveWidget = () => {
    let payload = {
      ...data,
      common_setting: [setting],
      type: 'widgets',
    }
    updateWidget.mutate(payload, {
      onSuccess: (data) => {
        //add failed scenario also
        dispatch(setNotification({ type: 'success', message: 'Email Sent Successfully!' }))
        closeModal()
      },
      onError: (error) => {
        dispatch(setNotification({ type: 'success', message: 'Something went wrong' }))
        closeModal()
      },
    })
  }

  console.log('header setting:::::::::', setting)
  return (
    <div>
      <div style={{ color: primary_color }} className='my-2'>
        Logo
      </div>
      <div className='d-flex'>
        <div className='mr-4' style={{ flex: 1 }}>
          <FileUploader
            handleChange={handleChange}
            className='mr-4'
            name='file'
            types={fileTypes}
            multiple={false}
            fileOrFiles={files}
            classes={'upload-file-form'}>
            <div className='upload_container'>
              <span style={{ marginRight: '1rem', fontSize: '2rem' }}>
                <IconSet icon='fa-solid fa-folder-arrow-up' color='info' />
              </span>
              Upload or Drop Image here!
            </div>
          </FileUploader>
        </div>

        <div
          className='d-flex align-items-center justify-content-center position-relative'
          style={{ width: '200px', border: '2px solid #000000', borderRadius: '10px' }}>
          <img width={'150px'} style={{ maxHeight: '200px' }} src={process.env.REACT_APP_STORAGE + setting?.Value}></img>
        </div>
      </div>
      <div className='d-flex justify-content-between' style={{ width: '60%' }}>
        <button
          className='btn rounded-pill mt-2 text-danger border-danger'
          type='button'
          onClick={() => {
            closeModal()
          }}
          style={{ fontWeight: 700, width: '48%' }}>
          Cancel
        </button>
        <button
          className='btn text-white rounded-pill mt-2'
          type='button'
          onClick={() => {
            onSaveWidget()
          }}
          style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
          Save
        </button>
      </div>
    </div>
  )
}

export default HeaderWidgetEdit

import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { setNav } from '../../Redux/Reducers/navigations'
import { request } from '../../Redux/Sagas/requests/api'

const fetchNav = async () => {
  const response = await request('/get-navigation', 'get')
  return response.data
}

export const useFetchNav = (enabled = true) => {
  const dispatch = useDispatch()
  return useQuery('nav-items', fetchNav, {
    onSuccess: (data) => {
      dispatch(setNav(data))
    },
    enabled,
  })
}

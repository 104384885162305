import { useMutation, useQueryClient } from 'react-query'
import { request } from '../../Redux/Sagas/requests/api'
import { store } from '../../Redux/Config/configureStore'
import { setNotification } from '../../Redux/Reducers/notificationHandling'


const updateEmailTemplate = async (payload) => {
  const response = await request('update-email-template','POST',payload)
  return response.data
}

const useUpdateEmailTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation(updateEmailTemplate, {
    onSuccess: () => {
      // store.dispatch(setNotification({type:'success',message:'Data update'}))
      // queryClient.invalidateQueries('emailTemplates')
    },
  })
}

export default useUpdateEmailTemplate

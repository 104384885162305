import { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, ListGroupItem, ListGroup } from 'reactstrap'
import RLDD from 'react-list-drag-and-drop/lib/RLDD'
import { Row } from 'reactstrap'

import { checkCMSPermission, cms, trimNameForId } from '../../../helper'
import { tidyName } from '../../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { removeItemByName, submitRequest, removeTabByName, updatePageDataRedux } from '../../../Redux/Reducers/CMS'
import ConfirmButton from '../../Common/confirmButton'
import Icon from '../../icon'
import { Fragment } from 'react'
import EditTab from '../Grid/editTab'
import { AvForm } from 'availity-reactstrap-validation'
import { setLoader } from '../../../Redux/Reducers/loader'
import { request } from '../../../Redux/Sagas/requests/api'

const SetTabOrder = ({ tabs, pageName, formName, main_source_table, parentPageName }) => {
  const [openModal, setOpenModal] = useState(false)
  const [tabList, setTabList] = useState([])
  const [isEnableOrder, setIsEnableOrder] = useState(false)
  const [formFieldOrder, setFormFieldOrder] = useState([])
  const [addFormOpen, setAddFormOpen] = useState(false)
  const [tabForm, updateTabForm] = useState()

  // const parentTableData

  const dispatch = useDispatch()

  useEffect(() => {
    setTabList(
      tabs
        ? tabs.map((item, key) => {
            item.id = item.order ? item.order : key
            return item
          })
        : []
    )
  }, [tabs])

  const submitOrdering = () => {
    setIsEnableOrder(!isEnableOrder)
    if (formFieldOrder.length > 0)
      dispatch(
        submitRequest({
          url: 'order-grid-tabs',
          data: {
            data: formFieldOrder,
            // form_name: formName,
            page_name: pageName,
            // tab:true
          },
        })
      )
  }
  const parentTableDataRedux = useSelector((state) => state.CMS.parentTableData)

  const onSaveTab = () => {
    if (tabForm && tabForm?.length > 0) {
      let payload = {
        data: formFieldOrder,
        main_source_table,
        // form_name: formName,
        page_name: pageName,
        parent_page_name: parentPageName ? parentPageName : window.location.hash.split('/')[1]?.split('-')[0],
        tabForm: tabForm,
      }
      if (parentTableDataRedux && parentTableDataRedux?.length > 0) {
        payload = {
          ...payload,
          tab: true,
        }
      }
      dispatch(setLoader(true))
      request('submit-page-tab', 'POST', payload).then((res) => {
        request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[parentPageName] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
      // dispatch(
      //   submitRequest({
      //     url: 'submit-page-tab',
      //     data: {
      //       ...payload,
      //       // tab:true
      //     },
      //   })
      // )
    }
  }

  return (
    <>
      <Button onClick={() => setOpenModal(true)} style={{ marginTop: '2rem', marginLeft: '1rem' }} color='primary' className='no-print'>
        <i className='fa-light fa-pen' style={{ marginRight: '0.4rem' }}></i> Tabs ({tidyName(main_source_table)})
      </Button>
      <Modal isOpen={openModal} style={{ width: 700 }} scrollable={true} toggle={() => setOpenModal(!openModal)}>
        <ModalHeader toggle={() => setOpenModal(!openModal)}>Set Order</ModalHeader>
        <ModalBody>
          <>
            {isEnableOrder && tabs?.length > 0 && (
              <Button className='btn btn-success float left m-2' onClick={() => submitOrdering()}>
                Confirm Order
              </Button>
            )}
            {tabs?.length > 0 && checkCMSPermission('order_tab') && (
              <Button className='btn btn-info float right m-2' onClick={() => setIsEnableOrder(!isEnableOrder)}>
                {isEnableOrder ? 'Disable' : 'Enable'} Order
              </Button>
            )}
            {checkCMSPermission('add_tab_in_grid') && (
              <Button color={addFormOpen ? 'danger' : 'primary'} onClick={() => setAddFormOpen(!addFormOpen)} className='btn-info m-1 float-right'>
                <Icon icon={addFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
              </Button>
            )}
          </>

          {addFormOpen && (
            <AvForm>
              <Row style={{ margin: '1rem' }}>
                <EditTab form={{ table: main_source_table }} col={6} updateTab={updateTabForm} isTabPage={true} onSaveTab={onSaveTab} />
              </Row>
            </AvForm>
          )}

          {tabList && isEnableOrder ? (
            <RLDD
              cssClasses='example'
              items={tabList}
              itemRenderer={(item, key) => {
                return (
                  <ListGroupItem id={item?.id}>
                    <span style={{ marginRight: '1rem', cursor: 'pointer' }}>
                      <i className='fa-sharp fa-solid fa-grid-horizontal'></i>
                    </span>
                    {cms(item.cms_tab_key)} <span style={{ fontSize: '0.7rem' }}></span>
                    <div className='float-right'>
                      <ConfirmButton
                        style={{ padding: 0 }}
                        color='link'
                        id={item.field_id || trimNameForId(item?.name)}
                        clickEvent={() =>
                          dispatch(
                            removeTabByName({
                              name: item.name,
                              page_name: window.location.hash.split('/')[1]?.split('-')[0],
                            })
                          )
                        }>
                        <Icon icon={'fa-solid fa-xmark'} color='danger' />
                      </ConfirmButton>
                    </div>
                  </ListGroupItem>
                )
              }}
              onChange={(e) => {
                setTabList(e)
                let requestData = []
                e &&
                  e.map((item, key) => {
                    requestData.push({
                      name: item.name || item.field_id,
                      order: key,
                    })
                  })
                setFormFieldOrder(requestData)
              }}
            />
          ) : (
            <>
              {tabList?.map((item, key) => {
                return (
                  <Fragment key={key}>
                    <ListGroupItem id={item.field_id || item?.name}>
                      {cms(item.cms_tab_key)} <span style={{ fontSize: '0.7rem' }}></span>
                      {checkCMSPermission('delete_child_tab') && (
                        <div className='float-right'>
                          <ConfirmButton
                            style={{ padding: 0 }}
                            color='link'
                            id={item.field_id || trimNameForId(item?.name)}
                            clickEvent={() =>
                              dispatch(
                                removeTabByName({
                                  name: item.name,
                                  page_name: window.location.hash.split('/')[1]?.split('-')[0],
                                })
                              )
                            }>
                            <Icon icon={'fa-solid fa-xmark'} color='danger' />
                          </ConfirmButton>
                        </div>
                      )}
                    </ListGroupItem>

                    {/* <UncontrolledCollapse
              toggler={"#" + (item.field_id || item?.name)}
            >
              <div className="border border-danger"></div>
              <div className="p-2 border">
                <EditField
                  item={item}
                  coreData={coreData}
                  hasReduxData={hasReduxData}
                  reduxDataValidate={reduxDataValidate}
                  manageDatabaseValidate={manageDatabaseValidate}
                  formName={formName}
                  pageName={pageName}
                  setModal={setModal}
                  section={section}
                  setAddFormOpen={setAddFormOpen}
                  tab={tab}
                />
              </div>
            </UncontrolledCollapse> */}
                  </Fragment>
                )
              })}
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default SetTabOrder

import { useEffect } from 'react'
import { primary_color } from '../../Helper/uiHelper'
import { request } from '../../Redux/Sagas/requests/api'

const UnSubscribe = () => {
  useEffect(()=>{
    request('unsubscribe')
  },[])
  return (
    <div style={{ height: '80vh' }} className='d-flex flex-column justify-content-center align-items-center'>
      <i class='fa-sharp-duotone fa-solid fa-mailbox' style={{ fontSize: '6rem', color: primary_color }}></i>

      <div className='font-weight-bold mt-4' style={{ fontSize: '2rem' }}>
        Successfully Unsubscribed
      </div>
      <div className='mt-4'>You have been removed from our mailing list.</div>
      <div>If this is a mistake or if you want to receive updates again you can easily subscribe again the mail from you profile page</div>
    </div>
  )
}

export default UnSubscribe

import { faCalendarAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Col, FormGroup, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { useEffect } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import Select from '../../../Forms/Fields/select'
import { saveFilter } from '../../../../Redux/Reducers/CMS'

const academic_year_dropdown = {
  component_type: 'cols',
  component_name: 'text_data_col',
  permission: 'view_academic year',
  display_header: 1,
  background_color: null,
  field_id: 'item_date_time_id',
  field_setting: {
    type: 'int',
    validations: [],
    field: 'dropdown',
    manage_data: null,
    component_call_data_connection: {
      component_type: 'data_connection',
      component_name: 'template_data',
      source_table: 'item_date_times',
      fields: 'title',
      clause_data: [],
    },
    values: '',
  },
  inline_edit: null,
  cms_key: 'field.academic_year',
  cms_key_tooltip: null,
  width: '100',
  sort: 'none',
  allow_sorting: true,
  sort_priority: null,
  filtering: true,
  default_filter: null,
  display_column: true,
}

const school_dropdown = {
  component_type: 'cols',
  component_name: 'text_data_col',
  permission: 'view_school',
  display_header: 1,
  background_color: null,
  field_id: 'domain_id',
  field_setting: {
    type: 'int',
    validations: [],
    field: 'dropdown',
    manage_data: null,
    component_call_data_connection: {
      component_type: 'data_connection',
      component_name: 'template_data',
      source_table: 'domains',
      fields: 'domain_name',
      clause_data: [],
    },
    values: '',
  },
  inline_edit: null,
  cms_key: 'field.school',
  cms_key_tooltip: null,
  width: '100',
  sort: 'none',
  allow_sorting: true,
  sort_priority: null,
  filtering: true,
  default_filter: null,
  display_column: true,
}

const AcademicYearGlobalFilter = ({ gridRelatedData, isOnlyAcademic }) => {
  const [selectedSchool, setSelectedSchool] = useState({ value: null, label: 'Search' })
  const [selectedYear, setSelectedYear] = useState({ value: null, label: 'Search' })

  const { sortBy, getBySearch, setGlobalUIFilter, globalUIFilter } = gridRelatedData
  const savedFilters = useSelector((state) => state.CMS.savedFilters)

  useEffect(() => {
    console.log('saved filter:::::::::', savedFilters, gridRelatedData?.content?.name)
    let mainPageName = gridRelatedData?.content?.name
    if (savedFilters && savedFilters[mainPageName] && savedFilters[mainPageName]['-filter-domain_name']) {
      let val = savedFilters[mainPageName]['-filter-domain_name']
      setSelectedSchool({ value: '', label: val })
    } else {
      setSelectedSchool({ value: '', label: 'Search' })
    }
  }, [JSON.stringify(savedFilters)])

  useEffect(() => {
    if (globalUIFilter?.block_reports?.acadamic_year) {
      setSelectedYear({
        value: globalUIFilter?.block_reports?.acadamic_year?.value,
        label: globalUIFilter?.block_reports?.acadamic_year?.label,
      })
    }
  }, [JSON.stringify(globalUIFilter)])

  // const onSearchByYear = (a, b, field, v, label) => {
  //   setGlobalUIFilter({
  //     block_reports: {
  //       acadamic_year: {
  //         value: v,
  //         label: label,
  //       },
  //     },
  //   })
  //   setSelectedYear({
  //     value: v,
  //     label: label,
  //   })
  // }
  const onSearchByYear = (a, b, field, v, label) => {
    if (v) {
      getBySearch({ ['-filter-' + 'item_date_time_id']: v || '' }, sortBy)
    }
  }

  console.log('selected school::::::', selectedSchool)
  return (
    <Row form>
      <Col md={6}>
        <AvForm>
          <FormGroup>
            {/* <Label className='font-weight-bold'>Academic Year</Label> */}
            <Select
              data={academic_year_dropdown}
              onChangeAction={onSearchByYear}
              value={selectedYear?.value}
              inputLabel={selectedYear?.label}></Select>
          </FormGroup>
        </AvForm>
      </Col>
      
    </Row>
  )
}

export default AcademicYearGlobalFilter

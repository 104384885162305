import React from 'react'
import Loader from 'react-loaders'

const CenteredLoader = () => {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
      <Loader type='ball-pulse' color='blue' />
    </div>
  )
}

export default CenteredLoader

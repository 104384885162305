import { useSelector } from 'react-redux'
import { tidyName } from '../../helper'
import PermissionGroup from './PermissionGroup'
import RowTitle from './RowTitle'
import Loader from 'react-loaders'

const PermissionPanel = ({
  currentPermission,
  role_id,
  isCreatingNewRole,
  filterPage,
  onClickPermissionForNewRole,
  selectedPermissionToCreateRole,
  permissionLoading,
  isSinglePage,
}) => {
  const reduxQueue = useSelector((state) => state.Queue.queues)

  let addPermissionArray = []
  const removePermissionArray = []

  if (!isCreatingNewRole) {
    reduxQueue?.map((queue) => {
      if (queue.url === 'update-role-wise-permission') {
        queue.payload?.data?.permissions?.map((permission) => {
          if (permission?.add_ids) {
            permission?.add_ids?.map((item) => {
              addPermissionArray?.push(item)
            })
          }
          if (permission?.remove_ids) {
            permission?.remove_ids?.map((item) => {
              removePermissionArray?.push(item)
            })
          }
        })
      }
    })
  } else {
    addPermissionArray = [...selectedPermissionToCreateRole]
  }

  let filterPageIds
  if (!isCreatingNewRole) {
    filterPageIds = filterPage?.filter((item) => item?.checked)?.map((item) => item?.id)
  }

  if (permissionLoading) {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center ' style={{ marginTop: '10rem' }}>
        {' '}
        <div>
          <Loader type='ball-pulse' color='blue' />
        </div>
        <div className='body1 text-muted'>Please wait while the data is being synchronized.</div>
      </div>
    )
  }

  return (
    <div className='permission-panel' style={{ maxHeight: isSinglePage ? '40vh' : '75vh' }}>
      {currentPermission?.page &&
        Object.keys(currentPermission?.page)?.map((key) => {
          if (!filterPageIds?.includes(key) && !isCreatingNewRole) return null
          let page_name = tidyName(key)
          let { pageButtons, ...rest } = currentPermission?.page?.[key]

          let isMainPageViewEnabled = false

          const mainPageViewPermission = currentPermission?.page?.[key]?.data?.find((p) => p?.permission === 'view')
          let mainPageViewId = mainPageViewPermission?.permission_id
          if (mainPageViewPermission && mainPageViewPermission?.enabled && !removePermissionArray?.includes(mainPageViewId)) {
            isMainPageViewEnabled = true
          }
          if (addPermissionArray?.includes(mainPageViewId)) {
            isMainPageViewEnabled = true
          }

          return (
            <>
              <RowTitle title={page_name} />
              <PermissionGroup
                title={'Page'}
                item={currentPermission?.page?.[key]}
                isMainPageViewEnabled={true}
                role_id={role_id}
                isCreatingNewRole={isCreatingNewRole}
                onClickPermissionForNewRole={onClickPermissionForNewRole}
                selectedPermissionToCreateRole={selectedPermissionToCreateRole}
              />
              {currentPermission?.page?.[key]?.pageButtons &&
                Object.keys(currentPermission?.page?.[key]?.pageButtons)?.map((key1) => {
                  return (
                    <PermissionGroup
                      title={tidyName(key1)}
                      item={currentPermission?.page?.[key]?.pageButtons?.[key1]}
                      isMainPageViewEnabled={isMainPageViewEnabled}
                      role_id={role_id}
                      isCreatingNewRole={isCreatingNewRole}
                      onClickPermissionForNewRole={onClickPermissionForNewRole}
                      selectedPermissionToCreateRole={selectedPermissionToCreateRole}
                    />
                  )
                })}
            </>
          )
        })}
    </div>
  )
}

export default PermissionPanel

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'

import { authLogout, setPublicUser } from '../../Redux/Reducers/user'
import { clearNav } from '../../Redux/Reducers/navigations'

const select = (state) => ({
  isAuth: state.User.isAuth,
})

const HandleLogout = ({ isAuth }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (isAuth) {
      dispatch(authLogout())
    }
    dispatch(setPublicUser())
    dispatch(clearNav())
  })
  return null
}

export default connect(select)(HandleLogout)

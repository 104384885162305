import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'reactstrap'
import Input from '../../CMSComponent/Forms/Fields/input'
import { cms, tidyName } from '../../helper'
import RoundedSearch from '../Form/Fields/roundedSearch'
import './navigationButton.scss'
import { primary_color } from '../../Helper/uiHelper'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import { useRef, useState } from 'react'
import CMSWidgetModal from '../CMSWidget/cmsWidgetModal'
import CMSHeroSearch from '../CMSWidget/HeroSearch/cmsHeroSearch'
import CMSPaymentSuccessBanner from '../CMSWidget/PaymentSuccess/cmsPaymentSuccessBanner'
import './Scss/paymentSuccess.scss'
import { useSelector } from 'react-redux'

const PaymentSuccessBanner = ({ data, widget_uuid }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  const sectionRef = useRef(null)

  const [modalPosition, setModalPosition] = useState()
  const editMode = useSelector((state) => state.CMS.editMode)

  const handleSectionClick = () => {
    // return
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        //  top: rect.top + window.scrollY - 50,
        top: rect.bottom + window.scrollY - 50,

        left: 300, // Position below the parent element
        // left: rect.left + window.scrollX, // Align left edge with parent element
        width: '70vw',
      })
    }
  }

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  return (
    <div
      className='locker-container payment-success-banner position-relative'
      // style={{ background: '#F5FCFF' }}
      onClick={handleSectionClick}
      ref={sectionRef}
      style={{
        background: `url(${process.env.REACT_APP_STORAGE + data?.bg_image})`,
        backgroundSize: 'cover',
        backgroundColor: 'rgb(255 255 255 / 60%)',
        paddingTop: '5rem',
        paddingBottom: '5rem',
        border: '1px solid #00000009',
      }}>
      <div
        className='d-flex flex-column align-items-center m-auto banner-container'
        style={{ background: 'rgb(255 255 255 / 82%)', border: '1px solid #00000009' }}>
        <div className='success-pulse'>
          <i className={'position-absolute ' + data?.icon ? data?.icon : 'fa-solid fa-solid-check'} style={{ color: 'white', scale: '0.45' }}></i>
        </div>
        <div class=' mt-3 content-widget'>
          <div dangerouslySetInnerHTML={{ __html: data?.heading }} className='text-center'></div>
          {/* <h2 className='text-center h2 font-weight-bold pb-2 pt-2 mb-2 mt-2' style={{ color: primary_color }}>
            {cms(data?.heading)}
          </h2> */}
          <div dangerouslySetInnerHTML={{ __html: data?.text }} className='text-center'></div>

          {/* <p className='text-center text-muted'>{data?.text}</p> */}
        </div>
      </div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSPaymentSuccessBanner
            data={data}
            toggleModal={() => {
              onCloseModal()
            }}
            widget_uuid={widget_uuid}
          />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default PaymentSuccessBanner

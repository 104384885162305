import { useQuery } from 'react-query'
import axios from 'axios'
import { request } from '../../Redux/Sagas/requests/api'

const fetchData = async (postData) => {
  // const response = await axios.post('https://api.example.com/data', postData)
   const response = await request('user/get-all-users', 'POST',postData)
   return response.data

}

const useFetchUserForMergeTable = (postData) => {
  const query = useQuery(['fetchUserData',postData], () => fetchData(postData), {
    enabled: !!postData, // Only run the query if postData is provided
  })

   return {
     data: query.data,
     error: query.error,
     isLoading: query.isLoading,
     isSuccess: query.isSuccess,
     isError: query.isError,
   }
}

export default useFetchUserForMergeTable

import { useEffect, useState } from 'react'
import { primary_color } from '../../../Helper/uiHelper'
import PageHeader from '../../Permissions/PageHeader'
import { request } from '../../../Redux/Sagas/requests/api'
import moment from 'moment'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { InputForAmount } from '../../Widget/PaymentForm/adminPaymentDetails'
import RefundSuccess from './refundSuccess'
import RefundRejected from './refundRejected'
import Loader from 'react-loaders'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import ApproveRefundPreview from './approveRefundPreview'
import { fetchUpdateApi } from '../../../Redux/Sagas/rootSaga'

const getSingleData = (item) => {
  const booking_fields = [
    {
      title: 'Child Name',
      field_id: 'child_name',
      value: item?.item_answer_responses?.[0]?.item_answer?.answer + ' ' + item?.item_answer_responses?.[1]?.item_answer?.answer,
    },
    {
      title: 'Locker Block',
      field_id: 'child_name',
      value: item?.location?.name,
    },
    {
      title: 'Reservation ID',
      field_id: 'child_name',
      value: item?.booking_reservation_id,
    },
    {
      title: 'Locker Number',
      field_id: 'child_name',
      value: item?.item?.item_name,
    },
    {
      title: 'Start Rent',
      field_id: 'pre_time',
      value: item?.item_date_time?.from_date && moment(item?.item_date_time?.from_date).format('MMM D  YYYY, hh:mm'),
    },
    {
      title: 'End Rent',
      field_id: 'post_time',
      value: item?.item_date_time?.to_date && moment(item?.item_date_time?.to_date).format('MMM D  YYYY, hh:mm'),
    },
  ]
  return booking_fields
}
const getData = (data) => {
  const arr = data?.map((item) => getSingleData(item))
  return arr
}

const ApproveRefund = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(location.search)
  const refund_id = searchParams.get('refund_id')

  const [refundData, setRefundData] = useState()
  const [bookingDetails, setBookingDetails] = useState()
  const [error, setError] = useState()
  const [screenStatus, setScreenStatus] = useState({
    loader: true,
    success: false,
    rejected: false,
    email: false,
    not_found: false,
  })

  const [refundAmount, setRefundAmount] = useState()
  const [refundNote, setRefundNote] = useState()

  useEffect(() => {
    if (!refund_id) {
      setScreenStatus({
        loader: false,
        success: false,
        rejected: false,
        email: false,
        not_found: true,
      })
      return
    }
    request('transaction-info', 'POST', { id: parseInt(refund_id), is_refund: true }).then((res) => {
      setRefundData(res?.data?.data)
      const booking_details = getData(res?.data?.data?.booking_details)
      setBookingDetails(booking_details)
      setRefundAmount(res?.data?.data?.transaction_log?.amount)
      if (res?.data?.data?.transaction_log) {
        setScreenStatus({
          loader: false,
          success: false,
          rejected: false,
          email: false,
          not_found: false,
        })
      } else {
        setScreenStatus({
          loader: false,
          success: false,
          rejected: false,
          email: false,
          not_found: true,
        })
      }
    })
  }, [])

  useEffect(() => {
    if (parseInt(refundAmount) > refundData?.payment?.amount) {
      setError('Refund amount should be less than total amount')
    } else {
      setError()
    }
  }, [refundAmount])

  const onDeclineRequest = () => {
    setScreenStatus({
      loader: true,
      success: false,
      rejected: false,
      email: false,
      not_found: false,
    })
    request('reject-refund', 'POST', { id: parseInt(refund_id) }).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
        setScreenStatus({
          loader: false,
          success: false,
          rejected: true,
          email: false,
          not_found: false,
        })
      } else {
        setScreenStatus({
          loader: false,
          success: false,
          rejected: false,
          email: false,
          not_found: false,
        })
        dispatch(setNotification({ type: 'error', message: 'Something went wrong!' }))
      }
      dispatch(fetchUpdateApi())
    })
  }

  const onApproveRequest = () => {
    setScreenStatus({
      loader: true,
      success: false,
      rejected: false,
      email: false,
      not_found: false,
    })
    request('update-refund-status', 'POST', { id: parseInt(refund_id), refund_note: refundNote, refund_amount: refundAmount }).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
        setScreenStatus({
          loader: false,
          success: true,
          rejected: false,
          email: false,
          not_found: false,
        })
      } else {
        setScreenStatus({
          loader: false,
          success: false,
          rejected: false,
          email: false,
          not_found: false,
        })
        dispatch(setNotification({ type: 'error', message: 'Something went wrong!' }))
      }
      dispatch(fetchUpdateApi())
    })
  }

  if (screenStatus?.success) return <RefundSuccess></RefundSuccess>
  if (screenStatus?.rejected) return <RefundRejected></RefundRejected>
  if (screenStatus?.email)
    return (
      <ApproveRefundPreview
        payload={{ id: parseInt(refund_id), refund_note: refundNote, refund_amount: refundAmount }}
        onCancelPreview={() => {
          setScreenStatus({
            loader: false,
            success: false,
            rejected: false,
            email: false,
            not_found: false,
          })
        }}></ApproveRefundPreview>
    )
  if (screenStatus?.loader)
    return (
      <div className='d-flex flex-column align-items-center'>
        <PageHeader text={'Refund'}></PageHeader>
        <div style={{ marginTop: '3rem' }}>
          <Loader type='ball-pulse' color='blue' />
        </div>
      </div>
    )
  if (screenStatus?.not_found)
    return (
      <div>
        <PageHeader text={'Refund'}></PageHeader>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div>
            <img src='not_found.jpg' style={{ width: '20rem' }}></img>
          </div>
          <div>Link is expired.</div>
        </div>
      </div>
    )
  return (
    <div>
      <PageHeader text={'Refund'}></PageHeader>
      <div style={{ padding: '4rem' }} className='mt-4 mb-5'>
        <div className='font-weight-bold body1' style={{ color: primary_color }}>
          Approve Refund Request
        </div>
        <div className='my-3'>
          <div className='font-weight-bold subtitle1 mb-4' style={{ color: primary_color }}>
            Booking Detail
          </div>
          {bookingDetails?.map((item) => {
            return (
              <>
                <div className='row mb-5'>
                  {item?.map((field) => {
                    return (
                      <>
                        <div className='col-md-6'>
                          <div className='d-flex row mb-2'>
                            <div className='font-weight-bold subtitle1 col-md-4'>{field?.title} : </div>
                            <div className='subtitle1 col-md-8'>&nbsp;{field?.value}</div>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
                <hr></hr>
              </>
            )
          })}
        </div>
        <div className='my-3'>
          <div className='font-weight-bold subtitle1 mb-2' style={{ color: primary_color }}>
            Type
          </div>
          <div className='subtitle1 mb-4'>
            {refundData?.transaction_log?.refund_type?.name || refundData?.transaction_log?.type_label || refundData?.transaction_log?.type}
          </div>
        </div>

        <div className='my-3'>
          <div className='font-weight-bold subtitle1 mb-2' style={{ color: primary_color }}>
            Reason
          </div>
          <div className='subtitle1 mb-4' dangerouslySetInnerHTML={{ __html: refundData?.transaction_log?.reason }}></div>
        </div>

        <div className='my-3'>
          <div className='font-weight-bold subtitle1 mb-2' style={{ color: primary_color }}>
            Refund Note
          </div>
          <AvForm>
            <AvField name='refund_note' type='textarea' onChange={(e) => setRefundNote(e.target.value)}></AvField>
          </AvForm>
        </div>

        <div className='my-3' style={{ width: '20rem' }}>
          <div className='font-weight-bold subtitle1 mb-2' style={{ color: primary_color }}>
            Charges
          </div>
          <InputForAmount
            id='refund'
            value={refundAmount}
            label='Refund'
            onChangeValue={(val) => {
              setRefundAmount(val)
            }}></InputForAmount>
          <InputForAmount
            id='total_amount'
            value={parseInt(refundData?.payment?.amount)}
            isDisabled={true}
            label='Total Amount'
            onChangeValue={() => {}}></InputForAmount>
        </div>
        {error && (
          <div class='alert alert-danger my-4' role='alert'>
            {error}
          </div>
        )}
        <div className='my-5 d-flex '>
          <button
            className='btn rounded-pill mt-2 text-danger border-danger mr-3'
            type='button'
            onClick={(e) => {
              onDeclineRequest()
            }}
            style={{ fontWeight: 700, width: '12rem' }}>
            Decline
          </button>
          <button
            className='btn text-white rounded-pill mt-2 mr-3'
            disabled={!!error}
            type='submit'
            onClick={() => {
              onApproveRequest()
            }}
            style={{ background: primary_color, fontWeight: 700, width: '12rem' }}>
            Approve
          </button>
          <button
            className='btn text-white rounded-pill mt-2'
            disabled={!!error}
            type='submit'
            style={{ background: primary_color, fontWeight: 700, width: '14rem' }}
            onClick={() => {
              setScreenStatus({
                loader: false,
                success: false,
                rejected: false,
                email: true,
                not_found: false,
              })
            }}>
            Preview Email
          </button>
        </div>
      </div>
    </div>
  )
}

export default ApproveRefund

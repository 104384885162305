import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Col, FormGroup, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { useEffect } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import BlockReportsGlobalFilter from './blockReportFilter'
import AcademicYearGlobalFilter from './academicYearGlobalFilter'

const RenderFilterByPageName = ({ gridRelatedData }) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState([])
  const savedFilters = useSelector((state) => state.CMS.savedFilters)

  const { sortBy, getBySearch } = gridRelatedData

  useEffect(() => {
    console.log('saved filter:::::::::', savedFilters, gridRelatedData?.content?.name)
    let mainPageName = gridRelatedData?.content?.name
    if (savedFilters && savedFilters[mainPageName] && savedFilters[mainPageName]['-filter-date']) {
      let dateRange = savedFilters[mainPageName]['-filter-date']?.split('-')
      const dateObject1 = moment(dateRange?.[0], 'YYYY/MM/DD').toDate()
      const dateObject2 = moment(dateRange?.[1], 'YYYY/MM/DD').toDate()
      setSelectedTimeRange([dateObject1, dateObject2])
    } else {
      setSelectedTimeRange([])
    }
  }, [JSON.stringify(savedFilters)])

  useEffect(() => {
    if (selectedTimeRange?.length > 1 && selectedTimeRange?.[0] && selectedTimeRange?.[1]) {
      let text = moment(selectedTimeRange?.[0]).format('YYYY/MM/DD') + '-' + moment(selectedTimeRange?.[1]).format('YYYY/MM/DD')
      getBySearch({ ['-filter-' + 'date']: text || '' }, sortBy)
    }
  }, [selectedTimeRange])

  switch (gridRelatedData?.pageName) {
    case 'sales_reports_grid':
      return (
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label className='font-weight-bold'>Start Date</Label>
              <InputGroup>
                <InputGroupAddon addonType='prepend'>
                  <div className='input-group-text'>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </div>
                </InputGroupAddon>
                <DatePicker
                  selected={selectedTimeRange?.[0] ? new Date(selectedTimeRange?.[0]) : ''}
                  selectsStart
                  className='form-control'
                  onChange={(start) => setSelectedTimeRange([start, selectedTimeRange?.[1]])}
                  maxDate={new Date()}
                  placeholderText='Start Date'
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className='font-weight-bold'>End Date</Label>
              <DatePicker
                selected={selectedTimeRange?.[1] ? new Date(selectedTimeRange?.[1]) : ''}
                selectsEnd
                className='form-control'
                onChange={(end) => setSelectedTimeRange([selectedTimeRange?.[0], end])}
                maxDate={new Date()}
                minDate={selectedTimeRange?.[0] && new Date(selectedTimeRange?.[0])}
                placeholderText='End Date'
              />
            </FormGroup>
          </Col>
        </Row>
      )
    case 'block_reports_grid':
      return <BlockReportsGlobalFilter gridRelatedData={gridRelatedData}></BlockReportsGlobalFilter>
    case 'offsale_reports_grid':
    case 'off_sale_reports_grid':
    case 'locker_maintanance_reports_grid':
      return <AcademicYearGlobalFilter gridRelatedData={gridRelatedData} isOnlyAcademic></AcademicYearGlobalFilter>
  }
}

const RenderGlobalFilter = ({ gridRelatedData }) => {
  return (
    <>
      <RenderFilterByPageName gridRelatedData={gridRelatedData}></RenderFilterByPageName>
    </>
  )
}

export default RenderGlobalFilter

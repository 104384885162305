import { AvField } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { primary_color } from '../../../Helper/uiHelper'

const SubscribeEmail = ({ value }) => {
  const [checked, setChecked] = useState()

  useEffect(() => {
    setChecked(value || false)
  }, [value])
  return (
    <div className='d-flex align-items-center mt-2'>
      <div className='mb-2 primary_color'>
        <AvField
          type='checkbox'
          style={{ color: primary_color }}
          checked={checked}
          className='primary_color'
          name='subscribe'
          label='Receive communication emails from Locker Rentals'
          onChange={(e) => setChecked(e.target.checked)}></AvField>
      </div>
    </div>
  )
}

export default SubscribeEmail

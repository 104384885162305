import { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import PublicFields from './publicFields'

const FieldsLayout = ({
  content,
  form,
  setFormFieldValue,
  formFieldValue,
  parentData,
  validationError,
  setValidationError,
  allFormsData,
  unitData,
  setUnitData,
  formIndex,
  setFormData 
}) => {
  const [layout, setLayout] = useState()
  

  useEffect(() => {
    if (content && content[0]?.layout) {
      setLayout(JSON.parse(content[0]?.layout))
    }
  }, [])

  return (
    <>
      <Row>
        {layout?.map((row, rowIndex) => {
          return (
            <Col md={12} key={rowIndex}>
              <Row>
                {row?.column?.map((col, blockIndex) => {
                  return (
                    <Col md={col?.col} key={rowIndex + blockIndex}>
                      {/* <Row> */}
                      {form?.component_call_fields
                        ?.filter((field) => field?.is_layout === 1 && field?.block_index && field?.row_index)
                        ?.filter((field) => field?.row_index - 1 === rowIndex)
                        ?.filter((field) => field?.block_index - 1 === blockIndex)
                        ?.map((field, i) => {
                          if (field?.hidden === true || field?.hidden === 1) return
                          if (field?.component_name === 'hidden_data') return
                          return (
                            <PublicFields
                              validationError={validationError}
                              setValidationError={setValidationError}
                              data={field}
                              formFieldValue={formFieldValue}
                              setFormFieldValue={setFormFieldValue}
                              parentData={parentData}
                              form={form}
                              allFormsData={allFormsData}
                              unitData={unitData}
                              formIndex={formIndex}
                              setUnitData={setUnitData}
                              setFormData={setFormData}
                            />
                          )
                        })}
                      {/* </Row> */}
                    </Col>
                  )
                })}
              </Row>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default FieldsLayout

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import './roundedInput.scss'

const inputIconStyle = {
  position: 'absolute',
  fontSize: '1rem',
  top: '50%',
  left: '15px',
  transform: 'translate(-0%, -50%)',
  color: 'grey',
}
const endIconStyle = {
  position: 'absolute',
  fontSize: '1rem',
  top: '50%',
  right: '15px',
  transform: 'translate(-0%, -50%)',
  color: 'grey',
  cursor: 'pointer',
}

const RoundedSearch = ({
  startIcon,
  endIcon,
  type,
  searchData,
  setSearchData,
  field_id,
  placeholder,
  search_keyword_exclude = [],
  search_limit_error = 'Please search proper keyword',
  min_length,
  clearSearchData,
  setClearSearchData,
}) => {
  const [enteredValue, setEnteredValue] = useState()
  const [isError, setIsError] = useState(false)
  const value = searchData[field_id]

  const dispatch = useDispatch()

  useEffect(() => {
    setEnteredValue('')
  }, [])

  const handleSearch = (value) => {
    setEnteredValue(value)
    setIsError(false)
    if (min_length && value?.length < parseInt(min_length) && value) {
      return
    }
    console.log('search exclude::::::::', search_keyword_exclude, value)
    if (search_keyword_exclude?.includes(value)) {
      // dispatch(
      //   setNotification({
      //     type: 'error',
      //     message: search_limit_error,
      //   })
      // )
      if (!clearSearchData?.includes(field_id)) {
        const newErrorField = [...clearSearchData]
        newErrorField?.push(field_id)

        setClearSearchData(newErrorField)
      }

      setIsError(true)
      return
    }
    let newSearchData = {
      ...searchData,
    }

    if (clearSearchData?.includes(field_id)) {
      const index = clearSearchData?.indexOf(field_id)

      const newErrorField = [...clearSearchData]
      newErrorField.splice(index, 1)
      setClearSearchData(newErrorField)
    }
    newSearchData[field_id] = value
    setSearchData(newSearchData)
  }

  return (
    <div>
      <div className=' position-relative mb-2'>
        <input
          className='form-control rounded-pill border border-black pl-5 black-placeholder rounded-input'
          type={type || 'text'}
          name='password'
          onChange={(e) => handleSearch(e.target.value)}
          placeholder={placeholder}
          required
          value={enteredValue ? enteredValue : ''}
        />
        {startIcon && <i style={inputIconStyle} className={startIcon}></i>}
        {endIcon && (
          <i
            style={endIconStyle}
            onClick={() => {
              setEnteredValue('')
              handleSearch('')
            }}
            className={endIcon}
            
          ></i>
        )}
        {isError ? (
          <div className='subtitle1 text-danger position-absolute mt-2 mb-2' style={{ top: '104%', left: '10px' }}>
            {search_limit_error}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )

  // return (
  //   <div className='rounded-input-container d-flex align-items-center justify-content-center'>
  //     {startIcon && (
  //       <div className='mr-2'>
  //         <i className={startIcon}></i>
  //       </div>
  //     )}
  //     <input placeholder={placeholder} type='text' value={enteredValue ? enteredValue : ''} onChange={(e) => handleSearch(e.target.value)}></input>
  //     {endIcon && (
  //       <div className='ml-2 cursor-pointer' onClick={() => handleSearch(null)}>
  //         <i className={endIcon}></i>
  //       </div>
  //     )}
  //   </div>
  // )
}

export default RoundedSearch

import { AvForm } from 'availity-reactstrap-validation'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap'

import CMSField from '../../../../../Forms/Fields/index'
import {
  addDropdownOptionRefetchTable,
  callInsertRepeatingGridData,
  cms,
  getConnectTemplateReduxDataAfterEdit,
  getConnectTemplateReduxDataAfterEditByReactId,
  getConnectTemplateReduxDataAfterInsert,
  insertChildTableAddedDataInSync,
  isDirectUpdateConnectTemplate,
  updateChildTableAddedDataInSync,
  updateSyncRowId,
} from '../../../../../../helper'
import {
  clearCurrentRowData,
  clearParentTableData,
  fetchUpdateApiData,
  setConnectTemplateDataRedux,
  setCurrentRow,
  setDropdownOptionsByPage,
  setParentTableData,
  updateSubmittedChangedField,
} from '../../../../../../Redux/Reducers/CMS'
import { setNotification } from '../../../../../../Redux/Reducers/notificationHandling'
import { SetFullPage, setTabFormData } from '../../../../../../Redux/Reducers/page'
import { setQueue } from '../../../../../../Redux/Reducers/queue'
import { v4 as uuidv4 } from 'uuid'
import { request } from '../../../../../../Redux/Sagas/requests/api'

const DefaultForm = ({ addDataDetail, grid_id, parentPageName, isModel, setAddGridDataDetail, forms }) => {
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false)

  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const queues = useSelector((state) => state.Queue.queues)
  const [isTempIdGenerated, setIsTempIdGenerated] = useState()
  const [validationErrorOnFieldValueChange, setValidationErrorOnFieldValueChange] = useState([])

  const dispatch = useDispatch()
  let fields = addDataDetail?.mainGrid?.component_call_cols?.filter((col) => col?.component_name === 'text_data_col')

  const handleValidSubmit = (e, values) => {
    const source_table = addDataDetail?.tableGrid?.source_table
    const parentPageName = addDataDetail?.parentPageName
    const pageName = addDataDetail?.pageName
    let parent_table, parent_table_row_id, dataToUpdate
    if (currentRow) {
      if ((addDataDetail?.isAddNew && currentRow?.length > 0) || currentRow?.length > 1) {
        parent_table = parentTableDataInRedux && parentTableDataInRedux?.[0]?.source_table
        parent_table_row_id = currentRow[0]?.id
      }
    }
    let data = {}
    data[source_table] = { ...values, temporary_id: uuidv4() }

    if (addDataDetail?.isAddNew) {
      dataToUpdate = getConnectTemplateReduxDataAfterInsert(
        connectTemplateDataRedux,
        parentPageName,
        pageName,
        source_table,
        currentRow,
        data[source_table],
        parent_table ? (isModel ? false : true) : false
      )

      dispatch(setConnectTemplateDataRedux(dataToUpdate))
    } else {
      const currentRowData = [...currentRow]
      currentRowData?.pop()
      dataToUpdate = getConnectTemplateReduxDataAfterEdit(
        connectTemplateDataRedux,
        parentPageName,
        pageName,
        source_table,
        currentRow,
        data[source_table],
        parent_table ? (isModel ? false : true) : false
      )
      dispatch(setConnectTemplateDataRedux(dataToUpdate))
      dispatch(clearCurrentRowData())
      dispatch(clearParentTableData())
    }

    callInsertRepeatingGridData(data, source_table, addDataDetail, isModel, domain_id, parent_table, parent_table_row_id)
    // dispatch(
    //   setQueue({
    //     url: 'insert-repeating-grid-data',
    //     payload: {
    //       data: {
    //         data: data,
    //         is_nested: true,
    //         parent_table,
    //         parent_table_row_id,
    //         domain_id,
    //         clause_data: isModel && addDataDetail?.tableGrid?.main_grid?.where_clause,
    //       },
    //     },
    //   })
    // )
    dispatch(setNotification({ type: 'success', message: 'Form saved successfully!' }))

    if (addDataDetail?.isAddNew) {
      if (isSaveButtonClicked && !addDataDetail?.tableGrid?.grid_settings?.show_add_form_on_edit) {
        const editButtonInGrid = addDataDetail?.tableGrid?.main_grid?.component_call_cols?.filter((item) => item?.icon === 'far fa-edit')
        if (editButtonInGrid?.length > 0) {
          setTimeout(() => {
            dispatch(setCurrentRow(data[source_table]))
          }, 500)
          dispatch(
            setTabFormData({
              data: {
                ...data[source_table],
              },
              main_source_table: source_table,
            })
          )
          setAddGridDataDetail({ open: false })
          dispatch(
            setParentTableData({
              source_table: addDataDetail?.tableGrid?.source_table,
              parent_page_name: addDataDetail?.tableGrid?.name,
              grid_id: addDataDetail?.tableGrid?.main_grid?.id,
              child_table: addDataDetail?.tableGrid?.child_tabs?.component_call_buttons[0]?.source_table,
            })
          )
          dispatch(SetFullPage(false))
        } else {
          setAddGridDataDetail({ open: false })
        }
      } else if (!isSaveButtonClicked) {
        setAddGridDataDetail({ open: false })
      }
    }
    setAddGridDataDetail({ open: false })
  }
  const onSubmitForm = (e, values) => {
    const source_table = addDataDetail?.tableGrid?.source_table
    const parentPageName = addDataDetail?.parentPageName
    const pageName = addDataDetail?.pageName

    let parent_table, parent_table_row_id, parent_table_temporary_id
    let submitData = {}
    submitData[source_table] = { ...values }

    if (currentRow[currentRow?.length - 1]?.id && !addDataDetail?.isAddNew) {
      submitData[source_table] = {
        ...values,
        id: currentRow[currentRow?.length - 1]?.id,
        temporary_id: currentRow[currentRow?.length - 1]?.temporary_id,
      }
    }

    if (submitData && currentRow) {
      if ((addDataDetail?.isAddNew && currentRow?.length > 0) || currentRow?.length > 1) {
        parent_table = parentTableDataInRedux && parentTableDataInRedux?.[0]?.source_table
        parent_table_row_id = currentRow[0]?.id || currentRow[0]?.temporary_id
      }
    }

    //validation for scheduler

    let submitFormData = {}
    submitFormData[source_table] = {}

    if (addDataDetail?.isAddNew) {
      const id = uuidv4()

      submitFormData[source_table] = {
        ...submitData[source_table],
        temporary_id: isTempIdGenerated || id,
      }
      if (!isTempIdGenerated) {
        setIsTempIdGenerated(id)
      }
    } else {
      submitFormData[source_table] = {
        ...submitData[source_table],
      }
    }

    if (!isDirectUpdateConnectTemplate(addDataDetail?.tableGrid?.source_table, forms, isModel)) {
      const queueData = queues?.filter(
        (queue) => queue.url === 'insert-repeating-grid-data' && queue?.source_table === parent_table && !queue?.parent_table_row_id
      )
      if (
        parent_table &&
        !isModel &&
        ((currentRow?.length === 1 && !currentRow?.[currentRow?.length - 1]?.id && currentRow?.[currentRow?.length - 1]?.temporary_id) ||
          (currentRow?.length === 2 && !currentRow?.[currentRow?.length - 2]?.id && currentRow?.[currentRow?.length - 2]?.temporary_id)) &&
        queueData?.length > 0
      ) {
        // alert('called')
        if (isTempIdGenerated || !addDataDetail?.isAddNew) {
          let id
          if (currentRow?.length === 1) {
            id = currentRow?.[currentRow?.length - 1]?.temporary_id
          } else {
            id = currentRow?.[currentRow?.length - 2]?.temporary_id
          }
          updateChildTableAddedDataInSync(id, parent_table, source_table, submitFormData[source_table])
        } else {
          insertChildTableAddedDataInSync(
            currentRow?.[currentRow?.length - 1]?.temporary_id,
            parent_table,
            source_table,
            submitFormData[source_table]
          )
        }
      } else {
        callInsertRepeatingGridData(submitFormData, source_table, addDataDetail, isModel, domain_id, parent_table, parent_table_row_id)
      }

      dispatch(updateSubmittedChangedField())
      let dataToUpdate

      if (addDataDetail?.isAddNew) {
        if (addDataDetail?.parentPageName !== 'task_monitor' && !isTempIdGenerated) {
          dataToUpdate = getConnectTemplateReduxDataAfterInsert(
            { ...connectTemplateDataRedux },
            parentPageName,
            pageName,
            source_table,
            currentRow,
            submitFormData[source_table],
            parent_table ? (isModel ? false : true) : false
          )
        } else if (isTempIdGenerated) {
          dataToUpdate = getConnectTemplateReduxDataAfterEditByReactId(
            { ...connectTemplateDataRedux },
            parentPageName,
            pageName,
            source_table,
            currentRow,
            submitFormData[source_table],
            parent_table ? (isModel ? false : true) : false
          )
        }
      } else {
        if (addDataDetail?.parentPageName !== 'task_monitor') {
          dataToUpdate = getConnectTemplateReduxDataAfterEdit(
            { ...connectTemplateDataRedux },
            parentPageName,
            pageName,
            source_table,
            currentRow,
            submitData[source_table],
            parent_table ? true : false
          )

          updateSyncRowId(parentPageName, pageName, [submitData[source_table]?.id])
        }
      }
      if (!isDirectUpdateConnectTemplate(source_table) && source_table !== 'items') {
        dispatch(setConnectTemplateDataRedux(dataToUpdate))
      }

      if (!addDataDetail?.isAddNew) {
        if (!isSaveButtonClicked) {
          dispatch(clearCurrentRowData())
          dispatch(clearParentTableData())
          setAddGridDataDetail({ open: false })
        }
      } else {
        if (isSaveButtonClicked && !addDataDetail?.tableGrid?.grid_settings?.show_add_form_on_edit) {
          //  const editButtonInGrid = addDataDetail?.tableGrid?.main_grid?.component_call_cols?.filter((item) => item?.icon === 'far fa-edit')
          if (addDataDetail?.currentTabData?.component_name === 'hidden_tab' && !addDataDetail?.isModel) {
            setTimeout(() => {
              dispatch(setCurrentRow(submitFormData[source_table]))
            }, 500)
            dispatch(
              setTabFormData({
                data: {
                  ...submitFormData[source_table],
                },
                main_source_table: source_table,
              })
            )

            setAddGridDataDetail({ open: false })
            dispatch(
              setParentTableData({
                source_table: addDataDetail?.tableGrid?.source_table,
                parent_page_name: addDataDetail?.tableGrid?.name,
                grid_id: addDataDetail?.tableGrid?.main_grid?.id,
                child_table: addDataDetail?.tableGrid?.child_tabs?.component_call_buttons[0]?.source_table,
              })
            )
            dispatch(SetFullPage(false))
          }
        } else if (!isSaveButtonClicked) {
          setAddGridDataDetail({ open: false })
        }
      }
      dispatch(setNotification({ type: 'success', message: 'Form saved successfully!' }))
      setIsSaveButtonClicked(false)

      //
    } else {
      const submitData = submitFormData

      let dataInPayload = {}

      dataInPayload[source_table] = [submitData[source_table]]
      let payload = {
        data: dataInPayload,
        is_nested: true,    
        domain_id,
        clause_data: isModel && addDataDetail?.tableGrid?.main_grid?.where_clause,
      }
      if(!isModel){
        payload = {
          ...payload,
          parent_table: !isModel && parent_table,
          parent_table_row_id: !isModel && parent_table_row_id,
        }
      }
      request('insert-repeating-grid-data', 'POST', payload).then((res) => {
        if (res.status === 202 || res.status === 200) {
          dispatch(setNotification({ type: 'success', message: 'Form added successfully!' }))
          dispatch(fetchUpdateApiData())

          if (!addDataDetail?.isAddNew) {
            dispatch(clearCurrentRowData())
            dispatch(clearParentTableData())
          }

          // to update dropdown options with added data
          if (isModel) {
            dispatch(setDropdownOptionsByPage({}))
            addDropdownOptionRefetchTable(source_table)
          }

          let dataToUpdate

          // if (addDataDetail?.isAddNew) {
          //   dataToUpdate = getConnectTemplateReduxDataAfterInsert(
          //     { ...connectTemplateDataRedux },
          //     parentPageName,
          //     pageName,
          //     source_table,
          //     currentRow,
          //     submitData[source_table],
          //     parent_table ? (isModel ? false : true) : false
          //   )
          // } else {
          //   dataToUpdate = getConnectTemplateReduxDataAfterEdit(
          //     { ...connectTemplateDataRedux },
          //     parentPageName,
          //     pageName,
          //     source_table,
          //     currentRow,
          //     submitData[source_table],
          //     parent_table ? true : false
          //   )
          // }
          // if (!isDirectUpdateConnectTemplate(source_table)) {
          //   dispatch(setConnectTemplateDataRedux(dataToUpdate))
          // }

          setAddGridDataDetail({ open: false })
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        }
      })
    }
  }

  if (fields?.length === 0)
    return <div style={{ textAlign: 'center', marginTop: '3rem', marginBottom: '1rem', fontSize: '1.3rem' }}> Form not added yet!</div>
  return (
    <AvForm onValidSubmit={onSubmitForm} style={{ marginBottom: '5rem' }}>
      <Row>
        {fields
          ?.filter((item) => item?.field_id !== 'id')
          ?.map((field, key) => {
            return (
              <Col md={field?.col || 6} key={key + field.field_id}>
                <div className='mb-2'>
                  <CMSField
                    id={'fieldToolTip' + key + field.field_id}
                    fieldKey={key}
                    data={field}
                    isModel={isModel}
                    allFieldsInForm={fields}
                    parentPageName={parentPageName}
                    pageName={parentPageName}
                    source_table={addDataDetail?.tableGrid?.source_table}
                    formData={addDataDetail?.tableGrid}
                    noDefaultValue={addDataDetail?.isAddNew}
                    validationErrorOnFieldValueChange={validationErrorOnFieldValueChange}
                    setValidationErrorOnFieldValueChange={setValidationErrorOnFieldValueChange}
                  />
                  {field.cms_tooltip && field.cms_tooltip !== null && (
                    <UncontrolledTooltip placement='left' target={'fieldToolTip' + key + field.field_id}>
                      {cms(field.cms_tooltip)}
                    </UncontrolledTooltip>
                  )}
                </div>
              </Col>
            )
          })}
        <Col md={12}>
          {validationErrorOnFieldValueChange?.map((item, index) => {
            return (
              <div class='alert alert-danger mb-1' role='alert' key={index}>
                {item?.message}
              </div>
            )
          })}
        </Col>
        <Col md={12}>
          <div className='wizard-single-form-with-child-submit float-right'>
            <Button
              color='primary'
              disabled={validationErrorOnFieldValueChange?.length > 0}
              size='lg'
              className={`btn-shadow float-right`}
              style={{ marginBottom: '1rem' }}
              id='submitAndCloseButtonDefault'>
              Save & Close
            </Button>
            <Button
              color='primary'
              size='lg'
              className={`btn-shadow float-right mr-2`}
              style={{ marginBottom: '1rem' }}
              disabled={validationErrorOnFieldValueChange?.length > 0}
              onClick={(e) => {
                setIsSaveButtonClicked(true)
                setTimeout(() => {
                  document.getElementById('submitAndCloseButtonDefault').click()
                }, 500)
              }}>
              Save
            </Button>
            <Button
              color='secondary'
              // disabled={validationErrorOnFieldValueChange?.length > 0}
              size='lg'
              className={`btn-shadow float-right mr-2 `}
              style={{ marginBottom: '1rem' }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setAddGridDataDetail({ open: false })
              }}>
              Close
            </Button>
          </div>
        </Col>
      </Row>
    </AvForm>
  )
}

export default DefaultForm

const TransactionStatus = ({inputValue}) =>{
  if(inputValue === 'Approved'){
    return <span class='badge badge-success'>Approved</span>
  } else if(inputValue === 'Decline'){
    return <span class='badge badge-danger'>Decline</span>
  } else if(inputValue === 'Waiting  For Approval'){
    return <span class='badge badge-warning'>Waiting For Approval</span>
  } else if(inputValue === 'Accept'){
    return <span class='badge badge-primary'>Accept</span>
  }else if(inputValue === 'Pending'){
    return <span class='badge badge-warning'>Pending</span>
  }
}

export default TransactionStatus
import 'bootstrap/dist/css/bootstrap.min.css'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { container_width, primary_color } from '../../../Helper/uiHelper'
import CMSCarousal from '../../CMSWidget/Carousal/cmsCarousal'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import { useSelector } from 'react-redux'

const ImageCarousel = ({ widget, data, widget_uuid }) => {
  const history = useHistory()

  const [modalPosition, setModalPosition] = useState()

  const sectionRef = useRef(null)
  const editMode = useSelector((state) => state.CMS.editMode)

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        //  top: rect.top + window.scrollY - 50,
        top: rect.height + 100,

        left: 0, // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '100%',
      })
    }
  }

  const isDesktop = useMediaQuery('(min-width:768px)')
  const images = []

  data
    ?.filter((item) => item?.component_name === 'slider_images' || item?.component_name === 'text')
    ?.map((slide) => {
      const widgetData = slide?.child_widget_data
      let image, heading, sub_heading, button_text, action_url

      widgetData?.map((item) => {
        switch (item?.component_name) {
          case 'heading':
            heading = item?.value
            return
          case 'sub_heading':
            sub_heading = item?.value
            return
          case 'button_text':
            button_text = item?.value
            return
          case 'image':
          case 'slider_image':
            image = item?.value
            return
          case 'action_url':
            action_url = item?.value
            return
        }
      })

      images?.push({
        image: image,
        heading: heading,
        sub_heading: sub_heading,
        button_text: button_text,
        action_url: action_url,
      })
    })
  // widget?.widget_child?.map((item) => {
  //   let data = item?.widget_data
  //   images?.push({
  //     image: data?.image,
  //     heading: data?.heading,
  //     sub_heading: data?.sub_heading,
  //     button_text: data?.button_text,
  //     action_url: data?.action_url,
  //   })
  // })
  // const images = [
  //   'https://images.ctfassets.net/hrltx12pl8hq/28ECAQiPJZ78hxatLTa7Ts/2f695d869736ae3b0de3e56ceaca3958/free-nature-images.jpg?fit=fill&w=1200&h=630',
  //   'https://cdn.create.vista.com/api/media/small/4493390/stock-photo-green-field-and-blue-sky',
  //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_de8D-YF5d8HWllKAQw4NNQZA2UOOV7s0DWf2pcsX5INujXXRhAvGRbzm57wlgVSViJ0&usqp=CAU',
  // ]

  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 2000)

    return () => clearInterval(interval)
  }, [images.length])

  const goToPrevSlide = () => {
    setIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1))
  }

  const goToNextSlide = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length)
  }
  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }
  if (images?.length === 0) return
  return (
    <div
      id='carouselExample'
      className={`carousel position-relative slide  ${isDesktop && 'px-3vw'} ${modalPosition && 'active-cms-edit'}`}
      data-ride='carousel'
      onClick={handleSectionClick}
      ref={sectionRef}
      style={{ borderRadius: '16px', marginTop: '6rem', marginBottom: '6rem', maxWidth: container_width, marginLeft: 'auto', marginRight: 'auto' }}>
      <div className='carousel-inner'>
        {images.map((image, idx) => (
          <div key={idx} className={`carousel-item ${index === idx ? 'active' : ''}`}>
            <img
              src={image?.image && process.env.REACT_APP_STORAGE + image?.image}
              className='d-block w-100 position-relative'
              alt={`Slide ${idx + 1}`}
              height={600}
              style={{ borderRadius: '16px', zIndex: 0, objectFit: 'cover', objectPosition: 'center' }}
            />
            <div className='gradient-overlay'></div>
          </div>
        ))}
      </div>
      <div
        onClick={goToPrevSlide}
        className='d-flex align-items-center justify-content-center position-absolute cursor-pointer'
        style={{
          boxShadow: '1px 1px 10px 0px #4e4e4e',
          top: '284px',
          left: isDesktop ? '1.5vw' : '-1rem',
          borderRadius: '50%',
          background: '#ffffffcc',
          width: '3rem',
          height: '3rem',
          zIndex: 11,
        }}>
        <i class='fa-solid fa-chevron-left'></i>
      </div>
      <div
        onClick={goToNextSlide}
        className='d-flex align-items-center justify-content-center position-absolute cursor-pointer'
        style={{
          boxShadow: '1px 1px 10px 0px #4e4e4e',
          top: '284px',
          right: isDesktop ? '1.5vw' : '-1rem',
          borderRadius: '50%',
          background: '#ffffffcc',
          width: '3rem',
          height: '3rem',
          zIndex: 11,
        }}>
        <i class='fa-solid fa-chevron-right'></i>
      </div>
      <div
        className='position-absolute'
        style={{
          top: isDesktop ? '80px' : '20px',
          left: isDesktop ? '100px' : '10px',
          width: isDesktop ? '60%' : '100%',
          padding: isDesktop ? '3rem 4rem' : '3rem 2rem',
          zIndex: 10,
        }}>
        <div className='text-white font-weight-bold mb-3 h2' dangerouslySetInnerHTML={{ __html: images?.[index]?.heading }}>
          {/* {images?.[index]?.heading} */}
        </div>
        <div
          className='text-white body1 mb-5 line-clamp-4'
          dangerouslySetInnerHTML={{
            __html: images?.[index]?.sub_heading,
          }}></div>
        <Button
          className='poppin d-flex align-items-center justify-content-center mt-3'
          style={{
            color: 'white',
            border: `1px solid ${primary_color}`,
            // maxWidth: isDesktop ? 300 : '100%',
            // height: isDesktop ? 45 : 45,
            backgroundColor: primary_color,
            borderRadius: 30,
            padding: '0.8rem 3rem',
            width: 'fit-content',
          }}
          onClick={() => {
            history.push(images?.[index]?.action_url)
          }}
          // onClick={buttonAction}
        >
          <span style={{ fontWeight: 600 }} className='body2'>
            {images?.[index]?.button_text}
          </span>
          <i className={`fa-solid fa-arrow-right-long ml-2 body1`}></i>
        </Button>
      </div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <div className='m-5' style={{ border: '1px solid #00000044', borderRadius: '10px', padding: '2rem' }}>
            <CMSCarousal
              data={data}
              widget_uuid={widget_uuid}
              toggleModal={() => {
                console.log('clicked')
                onCloseModal()
              }}
              index={index}
            />
          </div>
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default ImageCarousel

import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import { cms } from '../../../helper'
import { useSelector } from 'react-redux'
import { useRef, useState } from 'react'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSEmailDetails from '../../CMSWidget/EmailDetails/cmsEmailDetails'

const dummy_subheading = 'The mail may take some time to be delivered. Please check your spam folder.'
const EmailDetails = ({ data, widget_uuid }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  const editMode = useSelector((state) => state.CMS.editMode)
  const sectionRef = useRef()

  const [modalPosition, setModalPosition] = useState()

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        top: 250,
        // left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '40vw',
      })
    }
  }

  let heading = '<p><b>Note:</b></p>'
  let sub_heading = '<p><b>The mail may take some time to be delivered. Please check your spam folder.</b></p>'
  if (data?.heading) {
    heading = data?.heading
  }
  if (data?.sub_heading) {
    sub_heading = data?.sub_heading
  }

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }
  return (
    <div style={{ marginTop: isDesktop ? '8rem' : '2rem', padding: isDesktop ? '0 0 0 6rem' : '1rem' }} ref={sectionRef} onClick={handleSectionClick}>
      <div className=' text-left font-weight-bold body1 mb-4' style={{ color: primary_color }} dangerouslySetInnerHTML={{ __html: heading }}></div>
      <div className='font-weight-bold subtitle1' dangerouslySetInnerHTML={{ __html: sub_heading }}></div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSEmailDetails data={data} toggleModal={() => onCloseModal()} widget_uuid={widget_uuid} />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default EmailDetails

import { useSelector } from 'react-redux'
import useGetEmailTemplateResources from '../../../Queries/EmailTemplate/useGetEmailTemplateResource'
import Widget from './widget'

const TemplateWidgetListing = () => {
  const currentRow = useSelector((state) => state.CMS.currentRow)

  let data = currentRow?.[currentRow?.length - 1]

  const { data: tagsNWidgets } = useGetEmailTemplateResources(data?.uuid)

  return (
    <div>
      {tagsNWidgets?.emailWidgets?.map((item, index) =>{
        if(item?.component_name){
          return <Widget data={item} key={index} />
        }else{
          return <></>
        }
      })}
    </div>
  )
}

export default TemplateWidgetListing

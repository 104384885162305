const TransactionLogAction = ({ onClick }) => {
  return (
    <>
      <button
        className='bg-warning px-2 rounded cursor-pointer text-white'
        style={{ border: 'none', paddingTop:'0.3rem', paddingBottom:'0.3rem' }}
        onClick={() => {
          onClick()
        }}>
        Refund Request
      </button>
    </>
  )
}

export default TransactionLogAction

import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import 'rc-tabs/assets/index.css'

const CustomPrevIcon = () => <div style={{ fontSize: '24px', display: 'flex', alignItems: 'center', marginTop: '19px' }}>⬅️</div>

// Custom right icon component
const CustomNextIcon = () => <div style={{ fontSize: '24px', display: 'flex', alignItems: 'center', marginTop: '15px' }}>➡️</div>

const RCTabs = ({ items, selectedTabKey, onChange: onChangeTab, activeTab }) => {
  return (
    <Tabs
      defaultActiveKey='0'
      activeKey={activeTab?.toString() || '0'}
      onChange={(e) => onChangeTab(e)}
      renderTabBar={(props) => (
        <ScrollableInkTabBar
          inkBarStyle={{ backgroundColor: 'blue', height: '2px' }}
          {...props}
          prevIcon={<CustomPrevIcon />}
          nextIcon={<CustomNextIcon />}
        />
      )}
      renderTabContent={() => <TabContent />}>
      {items?.map((tab, index) => {
        return (
          <TabPane tab={tab?.title} key={index} style={{ minHeight: '60vh' }}>
            {activeTab === index && (
              <div>
                <div key={index}>{tab?.getContent()}</div>
              </div>
            )}
          </TabPane>
        )
      })}
    </Tabs>
  )
}

export default RCTabs

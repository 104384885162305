import { container_width } from "../../../Helper/uiHelper"

export const getContainerPadding = (isDesktop, isFluid) => {
  if (isDesktop) {
    if (isFluid) {
      return '0'
    } else {
      return '6rem'
    }
  } else {
    return '0'
  }
}


export const getContainerWidth = (section) =>{
  if (
    section?.widgets?.[0]?.component_name === 'hero_bg' ||
    section?.widgets?.[0]?.component_name == 'hero_search' ||
    section?.widgets?.[0]?.component_name == 'payment_success'
  ) {
    return
  } else {
    return container_width
  }
}

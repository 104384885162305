import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row, UncontrolledCollapse, UncontrolledTooltip } from 'reactstrap'
import cx from 'classnames'
import RLDD from 'react-list-drag-and-drop/lib/RLDD'

import {
  cms,
  deleteChangedFieldInReduxByCurrentRowId,
  getGridFormByGridId,
  tidyName,
  updateGridFormInReduxByGridId,
  updateGridFormInReduxByPageName,
} from '../../../../helper'
import PageTitle from '../../../../Layout/AppMain/pageTitle'
import { clearCurrentRowData, clearParentTableData, setCoreData, setDomain, submitRequest } from '../../../../Redux/Reducers/CMS'
import { request } from '../../../../Redux/Sagas/requests/api'
import ConfirmButton from '../../../Common/confirmButton'
import FieldSetting from '../../../EditCMS/ContentForms/fieldSetting'

import IconSet from '../../../icon'
import ShowAllForms from './Component/WizardForm/showAllForm'
import Loader from 'react-loaders'
import ImportTemplate from './importTemplate'
import DefaultForm from './Component/DefaultForm/defaultForm'
import SelectDomainField from '../../../Common/selectDomain'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import AddRefund from '../../../../Components/PageSpecificComponents/Refund/addRefund'

const AddGridData = ({ addDataDetail, setAddGridDataDetail, hideTitle = false, clearCurrentRowOnBack = true, isModel, onGoBack = () => {} }) => {
  const editPageMode = useSelector((state) => state.CMS.editPageMode)
  const [addFormOpen, setAddFormOpen] = useState(false)
  const fieldCreatedStatus = useSelector((state) => state.CMS.fieldCreatedStatus) || false
  const gridForm = useSelector((state) => state.CMS.gridForm)
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)

  const [importTemplateModalOpen, setImportTemplateModalOpen] = useState(false)

  const [addFormList, setAddFormList] = useState([])
  const [getFormLoading, setGetFormLoading] = useState(false)
  const [importTemplateLoading, setImportTemplateLoading] = useState(false)
  const [importedTemplates, setImportedTemplates] = useState()
  const [refetchForm, setRefetchForm] = useState(false)

  const currentRow = useSelector((state) => state.CMS.currentRow)

  const dispatch = useDispatch()

  const coreData = useSelector((state) => state.CMS.coreData)

  useEffect(() => {
    const data = {
      grid_id: addDataDetail?.mainGrid?.id,
    }
    if (getGridFormByGridId(addDataDetail?.mainGrid?.id)) {
      const formList = getGridFormByGridId(addDataDetail?.mainGrid?.id)

      setAddFormList(formList)
    } else {
      setGetFormLoading(true)
      request('get-grid-form', 'POST', data).then((response) => {
        if (response?.data) {
          setAddFormList(response?.data)
          setGetFormLoading(false)
          updateGridFormInReduxByGridId(response?.data, addDataDetail?.mainGrid?.id)
        } else {
          setAddFormList([])
          setGetFormLoading(false)
          dispatch(setNotification({ type: 'error', message: 'Something went wrong!' }))
        }
      })
    }
    if (addDataDetail?.isEditForm && !addDataDetail?.considerCurrentRow) {
      if (addDataDetail?.tableGrid?.source_table === 'template_groups') {
        setImportTemplateLoading(true)
        request('get-template-group', 'POST', { id: addDataDetail?.idToLoadData }).then((response) => {
          if (response.data) {
            const { message, success, ...rest } = response.data
            setImportedTemplates(rest)
          }
          setImportTemplateLoading(false)
        })
      } else {
        setImportTemplateLoading(true)
        request('get-item-group', 'POST', { id: addDataDetail?.idToLoadData }).then((response) => {
          if (response.data) {
            const { message, success, ...rest } = response.data
            setImportedTemplates(rest)
          }
          setImportTemplateLoading(false)
        })
      }
    }

    if (addDataDetail?.considerCurrentRow) {
      let currentForm = {}
      currentForm[addDataDetail?.tableGrid?.source_table] = currentRow[currentRow?.length - 1]
      setImportedTemplates(currentForm)
    }
  }, [])

  useEffect(() => {
    if (fieldCreatedStatus || refetchForm) {
      const data = {
        grid_id: addDataDetail?.mainGrid?.id,
      }
      request('get-grid-form', 'POST', data).then((response) => {
        setRefetchForm(false)

        if (response?.data) {
          setAddFormList(response?.data)
        }
        updateGridFormInReduxByGridId(response?.data, addDataDetail?.mainGrid?.id)
      })
    }
    setTimeout(() => {
      if (fieldCreatedStatus) {
        dispatch(fieldCreatedStatus(false))
      }
    }, 500)
  }, [fieldCreatedStatus, refetchForm])

  const onValueChange = (value, field_id) => {
    //
  }

  if (addDataDetail?.currentTabData?.source_table === 'transaction_logs')
    return (
      <AddRefund
        addDataDetail={addDataDetail}
        addFormList={addFormList}
        onCancel={() => {
          onGoBack()
          setAddGridDataDetail({ open: false })
        }}></AddRefund>
    )
  return (
    <div>
      {!hideTitle && (
        <PageTitle
          heading={tidyName(addDataDetail?.parentPageName)}
          subheading={addDataDetail?.isEditForm ? 'Edit Data' : 'Add data'}
          icon='pe-7s-browser icon-gradient bg-happy-fisher'
          hideBreadCrumbs
        />
      )}
      {console.log('add data detail:::::::::', addDataDetail)}

      <div style={{ color: 'black', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem', marginTop: '2px' }}>
        {' '}
        <span>
          <i
            className='fa-solid fa-arrow-left bg-light'
            style={{ padding: '0.5rem', cursor: 'pointer', borderRadius: '20px', fontSize: '15px', marginTop: '20px', border: '1px solid' }}
            onClick={() => {
              const updatedCoreData = {
                ...coreData,
                currentFormData: {},
                currentFormFullData: {},
              }
              onGoBack()

              dispatch(setCoreData(updatedCoreData))
              if ((clearCurrentRowOnBack || addDataDetail?.defaultDataFromCurrentRow) && !addDataDetail?.isAddNew) {
                dispatch(clearCurrentRowData())
                dispatch(clearParentTableData())
                deleteChangedFieldInReduxByCurrentRowId(currentRow[currentRow?.length - 1]?.id)
              }
              setTimeout(() => {
                setAddGridDataDetail({ open: false })
              }, 500)
            }}></i>
        </span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!addDataDetail?.isEditForm &&
            (addDataDetail?.tableGrid.source_table === 'item_groups' || addDataDetail?.tableGrid.source_table === 'items') && (
              <Button
                onClick={() => {
                  setImportTemplateModalOpen(true)
                }}
                className='float-right mt-4'
                color='primary'>
                Build From Template
              </Button>
            )}
          <div style={{ marginLeft: '1rem', display: 'flex', alignItems: 'center' }}>
            <AvForm style={{ paddingTop: '1rem' }}>
              {' '}
              <SelectDomainField />
            </AvForm>
          </div>
          {editPageMode && (
            <Button color='link' className='edit-form-page-button' onClick={() => setAddFormOpen(true)}>
              <IconSet icon={'fas fa-pen'} bgcolor='green' color='info' navheadericon='true' id='add_form' />
              <UncontrolledTooltip placement='right' target='add_form'>
                Add Form
              </UncontrolledTooltip>
            </Button>
          )}
        </div>
        <Modal
          isOpen={importTemplateModalOpen}
          toggle={() => {
            setImportTemplateModalOpen(!importTemplateModalOpen)
          }}
          style={{ width: 500 }}>
          <ModalHeader>Import Template</ModalHeader>
          <ModalBody>
            <ImportTemplate
              importedTemplates={importedTemplates}
              setImportedTemplates={setImportedTemplates}
              setImportTemplateModalOpen={setImportTemplateModalOpen}
            />
          </ModalBody>
        </Modal>
      </div>
      {(getFormLoading || importTemplateLoading) && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loader type='ball-grid-pulse' color='blue' />
        </div>
      )}

      {addFormList && addFormList?.length > 0 && !getFormLoading && !importTemplateLoading ? (
        <ShowAllForms
          forms={addFormList}
          onValueChange={onValueChange}
          parentPageName={addDataDetail?.parentPageName}
          grid_id={addDataDetail?.mainGrid?.id}
          setAddFormList={setAddFormList}
          setAddGridDataDetail={setAddGridDataDetail}
          addDataDetail={addDataDetail}
          importedTemplates={importedTemplates}
          isModel={isModel}
        />
      ) : !getFormLoading && !importTemplateLoading ? (
        <DefaultForm
          parentPageName={addDataDetail?.parentPageName}
          grid_id={addDataDetail?.mainGrid?.id}
          setAddGridDataDetail={setAddGridDataDetail}
          addDataDetail={addDataDetail}
          isModel={isModel}
        />
      ) : (
        <></>
      )}

      <Modal isOpen={addFormOpen} toggle={() => setAddFormOpen(!addFormOpen)} style={{ width: '900px' }} scrollable={true}>
        <ModalHeader toggle={() => setAddFormOpen(!addFormOpen)}>Add Form</ModalHeader>
        <ModalBody>
          <AddFormInGridData
            source_table={addDataDetail?.tableGrid?.source_table}
            addDataDetail={addDataDetail}
            setAddFormOpen={setAddFormOpen}
            addFormList={addFormList}
            setAddFormList={setAddFormList}
            pageName={addDataDetail?.parentPageName}
            grid_id={addDataDetail?.mainGrid?.id}
            refetchForm={refetchForm}
            setRefetchForm={setRefetchForm}
          />
        </ModalBody>
      </Modal>
    </div>
  )
}

//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::://
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::://
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::://
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::://

export const AddFormInGridData = ({
  source_table,
  pageName,
  grid_id,
  addDataDetail,
  setAddFormOpen,
  addFormList,
  setAddFormList,
  isChildFrom,
  childFormName,
  refetchForm,
  setRefetchForm,
}) => {
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)
  const [form, updateForm] = useState({ tab_name: '', grid_tab_method: '', repeatable_form: false })
  const [isAddFormOpen, setIsAddFormOpen] = useState(false)
  const [formOrder, setFormOrder] = useState([])
  const [RLDDFormList, setRLDDFormList] = useState([])
  const [isEnableOrder, setIsEnableOrder] = useState(false)

  useEffect(() => {
    const newList = addFormList?.map((item, key) => {
      return {
        ...item,
        id: key,
      }
    })
    setRLDDFormList(newList)
  }, [addFormList])

  const dispatch = useDispatch()

  const submitForm = () => {
    const data = {
      ...form,
      grid_id: grid_id,
      page_name: pageName,
      name: form?.tab_name,
      // repeatable_form:true
    }
    if (isChildFrom) {
      const data = {
        ...form,
        form_name: childFormName,
        page_name: pageName,
        name: form?.tab_name,
        grid_id: grid_id,
      }
      request('submit-page-form', 'POST', data).then((item) => {
        const data = {
          grid_id: grid_id,
        }
        setIsAddFormOpen(false)
        setRefetchForm(true)
        // request('get-grid-form', 'POST', data).then((response) => {
        //   if (response?.data) {
        //     setAddFormList(response?.data)
        //   }
        // })
      })
    } else {
      request('submit-page-form', 'POST', data).then((item) => {
        const data = {
          grid_id: grid_id,
        }
        setIsAddFormOpen(false)
        setRefetchForm(true)
        // request('get-grid-form', 'POST', data).then((response) => {
        //   if (response?.data) {
        //     setAddFormList(response?.data)
        //   }
        // })
      })
    }

    setAddFormOpen(false)
  }
  const selected_method = grid_selector[source_table]?.methods?.filter((item) => item?.name === form?.grid_tab_method)

  return (
    <>
      <AvForm>
        <Row>
          <Col md={12}>
            <div className='float-right'>
              <Button
                color={isAddFormOpen ? 'danger' : 'success'}
                onClick={() => {
                  setIsAddFormOpen(!isAddFormOpen)
                }}
                className='btn m-1 float-right'>
                <IconSet icon={isAddFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
              </Button>
            </div>
          </Col>
          {isAddFormOpen && (
            <>
              <Col md={6}>
                <AvField type='text' name='tab_name' label='Tab Name' onChange={(e) => updateForm({ ...form, tab_name: e.target.value })}></AvField>
              </Col>
              <Col md={6}>
                <AvField
                  type='select'
                  name='grid_tab_method'
                  label='Select Method'
                  onChange={(e) => updateForm({ ...form, grid_tab_method: e.target.value })}>
                  <option value=''>Select Method</option>
                  {grid_selector[source_table]?.methods?.map((option, i) => (
                    <option value={option?.name} key={i}>
                      {tidyName(option?.name)}
                    </option>
                  ))}
                </AvField>
              </Col>
              {selected_method && selected_method?.length > 0 && selected_method[0]?.repeatable && (
                <Col md={6}>
                  <div style={{ marginBottom: '1rem' }}>Repeatable</div>
                  <div
                    className='switch has-switch mb-2 mr-2'
                    data-on-label='ON'
                    data-off-label='OFF'
                    onClick={() => updateForm({ ...form, repeatable_form: !form?.repeatable_form })}>
                    <div
                      className={cx('switch-animate', {
                        'switch-on': form?.repeatable_form,
                        'switch-off': !form?.repeatable_form,
                      })}>
                      <input type='checkbox' />
                      <span className='switch-left bg-success'>ON</span>
                      <label>&nbsp;</label>
                      <span className='switch-right bg-success'>OFF</span>
                    </div>
                  </div>
                </Col>
              )}
              <Col md={12}>
                <Button
                  color='primary'
                  onClick={() => {
                    submitForm()
                  }}>
                  Save Form
                </Button>
              </Col>
            </>
          )}
        </Row>
      </AvForm>
      {RLDDFormList?.length > 1 && (
        <Button
          className='btn btn-info float right m-2'
          onClick={() => {
            setIsEnableOrder(!isEnableOrder)
          }}>
          {isEnableOrder ? 'Disable' : 'Enable'} Order
        </Button>
      )}

      <ListGroup style={{ marginTop: '1rem' }}>
        {isEnableOrder && RLDDFormList && RLDDFormList?.length > 0 && (
          <RLDD
            cssClasses='example'
            items={RLDDFormList}
            itemRenderer={(form, key) => {
              return (
                <>
                  <ListGroupItem id={form?.name?.replace(/ /g, '')}>
                    {tidyName(form?.name)}{' '}
                    <div className='float-right'>
                      <ConfirmButton
                        style={{ padding: 0 }}
                        color='link'
                        id={form?.name?.replace(/ /g, '')}
                        clickEvent={() => {
                          const data = {
                            grid_id: grid_id,
                            name: form?.name,
                            page_name: pageName,
                          }
                          request('remove/single-form', 'POST', data).then((item) => {
                            const data = {
                              grid_id: grid_id,
                            }
                            setRefetchForm(true)
                            // request('get-grid-form', 'POST', data).then((response) => {
                            //   if (response?.data) {
                            //     setAddFormList(response?.data)
                            //   }
                            // })
                          })
                          // dispatch(submitRequest({ url: 'remove/single-form', data }))
                        }}>
                        <IconSet icon={'fa-solid fa-xmark'} color='danger' />
                      </ConfirmButton>
                    </div>
                  </ListGroupItem>
                  <UncontrolledCollapse toggler={'#' + form?.name?.replace(/ /g, '')}>
                    <div style={{ border: '1px solid #bfbfbf', borderRadius: '0.5rem', borderTopLeftRadius: '0rem', borderTopRightRadius: '0rem' }}>
                      <AddFieldInForm
                        source_table={form?.grid_tab_table || form?.grid_tab_method}
                        form={form}
                        parentPageName={pageName}
                        addDataDetail={addDataDetail}
                        setAddFormList={setAddFormList}
                        isChildFrom={isChildFrom}
                        grid_id={grid_id}

                        // childParentPageName={}
                      />
                      <hr></hr>
                      <div style={{ padding: '1rem' }}>
                        <AddChildForm form={form} grid_id={grid_id} parentPageName={pageName} setRefetchForm={setRefetchForm} />
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </>
              )
            }}
            onChange={(e) => {
              // setAllFields(e)
              setRLDDFormList(e)
              let requestData = []
              e &&
                e.map((item, key) => {
                  requestData.push({
                    name: item.field_id || item.name,
                    order: key,
                  })
                })
              setFormOrder(requestData)

              if (requestData?.length > 0) {
                request('order-grid-forms', 'POST', {
                  data: requestData,
                  // form_name: formName,
                  page_name: addDataDetail?.parentPageName,
                  grid_id: addDataDetail?.mainGrid?.id,
                  // tab:true
                }).then((res) => {
                  setRefetchForm(true)
                })
              }
            }}
          />
        )}
        {console.log('add form list::::::::', addFormList)}

        {addFormList &&
          !isEnableOrder &&
          addFormList?.length > 0 &&
          addFormList?.map((form, index) => {
            return (
              <React.Fragment key={index}>
                <ListGroupItem id={form?.name?.replace(/ /g, '')}>
                  {tidyName(form?.name)}{' '}
                  <div className='float-right'>
                    <ConfirmButton
                      style={{ padding: 0 }}
                      color='link'
                      id={form?.name?.replace(/ /g, '')}
                      clickEvent={() => {
                        const data = {
                          grid_id: grid_id,
                          name: form?.name,
                          page_name: pageName,
                        }
                        request('remove/single-form', 'POST', data).then((item) => {
                          const data = {
                            grid_id: grid_id,
                          }
                          setRefetchForm(true)
                          // request('get-grid-form', 'POST', data).then((response) => {
                          //   if (response?.data) {
                          //     setAddFormList(response?.data)
                          //   }
                          // })
                        })
                        // dispatch(submitRequest({ url: 'remove/single-form', data }))
                      }}>
                      <IconSet icon={'fa-solid fa-xmark'} color='danger' />
                    </ConfirmButton>
                  </div>
                </ListGroupItem>
                <UncontrolledCollapse toggler={'#' + form?.name?.replace(/ /g, '')}>
                  <div style={{ border: '1px solid #bfbfbf', borderRadius: '0.5rem', borderTopLeftRadius: '0rem', borderTopRightRadius: '0rem' }}>
                    <AddFieldInForm
                      source_table={form?.grid_tab_table || form?.grid_tab_method}
                      form={form}
                      parentPageName={pageName}
                      addDataDetail={addDataDetail}
                      setAddFormList={setAddFormList}
                      isChildFrom={isChildFrom}
                      grid_id={grid_id}
                    />
                    <hr></hr>
                    <div style={{ padding: '1rem' }}>
                      <AddChildForm form={form} grid_id={grid_id} parentPageName={pageName} setRefetchForm={setRefetchForm} />
                    </div>
                  </div>
                </UncontrolledCollapse>
              </React.Fragment>
            )
          })}
      </ListGroup>
    </>
  )
}

const AddFieldInForm = ({ form, source_table, parentPageName, addDataDetail, setAddFormList, grid_id }) => {
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)
  const [selectedField, setSelectedField] = useState('')

  const selected_grid_method_table = grid_selector[source_table]?.methods?.filter((item) => item?.name === form?.grid_tab_method)

  let fields_options = []

  if (selected_grid_method_table && selected_grid_method_table?.length > 0) {
    fields_options = grid_selector[selected_grid_method_table[0]?.table]?.columns
  }

  const saveField = () => {
    const selected_column = fields_options?.filter((item) => item?.field_id === selectedField || item?.name === selectedField)
    const data = {
      grid_id: grid_id,
      page_name: parentPageName,
      form_name: form?.name,
      field_id: selectedField,
      type: selected_column[0]?.field || selected_column[0]?.type,
    }
    request('submit-form-field', 'POST', data).then((item) => {
      const data = {
        grid_id: grid_id,
      }
      request('get-grid-form', 'POST', data).then((response) => {
        if (response?.data) {
          setAddFormList(response?.data)
        }
      })
    })
  }

  return (
    <AvForm>
      <Row>
        <Col md={12}>
          <FieldSetting
            formName={form?.name}
            pageName={parentPageName}
            fields={form?.component_call_fields}
            tab={true}
            // section={title}
            source_table={source_table}
            fieldForm={form}
            grid_id={grid_id}
            isAddDataForm={true}
            setAddFormList={setAddFormList}
            parentPageName={parentPageName}
            addDataDetail={addDataDetail}
            // activeTabData={activeTabData}
          />
        </Col>
      </Row>
    </AvForm>
  )
}

const AddChildForm = ({ form, grid_id, parentPageName, setRefetchForm }) => {
  const [addFormList, setAddFormList] = useState([])
  useEffect(() => {
    if (form?.child_form && form?.child_form?.length >= 0) {
      setAddFormList(form?.child_form)
    }
  }, [JSON.stringify(form?.child_form)])
  return (
    <AddFormInGridData
      source_table={form?.grid_tab_table}
      // addDataDetail={addDataDetail}
      // setAddFormOpen={setAddChildForm}
      addFormList={addFormList}
      setAddFormList={setAddFormList}
      // formName={form?.name}
      childFormName={form?.name}
      pageName={parentPageName}
      isChildFrom={true}
      grid_id={grid_id}
      setRefetchForm={setRefetchForm}
    />
  )
}
export default AddGridData

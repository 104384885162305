export const TransactionTypeModel = {
  component_type: 'buttons',
  component_name: 'show_tabs_on_load',
  name: 'security_contractors_security_contractors',
  source_table: 'security_contractors',
  method_name: 'security_contractors',
  show_count: 1,
  show_data_progress: 1,
  is_grid: 1,
  default_tab_name: 'Security Contractors',
  slug: false,
  permission: 'view_security_contractors_security_contractors',
  allowed: true,
  cms_tab_key: 'nav.security_contractors_security_contractors',
  tab_color: '',
  icon_type: null,
  icon: null,
  component_call_pill_data_connection: null,
  tab_pill_color_theme: '',
  component_call_form: [
    {
      component_type: 'form',
      component_name: 'advanced_grid',
      type: 'GRID',
      permission: 'no_permission',
      allowed: 1,
      name: 'security_contractors_security_contractors',
      source_table: 'security_contractors',
      data_validations: [],
      main_grid: {
        component_type: 'form',
        component_name: 'form_grid',
        permission: 'no_permission',
        allowed: 1,
        name: 'security_contractors_security_contractors',
        cms_header_key: 'heading.security_contractors_security_contractors',
        cms_sub_header_key: 'sub.security_contractors_security_contractors_info',
        cms_note_key: false,
        icon_type: false,
        icon: false,
        inline_edit_button: 0,
        grid_column_setting: null,
        show_all_questions: null,
        column_layout: null,
        group_by: 0,
        group_by_setting: null,
        where_clause: [],
        grid_button_setting: null,
        live_refresh: 0,
        grid_colors: [],
        grid_filters: [],
        grid_advanced_filters: [],
        id: 5434,
        component_call_cols: [
          {
            component_type: 'cols',
            component_name: 'icon_col_button',
            name: 'usersecurity-delete',
            icon: 'pe-7s-trash',
            icon_type: 'Pe7',
            width: null,
            background_color: 'danger',
            component_call_actions: [
              {
                component_type: 'actions',
                url: 'usersecurity-delete',
                component_name: 'connect_delete_item_template',
                payload: 'selected_id',
              },
            ],
            hidden: false,
          },
          {
            component_type: 'cols',
            component_name: 'check_col',
            permission: 'view_select_row',
            display_header: false,
            background_color: null,
            cms_key: false,
            cms_key_tooltip: false,
            width: null,
            sort: false,
            allow_sorting: false,
            sort_priority: null,
            filtering: false,
            default_filter: null,
            display_column: true,
          },
          {
            component_type: 'cols',
            component_name: 'icon_col_button',
            name: 'security_contractorssecuritycontractors-edit',
            icon: 'far fa-edit',
            icon_type: 'font_awesome',
            width: null,
            background_color: 'primary',
            component_call_actions: [
              {
                component_type: 'actions',
                component_name: 'open_tab',
                slug: '',
                url: '/{id}',
                post_data: null,
              },
            ],
            hidden: false,
          },
          {
            component_type: 'cols',
            component_name: 'text_data_col',
            permission: 'view_id',
            display_header: 1,
            background_color: null,
            field_id: 'id',
            field_setting: {
              type: 'int',
              validations: [
                {
                  component_type: 'validation',
                  component_name: 'inline_validation',
                  validation_type: 'required',
                  message: 'Please complete',
                },
              ],
              field: 'input',
              manage_data: null,
              component_call_actions: null,
              col: 6,
              component_call_data_connection: null,
              values: '',
            },
            inline_edit: 0,
            cms_key: 'security_contractors.id',
            cms_key_tooltip: null,
            width: '100',
            sort: 'none',
            allow_sorting: true,
            sort_priority: null,
            filtering: true,
            default_filter: null,
            display_column: true,
          },
          {
            component_type: 'cols',
            component_name: 'text_data_col',
            permission: 'view_name1',
            display_header: 1,
            background_color: null,
            field_id: 'contractor_id',
            field_setting: {
              type: 'int',
              validations: [],
              field: 'dropdown',
              manage_data: null,
              component_call_actions: null,
              col: 6,
              component_call_data_connection: {
                component_type: 'data_connection',
                component_name: 'template_data',
                source_table: 'contractors',
                fields: 'name',
                clause_data: [],
              },
              values: '',
            },
            inline_edit: 0,
            cms_key: 'security_contractors.contractor_id',
            cms_key_tooltip: null,
            width: '100',
            sort: 'none',
            allow_sorting: true,
            sort_priority: null,
            filtering: true,
            default_filter: null,
            display_column: true,
          },
        ],
        component_call_data_connection: {
          component_type: 'data_conection',
          action: 'connect_template',
          template: 'user_security',
          from_page: 'meta.from',
          last_page: 'meta.last_page',
          current_page: 'meta.current_page',
          total: 'meta.total',
          row_color_column: 'row_color',
        },
        component_call_pagination: {
          component_type: 'pagination',
          component_name: 'advanced_pagination',
          redux_name: 'record_per_page',
          page_control: true,
          scroll: false,
          next: true,
          previous: true,
        },
        initial_search_payload: false,
        initial_filter_payload: {
          '-filter-contractor_id': null,
          '-filter-id': null,
        },
        global_filter_payload: ['-global-contractor_id', '-global-id'],
        grid_filter_cols: [],
        grid_expand_table: [],
        component_call_grid_buttons: [
          {
            component_type: 'fields',
            component_name: 'icon_name_button',
            col: null,
            is_layout: null,
            row_index: null,
            block_index: null,
            permission: 'trash_usersecurity',
            name: 'usersecurity_delete',
            cms_key: 'field.usersecurity_delete',
            cms_key_tooltip: 'field.usersecurity_delete_tooltip',
            component_call_data_connection: null,
            disabled: false,
            hidden: false,
            background_color: 'danger',
            component_call_actions: [
              {
                component_type: 'actions',
                url: 'user_security',
                component_name: 'connect_delete_item_template',
                payload: 'id',
              },
            ],
            icon_type: 'Pe7',
            icon: 'pe-7s-trash',
            uuid: '02f96778-45d9-4112-a56b-e13448757574',
          },
          {
            component_type: 'fields',
            component_name: 'icon_name_button',
            col: null,
            is_layout: null,
            row_index: null,
            block_index: null,
            permission: 'no_permission',
            name: 'user_security_clear',
            cms_key: 'field.user_security_clear',
            cms_key_tooltip: 'field.user_security_clear_tooltip',
            component_call_data_connection: null,
            disabled: false,
            hidden: false,
            background_color: 'warning',
            component_call_actions: [
              {
                component_type: 'actions',
                component_name: 'clear_grid',
              },
            ],
            icon_type: 'font_awesome',
            icon: 'fas fa-ban',
            uuid: '6cbc248d-fdf0-4792-83e9-a67856b151a0',
          },
          {
            component_type: 'fields',
            is_layout: null,
            row_index: null,
            block_index: null,
            component_name: 'icon_name_button_select',
            permission: 'no_permission',
            name: 'user_security_export',
            cms_key: 'field.user_security_export',
            cms_tooltip: 'field.user_security_export_tooltip',
            data_connection: null,
            disabled: false,
            hidden: false,
            background_color: 'link',
            drop_down_options: [
              {
                component_type: 'actions',
                component_name: 'drop_down_actions',
                color: 'danger',
                icon_type: 'font_awesome',
                icon: 'fas fa-file-pdf',
                cms_key: 'field.selected_to_pdf_option',
                component_call_actions: [
                  {
                    component_type: 'actions',
                    component_name: 'submit_api',
                    url: 'connect-template-export',
                    payload: {
                      type: 'pdf',
                      data: 'selected_id',
                    },
                  },
                ],
              },
              {
                component_type: 'actions',
                component_name: 'drop_down_actions',
                color: 'danger',
                icon_type: 'font_awesome',
                icon: 'far fa-file-pdf',
                cms_key: 'field.export_all_pdf_option',
                component_call_actions: [
                  {
                    component_type: 'actions',
                    component_name: 'submit_api',
                    url: 'connect-template-export',
                    payload: {
                      type: 'pdf',
                      data: 'filters',
                    },
                  },
                ],
              },
              {
                component_type: 'actions',
                component_name: 'drop_down_actions',
                color: 'success',
                icon_type: 'font_awesome',
                icon: 'fas fa-file-excel',
                cms_key: 'field.selected_to_spreadsheet_option',
                component_call_actions: [
                  {
                    component_type: 'actions',
                    component_name: 'submit_api',
                    url: 'connect-template-export',
                    payload: {
                      type: 'spreadsheet',
                      data: 'selected_id',
                    },
                  },
                ],
              },
              {
                component_type: 'actions',
                component_name: 'drop_down_actions',
                color: 'success',
                icon_type: 'font_awesome',
                icon: 'far fa-file-excel',
                cms_key: 'field.export_all_spreadsheet_option',
                component_call_actions: [
                  {
                    component_type: 'actions',
                    component_name: 'submit_api',
                    url: 'connect-template-export',
                    payload: {
                      type: 'spreadsheet',
                      data: 'filters',
                    },
                  },
                ],
              },
              {
                component_type: 'actions',
                component_name: 'drop_down_actions',
                color: 'primary',
                icon_type: 'font_awesome',
                icon: 'fas fa-file-word',
                cms_key: 'field.selected_to_doc_option',
                component_call_actions: [
                  {
                    component_type: 'actions',
                    component_name: 'submit_api',
                    url: 'connect-template-export',
                    payload: {
                      type: 'doc',
                      data: 'selected_id',
                    },
                  },
                ],
              },
              {
                component_type: 'actions',
                component_name: 'drop_down_actions',
                color: 'primary',
                icon_type: 'font_awesome',
                icon: 'far fa-file-word',
                cms_key: 'field.export_all_doc_option',
                component_call_actions: [
                  {
                    component_type: 'actions',
                    component_name: 'submit_api',
                    url: 'connect-template-export',
                    payload: {
                      type: 'doc',
                      data: 'filters',
                    },
                  },
                ],
              },
            ],
            icon_type: 'font_awesome',
            icon: 'fas fa-file-export',
            uuid: '56960901-6395-4cd0-923e-3946bcf5d553',
          },
          {
            component_type: 'fields',
            component_name: 'icon_name_button',
            col: null,
            is_layout: null,
            row_index: null,
            block_index: null,
            permission: 'no_permission',
            name: 'user_security_refresh',
            cms_key: 'field.user_security_refresh',
            cms_key_tooltip: 'field.user_security_refresh_tooltip',
            component_call_data_connection: null,
            disabled: false,
            hidden: false,
            background_color: 'warning',
            component_call_actions: [
              {
                component_type: 'actions',
                component_name: 'refresh_grid',
              },
            ],
            icon_type: 'font_awesome',
            icon: 'fas fa-sync',
            uuid: 'dc14cb53-26e8-4e22-a8b3-c0a3aab09184',
          },
        ],
        component_call_expand_fields: false,
        component_call_preferences_form: {
          rec_per_page: {
            key: 5434,
            cms_display_name: 'field.rec_per_page',
            value: 10,
          },
          save_search: {
            key: 5434,
            cms_display_name: 'field.save_search',
            value: false,
          },
          fields_order: {
            'usersecurity-delete': {
              key: 34324,
              display: true,
              order: false,
              preference_options: [],
              column_options: null,
            },
            id: {
              key: 34320,
              display: true,
              order: 1,
              preference_options: [],
              column_options: null,
            },
            contractor_id: {
              key: 34321,
              display: true,
              order: 2,
              preference_options: [],
              column_options: null,
            },
          },
        },
      },
      child_tabs: {
        component_type: 'page',
        component_name: 'page_with_tabs',
        name: 'user_security',
        template_name: null,
        tab_near_to_pages: null,
        permission: 'view_user_security',
        allowed: 1,
        style: 'full_page',
        button_style: 'none',
        icon_type: 'Pe7',
        icon: 'fa-solid fa-plus-large',
        cms_header_name: 'heading.user_security',
        cms_sub_header_name: 'sub.user_security_info',
        component_call_buttons: [],
        not_allowed_message: "You do not have permission to see this. Quote 'user_security' to administer ",
        component_call_actions: null,
        button_actions: null,
      },
      restore_grid: false,
      grid_user_preferences: {
        default_rec_per_page: 10,
        save_search: false,
      },
      grid_email_report: [],
      grid_settings: {
        display_add_summary_button: false,
        display_report_type: ['user'],
        summary_fields: [],
        grid_add_forms_style: false,
        show_add_form_on_edit: false,
        expand_all: false,
        select_all_records: true,
        sort_all_columns: false,
        filiter_all_columns: false,
        global_filiter_all_columns: false,
        cms_preferences_header_name: 'heading.header_preferences',
        cms_preferences_sub_header_name: 'sub.sub_header_preferences',
        preferences_icon_type: 'font_awesome',
        preferences_icon: 'fad fa-cogs',
        records_before_cms_value: 'text.security_contractors_security_contractors_rec',
        records_after_cms_value: 'text.security_contractors_security_contractors_rec',
        records_end_cms_value: 'text.security_contractors_security_contractors_rec',
        page_before_cms_value: 'text.security_contractors_security_contractors_pag',
        page_after_cms_value: 'text.security_contractors_security_contractors_pag',
        page_end_cms_value: 'text.security_contractors_security_contractors_pag',
        group_by: false,
      },
    },
  ],
  component_call_actions: null,
  uuid: '343b94f0-3c48-41b1-bf9d-fdfb45d5f222',
  print_enabled: 1,
}

export const BLOCK_DROPDOWN = {
  component_type: 'fields',
  component_name: 'select',
  name: 'select_block',
  is_layout: 1,
  row_index: 1,
  block_index: 1,
  col: 12,
  section_rowspan: null,
  field_setting: {
    import: true,
    export: true,
    is_copy_allowed: null,
    depend_fields: null,
    textarea: false,
  },
  cms_value: 'Select Block',
  permission: null,
  field_id: 'location_id',
  cms_key: 'field.select_block',
  cms_tooltip: null,
  component_call_data_connection: {
    component_type: 'data_connection',
    component_name: 'dependent_data',
    source_field: 'category_id',
    fields: 'id ,name',
    data: 'category.blocks',
  },
  selected_value: null,
  disabled: false,
  hidden: false,
  search: false,
  hide_from_grid: false,
  background_color: false,
  component_call_validation: null,
  component_call_actions: null,
  component_call_fields: null,
  format: {
    size: 12,
    searchable: false,
    is_multi_select: false,
  },
  place_holder: null,
  cms_sub_heading: '',
}
import { useMutation, useQueryClient } from 'react-query'
import { request } from '../../Redux/Sagas/requests/api'
import { store } from '../../Redux/Config/configureStore'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { clearEmailTemplateWidgetDesign } from '../../Redux/Reducers/emailTemplate'

const updateEmailTemplateTags = async (payload) => {
  const response = await request('email-tag-widget-update', 'POST', payload)
  return response.data
}

const useUpdateEmailTemplateTags = () => {
  const queryClient = useQueryClient()

  return useMutation(updateEmailTemplateTags, {
    onSuccess: () => {
      store.dispatch(clearEmailTemplateWidgetDesign())
      queryClient.invalidateQueries('fetchTemplateResources')
      queryClient.invalidateQueries('fetchEmailWidgetDesign')
      
      // fetchEmailWidgetDesign
    },
  })
}

export default useUpdateEmailTemplateTags

import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { Slider } from 'react-burgers'

import AppMobileMenu from '../AppMobileMenu'

import { setEnableMobileMenu, setEnableMobileMenuSmall } from '../../Redux/Reducers/themeOptions'
import { setEnableClosedSidebar } from '../../Redux/Reducers/staticThemeOptions'

import EditCompanyLogo from '../../CMSComponent/EditCMS/HeaderLogo/editCompanyLogo'

class HeaderLogo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
      editCompanyLogo: false,
    }
  }

  toggleEnableClosedSidebar = () => {
    let { enableClosedSidebar, setEnableClosedSidebar } = this.props
    setEnableClosedSidebar(!enableClosedSidebar)
  }

  toggleEditCompanyLogo = () => {
    if (this.props.editPageMode) {
      this.setState({
        editCompanyLogo: !this.state.editCompanyLogo,
      })
    }
  }

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  }

  render() {
    const userDomainLogo = this.props.userDomainLogo
    const domainLogoDefault = this.props.domainLogo?.filter((item) => item.logo_default === true || item.logo_default === 1)
    let logo_default_url
    if (domainLogoDefault?.length > 0) {
      logo_default_url = domainLogoDefault[0].logo
    }
    return (
      <Fragment>
        <div className='app-header__logo'>
          <div onClick={this.toggleEditCompanyLogo} className='logo-src-container'>
            <img height='50px' src='https://test-api.ionicbox.com/storage//logo/1lr.png'></img>
            {/* {userDomainLogo && <img src={`${process.env.REACT_APP_STORAGE}${userDomainLogo}`} height='50px' />}
            {logo_default_url && !userDomainLogo && <img src={`${process.env.REACT_APP_STORAGE}${logo_default_url}`} width='160px' />}
            {!logo_default_url && !userDomainLogo && <div className='logo-src' />} */}
          </div>
          <EditCompanyLogo
            isOpen={this.state.editCompanyLogo && this.props.editPageMode}
            onToggle={this.toggleEditCompanyLogo}
            domainLogo={this.props.domainLogo}
          />

          <div className='header__pane ml-auto'>
            <div onClick={this.toggleEnableClosedSidebar}>
              <Slider
                width={26}
                lineHeight={2}
                lineSpacing={5}
                color='#6c757d'
                active={this.state.active}
                onClick={() => this.setState({ active: !this.state.active })}
              />
            </div>
          </div>
        </div>
        <AppMobileMenu />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  enableClosedSidebar: state.StaticThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  editPageMode: state.CMS.editPageMode,
  domainLogo: state.CMS.coreData.domains,
  userDomainLogo: state.User.user?.domain?.logo,
})

const mapDispatchToProps = (dispatch) => ({
  setEnableClosedSidebar: (enable) => dispatch(setEnableClosedSidebar(enable)),
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: (enable) => dispatch(setEnableMobileMenuSmall(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo)

import useMediaQuery from '../../CustomHook/useMediaQuery'
import { primary_color } from '../../Helper/uiHelper'

const ProfileBanner = ({ heading = '<h2>Personal Details</h2>' }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  let image = ''
  return (
    <div
      className={`locker-container d-flex justify-content-center content-widget position-relative`}
      // style={{ background: '#F5FCFF' }}
      style={{ background: `url('profile_banner_bg.png')`, backgroundSize: 'cover', border: '1px solid #00000009' }}>
      <div
        style={{
          padding: isDesktop ? '3rem 2rem' : '1rem',
          marginBlock: '6rem',
          background: 'rgb(255 255 255 / 60%)',
          width: 'fit-content',
          maxWidth: isDesktop ? '70%' : '',
          minWidth: isDesktop ? '50rem' : '',
          border: '2px solid #00000009',
        }}>
        <div className='d-flex flex-column align-items-center'>
          <div class='mt-3' style={isDesktop ? { width: '45rem' } : {}}>
            <div className='text-center mb-4' style={{ color: primary_color, fontWeight: '700' }} dangerouslySetInnerHTML={{ __html: heading }}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileBanner

import { useEffect, useState } from 'react'
import { primary_color } from '../../../Helper/uiHelper'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AdvancedGrid from '../../Grid/advancedGrid'
import { USER_PAGE_DATA } from '../../../Utils/userPageContent'
import MergeUserTable from '../../../Pages/MergeUser/mergeUserTable'
import { FaUserCircle } from 'react-icons/fa'
import { AvField } from 'availity-reactstrap-validation'

const MergeUser = ({ value }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [mergedUsers, setMergedUsers] = useState([])

  const onDeleteUser = (uuid) => {
    const newUser = [...mergedUsers]
    let index
    newUser?.map((item, i) => {
      if (item?.uuid === uuid) {
        index = i
      }
    })
    newUser?.splice(index, 1)
    setMergedUsers(newUser)
  }

  useEffect(() => {
    if(value){
      console.log('merged user value::::::::',value)
      setMergedUsers(value)
    }else{
      setMergedUsers([])
    }
    
  }, [value])

  return (
    <>
      <div>
        <div>Share Booking</div>
        {mergedUsers && mergedUsers?.length > 0 && (
          <div className='my-5'>
            {mergedUsers?.map((item) => {
              return (
                <div className='d-flex justify-content-between pb-2 mb-2' style={{ borderBottom: '1px solid #EAEAEA' }}>
                  <div className='d-flex align-items-center'>
                    {item?.photo ? (
                      <img src={process.env.REACT_APP_STORAGE + item?.photo} height={'50px'} width={'50px'} style={{ borderRadius: '100%' }}></img>
                    ) : (
                      <FaUserCircle style={{ color: primary_color, fontSize: '1.50rem', height: '50px', width: '50px' }} />
                    )}
                    <div className='ml-4'>{item?.full_name}</div>
                  </div>
                  <div className='d-flex align-items-center'>
                    <i
                      className='fa-solid fa-trash text-danger cursor-pointer'
                      style={{ fontSize: '1.2rem' }}
                      onClick={() => onDeleteUser(item?.uuid)}></i>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        <div
          className='py-2 mt-2 px-5 cursor-pointer mb-2'
          style={{ color: primary_color, background: `${primary_color}44`, width: 'fit-content', borderRadius: '10px' }}
          onClick={() => setIsModalOpen(true)}>
          <i class='fa-solid fa-merge mr-3'></i>
          <span>Merge User</span>
        </div>
      </div>
      <AvField type='hidden' name='merged_user' value={mergedUsers}></AvField>
      <Modal toggle={() => setIsModalOpen(false)} isOpen={isModalOpen} centered={true} style={{ width: '60vw', boxShadow: 'none', maxWidth: '60vw' }}>
        <ModalHeader
          className='h4'
          toggle={() => setIsModalOpen(false)}
          style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem', color: primary_color }}>
          Merge User
        </ModalHeader>
        <ModalBody>
          <MergeUserTable toggle={() => setIsModalOpen(false)} setMergedUsers={setMergedUsers} mergedUsers={mergedUsers} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default MergeUser

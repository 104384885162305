import { useEffect, useState } from "react";
import { primary_color } from "../../Helper/uiHelper";

const CustomCheckbox = ({ id, text, onChange, value, checked, activeTextColor = '#8E8E8E' }) => {

  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const handleChange = (e) => {
    onChange?.(e)
    setIsChecked(!isChecked)
  }

  return (
    <label className="d-flex" style={{ cursor: 'pointer', marginBottom: 0 }}>
      <input id={id} type="checkbox" name="check" style={{ opacity: 0, position: 'absolute' }} onChange={(e) => handleChange(e)} value={value} />
      <div className="position-relative">
        <div className="border" style={{ height: 21, width: 21, borderRadius: '5px', backgroundColor: isChecked ? primary_color : '#FFF' }}></div>
        {isChecked ? <div className="position-absolute text-white" style={{ top: 0, left: 4 }}><i className="fa-solid fa-check"></i></div> : null}
      </div>
      <span className="label-text ml-3" style={{ fontWeight: 600, fontSize: 16, color: activeTextColor }}>{text}</span>
    </label>
  )
}

export default CustomCheckbox;
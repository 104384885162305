import { useEffect, useState } from 'react'
import { primary_color } from '../../../../Helper/uiHelper'
import RCTabs from '../../../Tabs/rcTabs'
import { request } from '../../../../Redux/Sagas/requests/api'
import { useSelector } from 'react-redux'
import LockerMove from './lockerMove'

const MoveBooking = () => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const payment_id = currentRow?.[0]?.id

  const [activeTab, setActiveTab] = useState(0)
  const [unitData, setUnitData] = useState([])
  const [tabsData, setTabsData] = useState([])


  const fetchBookingData = () => {
    request('get-booking-by-payment-id', 'POST', { payment_id: payment_id && parseInt(payment_id) }).then((res) => {
      if (res?.data && res?.data?.units) {
        let items = JSON.parse(JSON.stringify(res?.data?.units))
        setUnitData(items)
        const tabs = getTabs(items, res?.data?.domain_id)
        setTabsData(tabs)
      }
     

    })
  }
   

  useEffect(() => {
    fetchBookingData()
  }, [])

  const getTabs = (tabs,domain_id) => {
    return tabs?.map((item, index) => {
      
      return {
        title: `Pupil ${index + 1}`,
        getContent: () => {
          return <LockerMove domainId={domain_id} item={item} unitData={tabs} fetchBookingData={fetchBookingData}></LockerMove>
        },
      }
    })
  }

  return (
    <div className='px-5 py-2'>
      <div className='body1 my-3 font-weight-bold' style={{ color: primary_color }}>
        Move Booking
      </div>

      <RCTabs
        items={tabsData}
        selectedTabKey={activeTab || 0}
        activeTab={activeTab}
        onChange={(e) => {
          setActiveTab(parseInt(e))
        }}
      />
    </div>
  )
}

export default MoveBooking

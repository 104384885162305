import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Switch from 'react-switch'
import { primary_color } from '../../../../Helper/uiHelper'
import useUpdateEmailTemplateWidget from '../../../../Queries/EmailTemplate/useUpdateEmailTemplateWidget'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { useDispatch } from 'react-redux'

const FieldTable = ({ data: widget_data, closeModal }) => {
  const [data, setData] = useState([])

  const [editableCell, setEditableCell] = useState({ row: null, col: null })

  const updateWidget = useUpdateEmailTemplateWidget()
  const dispatch = useDispatch()

  useEffect(() => {
    if (widget_data && widget_data?.common_setting) {
      const fields = JSON.parse(widget_data?.common_setting)?.filter((item) => item?.field_id)
      setData(fields)
    }
  }, [JSON.stringify(widget_data)])

  const handleDoubleClick = (rowIndex, colIndex) => {
    setEditableCell({ row: rowIndex, col: colIndex })
  }

  const handleChange = (e, row_field_id) => {
    const newData = data?.map((item) => {
      if (item?.field_id === row_field_id) {
        return {
          ...item,
          heading: e.target.value,
        }
      } else {
        return item
      }
    })
    setData(newData)
  }

  const handleToggle = (row_field_id) => {
    const newData = data?.map((item) => {
      if (item?.field_id === row_field_id) {
        return {
          ...item,
          View: !item?.View,
        }
      } else {
        return item
      }
    })
    setData(newData)
  }

  const onSaveWidget = () => {
    let payload = {
      ...widget_data,
      common_setting: data,
      type: 'widgets',
    }
    updateWidget.mutate(payload, {
      onSuccess: (data) => {
        //add failed scenario also
        dispatch(setNotification({ type: 'success', message: 'Email Sent Successfully!' }))
        closeModal()
      },
      onError: (error) => {
        console.log('error:::::::',error)
        dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        closeModal()
      },
    })
  }

  return (
    <div className='container mt-3'>
      <table className='table table-bordered table-template-widget'>
        <thead>
          <tr>
            <th style={{ color: primary_color }}>Title</th>
            <th style={{ color: primary_color, background: '#EAEAEA' }}>Default Value</th>
            <th style={{ color: primary_color }}>View</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={row.id}>
              <td
                onDoubleClick={() => handleDoubleClick(rowIndex, 0)}
                style={{
                  backgroundColor: editableCell.row === rowIndex && editableCell.col === 0 ? '#049FDB1A' : 'transparent',
                }}>
                {editableCell.row === rowIndex && editableCell.col === 0 ? (
                  <input
                    type='text'
                    value={row.heading}
                    onChange={(e) => handleChange(e, row?.field_id)}
                    onBlur={() => setEditableCell({ row: null, col: null })}
                    autoFocus
                    style={{
                      backgroundColor: '#049FDB1A',
                      border: 'none',
                      outline: 'none',
                      width: '100%',
                    }}
                  />
                ) : (
                  row.heading
                )}
              </td>
              <td style={{ background: '#EAEAEA' }}>{row.Value}</td>
              <td>
                <Switch
                  onChange={() => handleToggle(row?.field_id)}
                  checked={row.View}
                  onColor='#049FDB'
                  offColor='#cccccc'
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className='d-flex justify-content-between' style={{ width: '60%' }}>
        <button
          className='btn rounded-pill mt-2 text-danger border-danger'
          type='button'
          onClick={() => {
            closeModal()
          }}
          style={{ fontWeight: 700, width: '48%' }}>
          Cancel
        </button>
        <button
          className='btn text-white rounded-pill mt-2'
          type='button'
          onClick={() => {
            onSaveWidget()
          }}
          style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
          Save
        </button>
      </div>
    </div>
  )
}

export default FieldTable

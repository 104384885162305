import FieldTable from './fieldTable'
import FieldText from './fieldText'
import FooterEdit from './footer'
import HeaderWidgetEdit from './header'

const EditWidget = ({ widget, closeModal }) => {

  switch (widget?.component_name) {
    case 'ticket_details':
      return <FieldTable data={widget} closeModal={closeModal} />
    case 'header':
      return <HeaderWidgetEdit data={widget} closeModal={closeModal} />
    case 'footer':
       console.log('widget data:::::::', widget)
      return <FooterEdit data={widget} widget_data={widget} closeModal={closeModal}></FooterEdit>
    case 'refund_note':
      return <FieldText data={widget} closeModal={closeModal}></FieldText>
    default:
      return <FieldTable data={widget} closeModal={closeModal} />
  }
}

export default EditWidget

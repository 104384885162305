import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Button, Collapse, ListGroup, ListGroupItem, UncontrolledCollapse } from 'reactstrap'

import CMSRichText from '../richText'
import '../cmsWidget.scss'
import { useEffect, useState } from 'react'
import { cms } from '../../../helper'
import { updatePublicPageCMS } from '../../../Helper/publicPageHelper'

const CMSFormEdit = ({ data, toggleModal, widget_uuid }) => {
  const [state, updateState] = useState({})
  const [formData, setFormData] = useState({})
  const [isCollapseOpen, setIsCollapseOpen] = useState('edit_text')

  useEffect(() => {
    let obj = {}
    obj['form'] = {}

    data?.form?.[0]?.component_call_fields?.map((item) => {
      obj['form'][item.cms_key] = cms(item?.cms_key)
      if (item?.cms_sub_heading) {
        obj['form'][item.cms_sub_heading] = cms(item?.cms_sub_heading)
      }
    })
    setFormData({
      ...obj,
      // search_limit_error: data?.search_limit_error,
    })
  }, [data])

    const onSave = () => {
      let payload = {
        uuid: widget_uuid,
        data: {
          form: formData?.form,
        },
      }
      updatePublicPageCMS(payload)
    }

  return (
    <div>
      <div className='d-flex justify-conent-between'>
        <div className='font-weight-bold'>Edit Content</div>
      </div>
      <hr></hr>
      <AvForm>
        <ListGroup>
          <ListGroupItem id='edit_cms_form_locker' className='editCMSMainTitle font-weight-bold'>
            Edit Text
          </ListGroupItem>
          <Collapse isOpen={true}>
            {data?.form?.[0]?.component_call_fields?.map((item) => {
              return (
                <>
                  <div className='p-1 mt-2 mb-2'>
                    <div>
                      <AvField
                        name={item?.field_id?.replaceAll('.', '_')}
                        label={cms(item?.cms_key)}
                        value={formData?.['form']?.[item?.cms_key]}
                        onChange={(e) => {
                          setFormData((values) => {
                            let obj = {
                              ...values,
                            }
                            obj['form'] = {
                              ...obj['form'],
                            }

                            obj['form'][item.cms_key] = e.target.value
                            return obj
                          })
                        }}></AvField>
                      {/* <CMSRichText
                  data={{ field_id: 'hero_search_heading' }}
                  id='hero_search_heading'
                  title={'Heading'}
                  value={formData?.heading}
                  onChange={(v) => {
                    console.log('form data::::::::', v)
                    setFormData({
                      ...formData,
                      heading: v,
                    })
                  }}
                /> */}
                    </div>
                  </div>
                  {item?.cms_sub_heading && (
                    <div className='p-1 mt-2 mb-2'>
                      <div>
                        <AvField
                          name={item?.field_id?.replaceAll('.', '_') + ' sub_heading'}
                          label={cms(item?.cms_key) + 'sub heading'}
                          value={formData?.['form']?.[item?.cms_sub_heading]}
                          onChange={(e) => {
                            setFormData((values) => {
                              let obj = {
                                ...values,
                              }
                              obj['form'] = {
                                ...obj['form'],
                              }

                              obj['form'][item.cms_sub_heading] = e.target.value
                              return obj
                            })
                          }}></AvField>
                      </div>
                    </div>
                  )}
                </>
              )
            })}
          </Collapse>
        </ListGroup>
      </AvForm>
      <div className='d-flex justify-content-end mt-4'>
        <Button
          outline
          color='danger'
          className='mr-2'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal()
          }}>
          Discard
        </Button>
        <Button color='info' onClick={()=>onSave()}>Save</Button>
      </div>
    </div>
  )
}

export default CMSFormEdit

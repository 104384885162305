import { Col, Row } from 'reactstrap'
import { RenderWidget } from './publicSection'
import useMediaQuery from '../../../CustomHook/useMediaQuery'

const PublicChildButton = ({
  data,
  searchData,
  setSearchData,
  filterData,
  setFilterData,
  setActivePage,
  activePage,
  parentData,
  setParentData,
  pageData,
  userAddress,
  setUserAddress,
  couponCode,
  setCouponCode,
  onGoBackToHomePage,
  clearSearchData,
  setClearSearchData,
  userAddressValidation,
  setUserAddressValidation,
  searchResultsAvailable,
  setSearchResultsAvailable,
  unitData,
  bookingDetails,
  setBookingDetails,
  setUnitData,
}) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  return (
    <>
      {data?.child_page_buttons?.length > 0 && (
        <Row style={isDesktop ? { paddingLeft: data?.padding ? data?.padding : '1rem', paddingRight: data?.padding ? data?.padding : '1rem' } : {}}>
        {/* <Row> */}
          {data?.child_page_buttons?.map((button, i) => {
            return (
              <Col md={button?.col ? parseInt(button?.col) : 12}>
                <PublicChildButton
                  key={i}
                  data={button}
                  searchData={searchData}
                  setSearchData={setSearchData}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  setActivePage={setActivePage}
                  activePage={activePage}
                  parentData={parentData}
                  setParentData={setParentData}
                  pageData={pageData}
                  userAddress={userAddress}
                  setUserAddress={setUserAddress}
                  couponCode={couponCode}
                  setCouponCode={setCouponCode}
                  onGoBackToHomePage={onGoBackToHomePage}
                  clearSearchData={clearSearchData}
                  setClearSearchData={setClearSearchData}
                  userAddressValidation={userAddressValidation}
                  setUserAddressValidation={setUserAddressValidation}
                  searchResultsAvailable={searchResultsAvailable}
                  unitData={unitData}
                  setUnitData={setUnitData}
                  setSearchResultsAvailable={setSearchResultsAvailable}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                />
              </Col>
            )
          })}
        </Row>
      )}

      {data?.widgets?.map((widget, index) => {
        return (
          <RenderWidget
            key={index}
            isLastWidget={index === data?.widgets.length - 1}
            widget={widget}
            searchData={searchData}
            setSearchData={setSearchData}
            filterData={filterData}
            setFilterData={setFilterData}
            setActivePage={setActivePage}
            activePage={activePage}
            parentData={parentData}
            setParentData={setParentData}
            pageData={pageData}
            userAddress={userAddress}
            setUserAddress={setUserAddress}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            onGoBackToHomePage={onGoBackToHomePage}
            clearSearchData={clearSearchData}
            setClearSearchData={setClearSearchData}
            userAddressValidation={userAddressValidation}
            setUserAddressValidation={setUserAddressValidation}
            searchResultsAvailable={searchResultsAvailable}
            unitData={unitData}
            setUnitData={setUnitData}
            setSearchResultsAvailable={setSearchResultsAvailable}
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
          />
        )
      })}
    </>
  )
}

export default PublicChildButton

import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useState, useRef } from 'react'
import Loader from 'react-loaders'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'

const dummyData = {
  heading: 'Still have Question?',
  sub_heading: 'Connect with us',
}

const AboutUs = ({ data = dummyData }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const dispatch = useDispatch()
  const formRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (e, values) => {
    const payload = { ...values }
    setIsLoading(true)
    request('contact-details', 'POST', payload)
      .then(() => {
        setIsLoading(false)
        dispatch(setNotification({ type: 'success', message: 'Message sent successfully!' }))
        formRef.current.reset() // Reset the form fields
      })
      .catch(() => {
        setIsLoading(false)
        dispatch(setNotification({ type: 'error', message: 'Message sending failed!' }))
      })
  }

  return (
    <div
      className='locker-container d-flex flex-column justify-content-center align-items-center'
      style={{
        background: `url(${data?.image})`,
        backgroundSize: 'cover',
        height: '30rem',
      }}>
      <div className='text-center mb-2' style={{ fontSize: '30px', color: primary_color, fontWeight: '700' }}>
        {data?.heading}
      </div>
      <div className='text-center mb-4' style={{ fontSize: '18px' }}>
        {data?.sub_heading}
      </div>
      <div className='contact-us' style={{ width: isDesktop ? '30rem' : '90%' }}>
        <AvForm ref={formRef} onValidSubmit={handleSubmit}>
          <AvField name='name' required type='text' placeholder='name' />
          <AvField name='email' required type='text' placeholder='email' />
          <AvField name='message' type='textarea' placeholder='message' required />
          <Button
            className='w-100 poppin d-flex align-items-center justify-content-center mt-3'
            style={{
              color: 'white',
              border: `1px solid ${primary_color}`,
              height: isDesktop ? 45 : 45,
              backgroundColor: primary_color,
              borderRadius: 30,
            }}>
            <span style={{ fontSize: isDesktop ? '16px' : '15px', fontWeight: 600 }}>{data?.button_text || 'Send'}</span>
            {isLoading && <Loader type='line-scale' color='#FFF' style={{ transform: 'scale(0.3)' }} />}
          </Button>
        </AvForm>
      </div>
    </div>
  )
}

export default AboutUs

import { useEffect, useState, useRef } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import { connect, useSelector } from 'react-redux'
import RedactorX from '../../Vendor/redactorx/redactorx.js'
import '../../Vendor/redactorx/redactorx.css'


const checkIsFieldVisible = (formFieldValue, field_id) => {
  if (field_id === 'termination_reason' && !formFieldValue?.termination_date) {
    return false
  }
  return true
}

const select = (state) => ({
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
})

const CMSRichText = ({ data, cmsEditMode, id, value, formName, currentRow, onChange = () => {}, formFieldValue, title }) => {
  const [enteredValue, setEnteredValue] = useState(value)
  const [changedFieldRendered, setChangedFieldRendered] = useState(false)
  const submittedFieldInRedux = useSelector((state) => state.CMS?.submittedChangedField)
  const editorRef = useRef(null)

  useEffect(() => {
    const app = RedactorX(`#${id}`, {
      editor: { minHeight: data?.section_rowspan?.rowspan === 3 ? '237px' : '150px' },
      subscribe: {
        'editor.change': (event) => {
          setEnteredValue(event.params.html)
          onChange(event.params.html)
        },
      },
    })

    editorRef.current = app

    return () => {
      app.destroy() // Cleanup to prevent memory leaks
    }
  }, [])

  useEffect(() => {
    if (value !== enteredValue) {
      setEnteredValue(value)
      if (editorRef.current) {
        editorRef.current.editor.setContent({ html: value || '' })
      }
    }
  }, [value])

  



  const isFieldInSync = false
  return (
    <div className={`${data?.background_color || ''} ${isFieldInSync ? 'blue-border' : ''}`}>
      <label>{title}</label>
      <textarea id={id} style={{ width: '100%' }} className='blue-border' defaultValue={enteredValue} />
      <AvField
        type='hidden'
        name={data.field_id || 'rich-text'}
        value={enteredValue}
        // validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}
      />
    </div>
  )
}

export default connect(select)(CMSRichText)

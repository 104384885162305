import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import ShowBookingData from './showBookingData'
import Lockers from './lockers'
import { primary_color } from '../../../../Helper/uiHelper'
import { request } from '../../../../Redux/Sagas/requests/api'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import Select from '../../../../CMSComponent/Forms/Fields/select'
import { BLOCK_DROPDOWN } from '../../../../Utils/paymentUtils'
import RoundedSelect from '../../../Form/Fields/roundedSelect'
import { fetchUpdateApi } from '../../../../Redux/Sagas/rootSaga'

const LockerMove = ({ item, unitData, fetchBookingData, domainId }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  let parent_row_id = currentRow?.[currentRow?.length - 1]?.uuid

  const [oldData, setOldData] = useState(item)
  const [newData, setNewData] = useState(item)
  const [confirmationMail, setConfirmationMail] = useState(false)
  const [isMoving, setIsMoving] = useState(false)
  const [formData, setFormData] = useState([item])
  const [isWarning, setIsWarning] = useState(false)

  const dispatch = useDispatch()

  const onCancelBooking = () => {
    setNewData(oldData)
  }

  useEffect(() => {
    setOldData(item)
  }, [JSON.stringify(item)])
  useEffect(() => {
    if (formData?.[0]?.location_id && formData?.[0]?.location_id !== oldData?.location_id) {
      setNewData({
        ...newData,
        location_id: formData?.[0]?.location_id,
        location_id_label: formData?.[0]?.location_id_label,
      })
    }
  }, [formData?.[0]?.location_id])

  const onValidateBooking = () => {
    let newCost, oldCost
    if (newData?.total_cost && oldData?.total_cost) {
      newCost = parseInt(newData?.total_cost?.replace('£', ''))
      oldCost = parseInt(oldData?.total_cost?.replace('£', ''))
    }
    if (newCost > oldCost) {
      return false
    } else {
      return true
    }
    return true
  }
  const onMoveBooking = () => {
    setIsMoving(true)
    request('move-booking', 'POST', {
      old_booking: oldData,
      new_booking: newData,
      send_confirmation_mail: confirmationMail,
      parent_row_id,
    }).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Booking moved successfully!' }))
        fetchBookingData()
      } else {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong!' }))
      }
      dispatch(fetchUpdateApi())
      setIsMoving(false)
    })
  }

  const onChangeQuestion = (i, val) => {
    let oldQuestions = newData?.questions
    let newQuestions = oldQuestions?.map((que, index) => {
      if (index === i) {
        return {
          ...que,
          answer: val,
        }
      } else {
        return que
      }
    })
    setNewData((old) => {
      return {
        ...old,
        questions: newQuestions,
      }
    })
  }
  return (
    <div className='my-4'>
      <AvForm>
        <Row>
          {newData?.questions?.map((question, i) => {
            return (
              <Col md={6}>
                <AvField
                  type='text'
                  name={question?.uuid}
                  label={question?.question}
                  onChange={(e) => onChangeQuestion(i, e.target.value)}
                  value={question?.answer}></AvField>
              </Col>
            )
          })}
        </Row>
      </AvForm>
      <div className='mb-5'>
        <ShowBookingData item={oldData}></ShowBookingData>
      </div>
      <hr></hr>
      <AvForm>
        <RoundedSelect
          data={BLOCK_DROPDOWN}
          setFormData={setFormData}
          formFieldValue={formData?.[0]}
          formIndex={0}
          parentData={{ id: domainId }}
          depend_field_source_table='categories'></RoundedSelect>
        {/* <Select id='block' data={BLOCK_DROPDOWN} depend_value={item?.category_id} depend_field_source_table='categories'></Select> */}
      </AvForm>

      <Lockers
        dependent_value={formData?.[0]?.location_id}
        unitData={unitData}
        currentLockerData={oldData}
        newLockerData={newData}
        setNewData={setNewData}></Lockers>
      <div className='ml-2'>
        <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          <input
            checked={confirmationMail}
            type='checkbox'
            id='emailCheckBox'
            className='subtitle1'
            onChange={(e) => {
              setConfirmationMail(e.target.checked)
            }}
          />
          <label for='emailCheckBox' className='font-weight-bold mt-2 subtitle1' style={{ opacity: 0.7 }}>
            Send Confirmation Mail
          </label>
        </div>
      </div>
      <div className='my-5 d-flex justify-content-end'>
        <button
          className='btn rounded-pill mt-2 text-danger border-danger mr-3'
          type='button'
          onClick={() => {
            onCancelBooking()
          }}
          style={{ fontWeight: 700, width: '8rem' }}>
          Cancel
        </button>
        <button
          className='btn text-white rounded-pill mt-2'
          type='button'
          onClick={(e) => {
            if (onValidateBooking()) {
              onMoveBooking()
            } else {
              setIsWarning(true)
            }
          }}
          style={{ background: primary_color, fontWeight: 700, width: '8rem' }}>
          {isMoving ? 'Saving...' : 'Save'}
        </button>
      </div>

      <Modal
        isOpen={isWarning}
        centered={true}
        toggle={() => {
          setIsWarning(false)
        }}
        style={{ width: '50rem', boxShadow: 'none', maxWidth: '50rem' }}>
        <ModalHeader style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem' }}>
          <div className='p font-weight-bold' style={{ color: primary_color }}>
            This booking move has resulted in a charge. If you like to continue to move this booking please go to the admin interface for move
            booking.
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='d-flex justify-content-between'>
            <button
              className='btn rounded-pill mt-2 text-danger border-danger mr-3'
              type='button'
              onClick={() => {
                setIsWarning(false)
              }}
              style={{ fontWeight: 700, width: '48%' }}>
              Cancel
            </button>
            <button
              className='btn text-white rounded-pill mt-2 mr-3'
              type='button'
              onClick={() => {
                setIsWarning(false)
              }}
              style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
              Edit & Move
            </button>

            <button
              className='btn text-white rounded-pill mt-2 '
              type='button'
              onClick={() => {
                setIsWarning(false)
                onMoveBooking()
              }}
              style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
              Move without change
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default LockerMove

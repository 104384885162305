import { store } from '../Redux/Config/configureStore'
import { refetchPublicPage, updatePublicPage } from '../Redux/Reducers/CMS'
import { clearNav } from '../Redux/Reducers/navigations'
import { setNotification } from '../Redux/Reducers/notificationHandling'
import { clearPublicPageDataFromRedux, storePublicPageData } from '../Redux/Reducers/publicPageData'
import { request } from '../Redux/Sagas/requests/api'

export const fetchPublicPagesNav = () => {
  const publicPageList = store.getState().CMS.publicPage

  if (publicPageList?.length > 0) return

  request('public-navigations').then((res) => {
    if (res.status === 200) {
      store.dispatch(updatePublicPage(res?.data))
    }
  })
}

export const getPublicPageData = async (page_uuid) => {
  try {
    const publicPageData = store.getState().publicPageData
    const refetchpage = store.getState().CMS.refetchPublicPage

    if (publicPageData[page_uuid] && !refetchpage) {
      return publicPageData[page_uuid]
    } else {
      const res = await request(`get-page/${page_uuid}`)

      if (res && res.status === 200) {
        let newData = publicPageData ? { ...publicPageData } : {}
        newData[page_uuid] = res?.data
        store.dispatch(storePublicPageData(newData))
        store.dispatch(refetchPublicPage(false))
        return res.data
      } else {
        console.error('Failed to fetch public page data:', res.statusText)
        return null
      }
    }
  } catch (error) {
    console.error('Error fetching public page data:', error)
    return null
  }
}

export const removePublicPageDataByUUID = (uuid) => {
  const publicPageData = store.getState().publicPageData
  let newPageData = {
    ...publicPageData,
  }
  delete newPageData[uuid]
  store.dispatch(storePublicPageData(newPageData))
}

export const _validateFormData = (data) => {
  for (let i = 0; i < data.length; i++) {
    console.log('data[i]:==> ', data[i], data[i].hasOwnProperty('questions'))

    if (!data[i].category_id) {
      // store.dispatch(setNotification({ type: 'error', message: 'Please select Student Year Group in Student ' + (i + 1) }))
      return false
    }
    if (!data[i].item_date_time_id) {
      // store.dispatch(setNotification({ type: 'error', message: 'Please select Year Session in Student ' + (i + 1) }))
      return false
    }
    if (!data[i].location_id) {
      // store.dispatch(setNotification({ type: 'error', message: 'Please Select Block in Student ' + (i + 1) }))
      return false
    }
    if (!data[i].item_id) {
      store.dispatch(setNotification({ type: 'error', message: 'Please Select Locker in Student ' + (i + 1) }))
      return false
    }
    if (data[i].hasOwnProperty('questions')) {
      for (let key in data[i].questions) {
        if (!data[i].questions[key]) return false
      }
    }
  }
  return true
}

export const checkFilterApplied = (searchData, filterData) => {
  if (Object.keys(searchData)?.length === 0 && Object.keys(filterData)?.length === 0) {
    return false
  }

  let isApplied = false
  Object.keys(searchData)?.map((key) => {
    if (searchData[key]) {
      isApplied = true
    }
  })
  Object.keys(filterData)?.map((key) => {
    if (filterData[key]?.length > 0) {
      isApplied = true
    }
  })
  return isApplied
}

export const checkSideBarVisible = (parentData, searchData, filterData, searchResultsAvailable) => {
  if (parentData) return true
  else if (!searchResultsAvailable) {
    return false
  } else if (checkFilterApplied(searchData, filterData)) {
    return true
  } else {
    return false
  }
}

export const updatePublicPageCMS = async (payload) => {
  const navigation = store.getState().CMS.publicPage

  request('public/save-cms', 'POST', payload).then((res) => {
    store.dispatch(clearNav())
    store.dispatch(refetchPublicPage(true))
    store.dispatch(clearPublicPageDataFromRedux())
    // const currentPage = navigation.find((item) => window.location.pathname === '/' + item?.route)
    // removePublicPageDataByUUID(currentPage?.page_uuid)
    // setTimeout(() => {
    //   store.dispatch(refetchPublicPage(true))
    // }, 500)
  })
}

import moment from 'moment'
import { primary_color } from '../../../Helper/uiHelper'

import { getObjectDifference } from '../../../helper'
import { checkIsDataUpdated, checkIsFieldHide, getValueByFieldTypeNid } from '../../../Pages/Logs/logHelper'

const fields = [
  {
    field_id: 'name',
    label: 'Name',
    type: 'input',
  },
  {
    field_id: 'description',
    label: 'Description',
    type: 'input',
  },
  {
    field_id: 'is_guest',
    label: 'Guest Role',
    type: 'toggle_switch',
  },
  {
    field_id: 'is_default',
    label: 'Default Role',
    type: 'toggle_switch',
  },
]

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference, parentPageName, fields }) => {
  console.log('updated log:::::::::', original_data, updated_data)
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          Details
        </div>
        <div className='p-4'>
          {fields?.map((item) => {
            if (checkIsFieldHide(item?.field_id)) return
            return (
              <div className='d-flex mb-2 align-items-center' style={{ width: '100%' }}>
                <div className='font-weight-bold mb-2' style={{ width: '40%' }}>
                  {item?.label}:
                </div>
                <div style={{ width: '30%' }}>{getValueByFieldTypeNid(updated_data, item?.type, item?.field_id)}</div>
                {checkIsDataUpdated(original_data, updated_data, item?.field_id) &&
                  getValueByFieldTypeNid(original_data, item?.type, item?.field_id) && (
                    <div
                      style={{ width: '30%' }}
                      className={`${checkIsDataUpdated(original_data, updated_data, item?.field_id) ? 'p-2 bg-danger text-light' : ''}`}>
                      {getValueByFieldTypeNid(original_data, item?.type, item?.field_id)}
                    </div>
                  )}
              </div>
            )
          })}

        </div>
      </div>
    </div>
  )
}

const getTextToRender = (newData, oldData, updatedBy) => {
  const difference = getObjectDifference(newData, oldData)
  console.log('asfdsdfsdfsdf:::::::', newData, oldData, difference)
  if (difference['is_guest']) {
    if (difference['name']) {
      if (newData?.is_guest) {
        return (
          <span>
            Role is assigned as <span className='font-weight-bold'>Guest</span> role and changed name from{' '}
            <span className='font-weight-bold'>{difference['name']?.obj2}</span> to{' '}
            <span className='font-weight-bold'>{difference['name']?.obj1}</span> by <span className='font-weight-bold'>{updatedBy}</span>
          </span>
        )
      }
    } else {
      return (
        <span>
          Role is assigned as <span className='font-weight-bold'>Guest</span> role by <span className='font-weight-bold'>{updatedBy}</span>
        </span>
      )
    }
  } else if (difference['is_default']) {
    if (difference['name']) {
      if (newData?.is_guest) {
        return (
          <span>
            Role is assigned as <span className='font-weight-bold'>Default</span> role and changed name from{' '}
            <span className='font-weight-bold'>{difference['name']?.obj2}</span> to{' '}
            <span className='font-weight-bold'>{difference['name']?.obj1}</span> by <span className='font-weight-bold'>{updatedBy}</span>
          </span>
        )
      }
    } else {
      return (
        <span>
          Role is assigned as <span className='font-weight-bold'>Default</span> role by <span className='font-weight-bold'>{updatedBy}</span>
        </span>
      )
    }
  } else if (difference['name']) {
    return (
      <span>
        Role name is changed from <span className='font-weight-bold'>{difference['name']?.obj2}</span> to{' '}
        <span className='font-weight-bold'>{difference['name']?.obj1}</span> role by <span className='font-weight-bold'>{updatedBy}</span>
      </span>
    )
  } else {
    return <></>
  }
}

const UpdatedRole = ({ data, onExpand, openRoleDetails, expandedId }) => {
  const updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  const original_data = data?.original_data && JSON.parse(data?.original_data)

  // const text = getTextToRender(data1, data2, data?.updated_by)
  if (expandedId) {
    return <ShowDifference data={data} updated_data={updated_data} original_data={original_data} fields={fields} onExpand={onExpand}></ShowDifference>
  }
  return (
    <div className='d-flex align-items-center'>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            // openRoleDetails(0)
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {' has updated details.'}
      </div>
      {data?.updated_at && <div>{moment(data?.updated_at).format('MMM D  YYYY, hh:mm')}</div>}
    </div>
  )
}

export default UpdatedRole

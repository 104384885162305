import OpenModal from '../CMSComponent/Forms/Fields/openModal'
import PublicFooter from './Footer/footer'
import PublicHeader from './Header/header'
import { useLocation } from 'react-router-dom'

const PublicPageWrapper = ({ children }) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isShowOnlyContent = searchParams.get('isShowOnlyContent')
  return (
    <>
      {!isShowOnlyContent && <PublicHeader />}
      {children}
      {!isShowOnlyContent && <PublicFooter />}
      <OpenModal />
    </>
  )
}

export default PublicPageWrapper

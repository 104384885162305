import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { Button, Collapse, ListGroup, ListGroupItem } from 'reactstrap'

import CMSRichText from '../richText'
import { request } from '../../../Redux/Sagas/requests/api'
import { updatePublicPageCMS } from '../../../Helper/publicPageHelper'

const CMSResendEmail = ({ data, widget_uuid, toggleModal }) => {
  const [state, updateState] = useState({})
  const [formData, setFormData] = useState({})
  const [isCollapseOpen, setIsCollapseOpen] = useState('edit_text')

  useEffect(() => {
    let obj = {
      heading: data?.heading,

      sub_heading: data?.sub_heading,
    }

    setFormData({
      ...obj,
    })
  }, [data])

  const onSave = () => {
    let payload = {
      uuid: widget_uuid,
      data: formData,
    }
    updatePublicPageCMS(payload)
  }

  return (
    <div>
      <div className='d-flex justify-conent-between content-widget'>
        <div className='font-weight-bold'>Edit Content</div>
      </div>
      <hr></hr>
      <AvForm>
        <ListGroup>
          <ListGroupItem id='edit_text' className='editCMSMainTitle font-weight-bold'>
            Edit Text
          </ListGroupItem>
          <Collapse isOpen={isCollapseOpen === 'edit_text'}>
            <div className='p-1 mt-2 mb-2'>
              <div>
                <CMSRichText
                  data={{ field_id: 'content_heading' }}
                  id='content_heading'
                  title={'Heading'}
                  value={formData?.heading}
                  onChange={(v) => {
                    setFormData((val) => ({
                      ...val,
                      heading: v,
                    }))
                  }}
                />
              </div>
            </div>
            <div className='p-1 mt-2 mb-2'>
              <div>
                <CMSRichText
                  data={{ field_id: 'content_sub_heading' }}
                  id='content_sub_heading'
                  title={'Sub Heading'}
                  value={formData?.sub_heading}
                  onChange={(v) => {
                    setFormData((val) => ({
                      ...val,
                      sub_heading: v,
                    }))
                  }}
                />
              </div>
            </div>
          </Collapse>
        </ListGroup>
      </AvForm>
      <div className='d-flex justify-content-end mt-4'>
        <Button
          outline
          color='danger'
          className='mr-2'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal()
          }}>
          Discard
        </Button>
        <Button color='info' onClick={() => onSave()}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default CMSResendEmail

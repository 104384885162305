import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { request } from '../../Redux/Sagas/requests/api'
import { MdCheck } from 'react-icons/md'
import { primary_color } from '../../Helper/uiHelper'
import CMSWidgetModal from '../CMSWidget/cmsWidgetModal'
import CMSHeroSearch from '../CMSWidget/HeroSearch/cmsHeroSearch'
import CMSCouponCode from '../CMSWidget/SidebarWidget/cmsCouponCode'
import { cms } from '../../helper'
const CoupenCode = ({ data, setCouponCode, couponCode, isLastWidget, widget_uuid }) => {
  const [couponCodeValue, setCouponCodeValue] = useState('')
  const editMode = useSelector((state) => state.CMS.editMode)
  const dispatch = useDispatch()
  useEffect(() => {
    setCouponCodeValue(couponCode)
  }, [couponCode])

  const sectionRef = useRef(null)

  const [modalPosition, setModalPosition] = useState()

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        //  top: rect.top + window.scrollY - 50,
        top: rect.height + 100,

        //  left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '60vw',
      })
    }
  }

  const onVerifyCoupon = () => {
    request('check-coupon', 'POST', { coupon_code: couponCodeValue }).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Coupon code applied!' }))
        setCouponCode(couponCodeValue)
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.data?.message?.coupon_code?.[0] ? res?.data?.message?.coupon_code?.[0] : res?.data?.message,
          })
        )
        setCouponCodeValue('')
      }
    })
  }

  const onChangeValue = (value) => {
    setCouponCodeValue(value)
  }

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  return (
    <div
      className={`mt-2 coupon-widget ${modalPosition && 'active-cms-edit'}`}
      onClick={handleSectionClick}
      ref={sectionRef}
      style={{ padding: '2.5rem 0', borderBottom: isLastWidget ? '' : '1px solid lightgrey' }}>
      <div
        className='primary-color heading text-left font-weight-bold h4 mb-4 body1'
        dangerouslySetInnerHTML={{ __html: data?.heading }}
        style={{ color: primary_color }}></div>
      {/* <div className='primary-color text-left font-weight-bold h4 mb-4 body1' style={{ color: primary_color, fontWeight: '800' }}>
        {data?.heading}
      </div> */}
      <div className='mb-2 d-flex align-items-center justify-content-center'>
        <div className='d-flex align-items-center justify-content-center'>
          <input
            placeholder={couponCode ? couponCode : 'Code'}
            className={'rounded-input'}
            type='text'
            value={couponCodeValue ? couponCodeValue : ''}
            onChange={(e) => onChangeValue(e.target.value)}></input>
        </div>
        <div>
          <button
            className='btn btn-primary rounded-pill ml-1'
            style={{ width: 'auto', background: primary_color }}
            onClick={() => {
              if (couponCodeValue && couponCodeValue != couponCode) onVerifyCoupon()
            }}>
            {couponCode && couponCodeValue === couponCode ? (
              <MdCheck style={{ marginLeft: '0px', marginBottom: '2px' }} />
            ) : (
              <div className='cursor-pointer subtitle1' style={{ color: 'white' }}>
                Verify
              </div>
            )}
          </button>
        </div>
      </div>
      <div className='subtitle1 sub-heading' dangerouslySetInnerHTML={{ __html: data?.sub_heading }}></div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSCouponCode
            data={data}
            toggleModal={() => {
              onCloseModal()
            }}
            widget_uuid={widget_uuid}
          />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default CoupenCode

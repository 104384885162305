import { useSelector } from 'react-redux'
import Loader from 'react-loaders'
import { useState } from 'react'
import { useFetchLogsByTable } from '../../../../Queries/Logs/useFetchLogsByTable'
import RefundCreateLog from './refundCreateLog'
import MoveBookingLog from './moveBookingLog'
import UpdateLog from './updateLogs'
import RefundApprove from './refundApprove'
import RefundRejected from './refundRejected'
import PaymentCreated from './created'
import PaymentUpdatedLog from './paymentUpdated'
import PaymentGenerated from './paymentGenerated'

const renderLog = (type, data, onExpand, expandedId, parentPageName) => {
  switch (type) {
    case 'create':
      return <PaymentCreated data={data} onExpand={onExpand} expandedId={expandedId}></PaymentCreated>
    case 'edit_purchase':
      return <PaymentUpdatedLog data={data} onExpand={onExpand} expandedId={expandedId}></PaymentUpdatedLog>
    case 'update':
      return <UpdateLog data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></UpdateLog>
    case 'payment_generated':
      return <PaymentGenerated data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></PaymentGenerated>
    case 'refund_create':
      return <RefundCreateLog data={data} onExpand={onExpand} expandedId={expandedId}></RefundCreateLog>
    case 'refund_approve':
      return <RefundApprove data={data} onExpand={onExpand} expandedId={expandedId}></RefundApprove>
    case 'refund_reject':
      return <RefundRejected data={data} onExpand={onExpand} expandedId={expandedId}></RefundRejected>
    case 'move_booking':
      return <MoveBookingLog data={data} onExpand={onExpand} expandedId={expandedId}></MoveBookingLog>
    default:
      return
  }
}

const RefundLogs = ({ parentPageName }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  let role_id = currentRow?.[currentRow?.length - 1]?.uuid
  const { data, isLoading } = useFetchLogsByTable(role_id, 'payments')

  const [expandedId, setExpandedId] = useState()

  const onExpand = (id) => {
    console.log('expanded id::::::', id)
    setExpandedId(id)
  }

  if (!isLoading && data?.data?.length === 0)
    return (
      <div style={{ marginTop: '3rem' }} className='text-center font-weight-bold'>
        Logs not available!
      </div>
    )
  if (data?.data?.length !== 0 && isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '10rem', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )

  return (
    <div style={{ maxHeight: '30rem', overflow: 'auto' }}>
      {data?.data?.map((item, index) => {
        if (!expandedId) {
          return (
            <div key={index} className='p-1'>
              {renderLog(item?.type, item, onExpand, null, parentPageName)}
            </div>
          )
        } else {
          if (expandedId === item?.id) {
            return (
              <div key={index} className='p-1'>
                {renderLog(item?.type, item, onExpand, expandedId, parentPageName)}
              </div>
            )
          } else {
            return <></>
          }
        }
      })}
    </div>
  )
}

export default RefundLogs

import { useRef, useState } from 'react'
import RowTitle from './RowTitle'
// import Switch from "./Switch";
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { primary_color } from '../../Helper/uiHelper'
import ReactSwitch from 'react-switch'
import { tidyName } from '../../helper'
import { updatePermission } from '../../Helper/permissionHelper'
import { useSelector } from 'react-redux'

const CMSPermissionPanel = ({ currentPermission, role_id }) => {
  const [isWarning, setIsWarning] = useState(false)
 const warningItem = useRef()

  const reduxQueue = useSelector((state) => state.Queue.queues)

  const addPermissionArray = []
  const removePermissionArray = []

  reduxQueue?.map((queue) => {
    if (queue.url === 'update-role-wise-permission') {
      queue.payload?.data?.permissions?.map((permission) => {
        if (permission?.add_ids) {
          permission?.add_ids?.map((item) => {
            addPermissionArray?.push(item)
          })
        }
        if (permission?.remove_ids) {
          permission?.remove_ids?.map((item) => {
            removePermissionArray?.push(item)
          })
        }
      })
    }
  })

  return (
    <>
      <div className='cms-permission-panel'>
        <RowTitle title={'Cms Permissions'} />
        <div className='content'>
          {currentPermission?.cmsSetting &&
            Object.keys(currentPermission?.cmsSetting).map((key) => {
              const item = currentPermission?.cmsSetting?.[key]?.[0]
              let isItemActive = false

              if (!item?.enabled && addPermissionArray?.includes(item?.permission_id)) {
                isItemActive = true
              }
              if (item?.enabled && !removePermissionArray?.includes(item?.permission_id)) {
                isItemActive = true
              }
              console.log('cms item::::::::::',item)
              return (
                <div className='d-flex mb-4'>
                  <ReactSwitch
                    checked={isItemActive}
                    onChange={() => {
                      if (!isItemActive) {
                        warningItem.current = item
                        setIsWarning(true)
                      } else {
                        updatePermission(role_id, item?.permission_id, item?.enabled)
                      }
                    }}
                    onColor={primary_color}
                    // checked={preferanceForm[item.field_id]?.print}
                    // onChange={(value) =>
                    //   updatePreferanceForm({
                    //     ...preferanceForm,
                    //     [item.field_id]: {
                    //       ...preferanceForm[item.field_id],
                    //       print: value,
                    //     },
                    //   })
                    // }
                    uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
                    checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
                    className='d-inline'
                  />
                  <div className='ml-4 font-weight-bold'>{tidyName(key)}</div>
                </div>
              )
            })}

          {/* <Switch label={'Edit Labels'} onChange={(value) => console.log(value)} name='edit-label' id='edit-label' />
          <Switch label={'Edit CMS'} onChange={(value) => setIsWarning(true)} name='edit-cms' id='edit-cms' /> */}
        </div>
      </div>
      <Modal
        isOpen={isWarning}
        centered={true}
        toggle={() => {
          setIsWarning(false)
          warningItem.current = null
        }}
        style={{ width: '28rem', boxShadow: 'none' }}>
        <ModalHeader style={{ backgroundColor: '#FFF', border: 'none' }}>
          <div className='body1 font-weight-bold' style={{ color: primary_color }}>
            Are you sure you want to continue assigning manage permissions to this role, thereby granting access to this role?
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='d-flex justify-content-between'>
            <button
              className='btn text-white rounded-pill mt-2'
              type='button'
              onClick={() => {
                setIsWarning(false)
                console.log('warning item::::::',warningItem)
                updatePermission(role_id, warningItem?.current?.permission_id, warningItem?.current?.enabled)
                warningItem.current = null
              }}
              style={{ background: primary_color, fontWeight: 700, width: '48%',  }}>
              Yes
            </button>
            <button
              className='btn rounded-pill mt-2 text-danger border-danger'
              type='button'
              onClick={() => {
                setIsWarning(false)
                warningItem.current = null
              }}
              style={{ fontWeight: 700, width: '48%',  }}>
              Cancel
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CMSPermissionPanel

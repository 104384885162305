const data = [
  {
    title: 'Start Rent',
    key: 'pre_time',
  },
  {
    title: 'End Rent',
    key: 'post_time',
  },
  {
    title: 'Block',
    key: 'location_id_label',
  },
  {
    title: 'Academic year',
    key: 'item_date_time_id_label',
  },
  {
    title: 'Locker Number',
    key: 'item_id_label',
  },
]

const ShowBookingData = ({ item }) => {
  return (
    <div className="d-flex my-4">
      {data?.map((field) => {
        return (
          <div className="mr-5">
            <div className='font-weight-bold subtitle1'>{field?.title}</div>
            <div className='text-muted subtitle1' style={{ fontWeight: '100' }}>
              {item[field?.key]}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ShowBookingData

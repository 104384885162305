import { configEnv } from './config'

const themeApi = ['edit-theme', 'theme', 'save-custom-color', 'delete-custom-color']
const cmsApi = [
  // 'cms',
  // 'cms-update',
  // 'submit-top-nav-button',
  // 'submit-page',
  // 'submit-page-content',
  // 'submit-page-form',
  // 'get-grid-form',
  // 'save-form',
  // 'submit-form-field',
  // 'update-form-fields',
  // 'update-fields-order',
  // 'upload-form-files',
  // 'get-form-files',
  // 'remove-form-files',
  // 'submit-form-field-order',
  // 'create-sidebar-nav',
  // 'submit-nav-order',
  // 'update-main-page',
  // 'create-seeder',
  // 'save-default-form-data',
]
const gridApi = [
  // 'grid-update',
  // 'grid-rec-page',
  // 'save-grid-data-filter',
  // 'save-grid-color-condition',
  // 'save-grid-color-group',
  // 'save-grid-setting',
  // 'save-grid-email-report',
  // 'tab-progress-status',
  // 'delete-grid-email-report',
  // 'create-grid_button',
  // 'save-user-grid-preference',
  // 'save-questionnaire-setting',
  // 'save-grid-layout',
  // 'remove-grid-filter',
  // 'remove-grid-column',
  // 'order-grid-column',
  // 'submit-grid-column',
  // 'order-grid-tabs',
  // 'order-grid-forms',
  // 'insert-repeating-grid-data',
  // 'unique-validation',
  // 'submit-page-tab',
  // 'update-repeating-grid-data',
]
const connectTemplateApi = [
  // 'connect-template',
  // 'connect-template-data',
  // 'connect-template-data-depend',
  // 'connect-template-delete',
  // 'connect-template-export',
  // 'connect-template-print',
  // 'connect-template-queue',
  // 'connect-template-expand-data',
  // 'connect-pdf',
  // 'copy-template-data',
  // 'get-cms-pages',
  // '/get-cms-pages',
  // '/update-permissions',
  // 'update-permissions',
]
const pageApi = ['create-page-route', 'create-page-grid', 'copy-tab-data']
const paymentApi = ['make-payment', 'get-payment', 'check-coupon']

export const getUrlDomainConfigNameByUrl = (url) => {
  if (themeApi?.includes(url)) {
    return getCmsUrlDomainByName('theme')
  } else if (cmsApi?.includes(url)) {
    return getCmsUrlDomainByName('cms')
  } else if (gridApi?.includes(url)) {
    return getCmsUrlDomainByName('grid')
  } else if (pageApi?.includes(url)) {
    return getCmsUrlDomainByName('page')
  } else if (connectTemplateApi?.includes(url)) {
    return getCmsUrlDomainByName('connectTemplate')
  } else if (url?.includes('cms/')) {
    return getCmsUrlDomainByName('cms')
  } else if (url?.includes('remove-grid-color')) {
    return getCmsUrlDomainByName('cms')
  } else if (url?.includes('file-upload')) {
    return getCmsUrlDomainByName('cms')
  } else if (url?.includes('download-upload')) {
    return getCmsUrlDomainByName('cms')
  // } else if (url?.includes('remove/')) {
  //   return getCmsUrlDomainByName('cms')
  } else if (paymentApi?.includes(url)) {
    return getCmsUrlDomainByName('payment')
  }
}
export const getCmsUrlDomainByName = (name) => {
  // configEnv
  const env = process.env.REACT_APP_ENV
  const apiDomain = configEnv[env][name]
  return apiDomain
}

import Loader from 'react-loaders'

import { useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { request } from '../../../Redux/Sagas/requests/api'
import PageHeader from '../../Permissions/PageHeader'


const ApproveRefundPreview = ({ payload, onCancelPreview }) => {
  const [templatePreview, setTemplatePreview] = useState()
  const currentRow = useSelector((state) => state.CMS.currentRow)
  let uuid = currentRow?.[currentRow?.length - 1]?.uuid

  const [iframeHeight, setIframeHeight] = useState('0px')

  const iframeRef = useRef(null)
  const updateIframeHeight = () => {
    const iframe = iframeRef.current
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
    setIframeHeight(`${iframeDoc.body.scrollHeight}px`)
  }

  useEffect(() => {
    request('refund-preview', 'POST', payload).then((res) => {
      if (res.data) {
        setTemplatePreview(res.data)
      }
    })
  }, [])
  useEffect(() => {
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument
      iframeDoc.open()
      iframeDoc.write(templatePreview)
      iframeDoc.close()

      iframeRef.current.addEventListener('load', updateIframeHeight)
      updateIframeHeight()
    }
    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', updateIframeHeight)
      }
    }
  }, [templatePreview, iframeRef.current])

  console.log('preview data::::::::', iframeRef)

  return (
    <div>
      <PageHeader text={'Refund'}></PageHeader>
      <div
        className='cursor-pointer mb-3'
        onClick={() => {
          onCancelPreview()
          // setIsPaymentRequestPreviewOpen(false)
        }}>
        <i class='fa-sharp fa-regular fa-arrow-left'></i>
      </div>
      {iframeRef && <iframe ref={iframeRef} style={{ width: '100%', height: iframeHeight, border: 'none' }} title='HTML Renderer' />}

      {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}
    </div>
  )
}

export default ApproveRefundPreview

import { AvField, AvForm } from 'availity-reactstrap-validation'
import Accordion from 'react-bootstrap/Accordion'
import { Button, Collapse, ListGroup, ListGroupItem } from 'reactstrap'
import CMSRichText from '../../CMSComponent/Forms/Fields/richText'
import { useDispatch, useSelector } from 'react-redux'
import useUpdateEmailTemplate from '../../Queries/EmailTemplate/useUpdateEmailTemplate'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { useEffect, useState } from 'react'
import { getMainGridNameByParentPageName, validateEmail } from '../../helper'
import useSendTestEmail from '../../Queries/EmailTemplate/useSendTestEmail'
import useGetEmailTemplateResources from '../../Queries/EmailTemplate/useGetEmailTemplateResource'
import { clearPageTab } from '../../Redux/Reducers/page'
import { clearCurrentRowAllData, clearCurrentRowData, clearParentTableAllData, setConnectTemplateDataRedux, setCurrentRow } from '../../Redux/Reducers/CMS'

const EditEmailTemplate = ({ parentPageName }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const dispatch = useDispatch()
  const [email, updateEmail] = useState()
  const [showTags, setShowTags] = useState()
  const [form, updateForm] = useState({})

  let data = currentRow?.[currentRow?.length - 1]

  const updateTemplate = useUpdateEmailTemplate()
  const sendTestEmail = useSendTestEmail()
  const { data: tagsNWidgets } = useGetEmailTemplateResources(data?.uuid)

  useEffect(() => {
    console.log('data updated:::::', currentRow)
    if (currentRow?.length > 0) {
      console.log('data updated:::::')
      let data = currentRow[currentRow?.length - 1]
      updateForm(data)
    }
  }, [JSON.stringify(data),data?.content])

  const updateDataInRedux = (values) => {

    const mainGridName = getMainGridNameByParentPageName(parentPageName)
    const connectTemplateDataOfCurrentForm = connectTemplateDataRedux[parentPageName]?.[mainGridName]
    let pageIndex, rowIndex
    Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
      Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
        if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === data?.id) {
          pageIndex = key
          rowIndex = rowKey
        }
      })
    })

    let connectTemplateDataReduxNeedToUpdate = {
      ...connectTemplateDataRedux,
    }
    connectTemplateDataReduxNeedToUpdate[parentPageName] = {
      ...connectTemplateDataReduxNeedToUpdate[parentPageName],
    }
    connectTemplateDataReduxNeedToUpdate[parentPageName][mainGridName] = {
      ...connectTemplateDataReduxNeedToUpdate[parentPageName]?.[mainGridName],
    }

    connectTemplateDataReduxNeedToUpdate[parentPageName][mainGridName][pageIndex]['data']['data'][rowIndex] = {...data, ...values}
    dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxNeedToUpdate))
     dispatch(
       setCurrentRow({
         ...data,
         ...values,
       })
     )
  }

  const handleSubmit = (e, values) => {
    updateDataInRedux(values)
    updateTemplate.mutate(values, {
      onSuccess: (data) => {
        //add failed scenario also
        dispatch(setNotification({ type: 'success', message: 'Data updated successfully!' }))
      },
      onError: (error) => {
        dispatch(setNotification({ type: 'success', message: 'Something went wrong' }))
      },
    })
  }

  const onSendVerificationEmail = () => {
    if (!email || !validateEmail(email)) {
      dispatch(setNotification({ type: 'error', message: 'Please enter valid email' }))
      return
    }

    sendTestEmail.mutate(
      { email, uuid: currentRow?.[currentRow?.length - 1]?.uuid },
      {
        onSuccess: (data) => {
          //add failed scenario also
          dispatch(setNotification({ type: 'success', message: 'Email Sent Successfully!' }))
        },
        onError: (error) => {
          dispatch(setNotification({ type: 'success', message: 'Something went wrong' }))
        },
      }
    )
  }

  return (
    <div className='m-4'>
      <AvForm onValidSubmit={(event, values) => handleSubmit(event, values)}>
        <div>
          <label>Template Name</label>
          <AvField
            name='template_name'
            value={form?.template_name}
            onChange={(e) => {
              updateForm((data) => {
                return {
                  ...data,
                  template_name: e.target.value,
                }
              })
            }}></AvField>
        </div>
        <div>
          <label>Subject</label>
          <AvField
            name='subject'
            value={form?.subject}
            onChange={(e) => {
              updateForm((data) => {
                return {
                  ...data,
                  subject: e.target.value,
                }
              })
            }}></AvField>
        </div>
        <div className='d-flex'>
          <div style={{ flex: 1 }}>
            <label>Email</label>
            <AvField name='email' onChange={(e) => updateEmail(e.target.value)}></AvField>
          </div>
          <div className='d-flex flex-column justify-content-center ml-3'>
            <label></label>
            <Button
              color='warning'
              className='text-light'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onSendVerificationEmail()
              }}>
              {sendTestEmail?.isLoading ? 'Sending...' : 'Send Test Email'}
            </Button>
          </div>
        </div>
        <CMSRichText
          data={{ field_id: 'content' }}
          onChange={(e) => {
            updateForm((data) => {
              return {
                ...data,
                content: e,
              }
            })
          }}
          id='content'
          value={form?.content}></CMSRichText>
        <AvField name={'uuid'} type='hidden' value={currentRow?.[currentRow?.length - 1]?.uuid}></AvField>

        <div className='d-flex justify-content-end'>
          <Button
            color='secondary'
            // disabled={validationErrorOnFieldValueChange?.length > 0}
            size='lg'
            className={`btn-shadow  mr-2 `}
            style={{ marginBottom: '1rem' }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              dispatch(clearPageTab())
              dispatch(clearCurrentRowData())
              dispatch(clearParentTableAllData())
              dispatch(clearCurrentRowAllData())
            }}>
            Close
          </Button>
          <Button
            color='primary'
            // disabled={validationErrorOnFieldValueChange?.length > 0}
            size='lg'
            className={`btn-shadow  mr-2 `}
            style={{ marginBottom: '1rem' }}>
            {updateTemplate?.isLoading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </AvForm>

      <ListGroup className='mt-5'>
        <ListGroupItem className='cursor-pointer' onClick={() => setShowTags(!showTags)} style={{ background: '#049FDB1A' }}>
          Show Tags & Widgets
        </ListGroupItem>
        <Collapse isOpen={showTags} className='p-4'>
          <div className='d-flex'>
            <div style={{ flex: 1 }}>
              <div className='font-weight-bold mb-4'>Tags</div>
              {tagsNWidgets?.emailTags?.map((item) => {
                return <div className='mb-2'>{`%${item?.key || item?.name}%`}</div>
              })}
            </div>
            <div style={{ flex: 1 }}>
              <div className='font-weight-bold mb-4'>Widgets</div>
              {tagsNWidgets?.emailWidgets?.map((item) => {
                return <div className='mb-2'>{`[${item?.key || item?.name}]`}</div>
              })}
            </div>
          </div>
        </Collapse>
      </ListGroup>
    </div>
  )
}

export default EditEmailTemplate

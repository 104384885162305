import React, { Fragment, useState } from "react";
import { Button } from "reactstrap";
import PrivacyPolicy from "../Sections/privacypolicy";
import TermsConditions from "../Sections/termsconditions";
import useMediaQuery from "../../CustomHook/useMediaQuery";
import LogoWhite from "../../assets/utils/images/logo-white.png";
import { container_width, primary_color } from "../../Helper/uiHelper";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { tidyName } from "../../helper";

const PublicFooter = () => {
  const history = useHistory();
  const Navigations = useSelector((state) => state.Navigations?.FooterNav);

  const policies = [
    { title: "Privacy Policy", url: "/privacy-policies" },
    { title: "Terms & Conditions", url: "/terms-and-condition" },
  ];
  const policyItems = {
    "Privacy Policy": <PrivacyPolicy />,
    "Terms & Conditions": <TermsConditions />,
    "Cookie Policy": "Cookie Policy",
    Contact: "Contact",
  };

  const [selectedData, setSelectedData] = useState("");
  const isDesktop = useMediaQuery("(min-width:768px)");

  const handleItemClick = (item, data) => {
    setSelectedData(policyItems[item]);
    history.push("/" + data?.route);
  };

  return (
    <Fragment>
      <div
        className={`footer d-flex py-5 px-3vw justify-content-center ${isDesktop ? 'align-items-end' : ''}`}
        style={{
          // height: isDesktop ? '11rem' : 'auto',
          background: primary_color,
          color: '#fff',
          position: 'relative',
          bottom: '0',
          left: '0',
          right: '0',
          paddingLeft: isDesktop && '1rem',
          paddingRight: isDesktop && '1rem',
        }}>
        {/* <div style={{ width: container_width }}> */}
        <div style={{ width: '100%' }}>
          <div
            className={`app-footer__inner  ${isDesktop ? 'align-items-end row' : 'flex-column'}`}
            style={{ flex: 1, marginLeft: isDesktop?'0rem':'3rem'}}>
            <div className={` app-footer-left text-left ${isDesktop ? 'col-6 d-flex align-items-center' : ''}`}>
              <div className={` ${isDesktop ? ' col-6 d-flex flex-column ml-3' : 'mt-4'} `}>
                <img
                  alt='logo'
                  className='mb-4'
                  style={{
                    mixBlendMode: 'plus-lighter',
                    marginBottom: isDesktop ? '0' : '1rem',
                  }}
                  src={LogoWhite}
                  // height='70px'
                  width='200px'
                />

                <p className={isDesktop ? 'mb-0 subtitle1' : ''}>Copyright 2024 Locker.rental</p>
              </div>
            </div>
            <div className={` ${isDesktop ? 'col-6 d-flex justify-content-end' : ''}`}>
              {Navigations.map((ele, index) => (
                <div
                  key={ele}
                  className={`subtitle1 mr-${index === policies.length - 1 ? '0' : '4'} ${isDesktop ? '' : 'mb-4 !mr-0'}`}
                  onClick={() => handleItemClick(ele?.title, ele)}
                  style={{ cursor: 'pointer' }}>
                  {tidyName(ele?.name)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {selectedData && <div className='data'>{selectedData}</div>}
    </Fragment>
  )
};

export default PublicFooter;

import { store } from './Redux/Config/configureStore'
import avatar1 from './assets/utils/images/avatars/default-user.png'

import {
  addChangedFieldIdByFormName,
  callConnectApi,
  callConnectTemplateApi,
  callConnectTemplateRestoreApi,
  callFreeApi,
  changePageCrashedStatus,
  editModal,
  setCMSDefaultValue,
  setCompletedQuestionnaireRow,
  setDeletedRowIdInRedux,
  setDropdownOptionRefetchTable,
  setExpandTableDataByFilter,
  setGridFormInRedux,
  setModalValue,
  setPagePermissionDataByUserId,
  setPermissionUpdatedTime,
  setUserSecurityOverviewDropdown,
  updateRowSyncIdInRedux,
} from './Redux/Reducers/CMS'
import IconSet from './CMSComponent/icon'
import { UncontrolledTooltip, Button, ListGroup, ListGroupItem, Container, Row, Col } from 'reactstrap'
import { SetFullPage, SetTab } from './Redux/Reducers/page'
import EditButton from './CMSComponent/Table/Component/editButton'
import { request } from './Redux/Sagas/requests/api'
import UploadPercent from './CMSComponent/Table/Component/uploadPercent'

import { setPermissions } from './Redux/Reducers/permissions'
import AggregateTableCell from './CMSComponent/Table/Component/AggregateColumn/aggregateColumn'
import ReplicateColumn from './CMSComponent/Table/Component/replicateColumn'
import { setQueueAfterCRUDops } from './Redux/Reducers/queue'
import moment from 'moment'
import { primary_color } from './Helper/uiHelper'

export const can = (permission) => {
  return true
  // return permission === '' || permission === 'no_permission' || !(store.getState().Permissions.indexOf(permission) === -1)
}

export const userAvatar = () => {
  const user = store.getState().User.user
  return user && user.photo ? process.env.REACT_APP_URL_FILES + user.photo : avatar1
}

export const cms = (value) => {
  if (value === undefined || value === null || typeof value !== 'string') return null

  const cms = store.getState().CMS.cmsData
  // avoid an error if the value is not exist in cms array.
  let string = cms[value] ? cms[value] : ''
  if (!string) {
    string = value.substring(value.indexOf('.') + 1)
    string = capitalize(string.replace(/_/g, ' '))
  }

  return capitalize(string)
}

export const getCmsData = (search) => {
  if (search === undefined || search === null || typeof search !== 'string' || search.length < 2) return []
  const cms = Object.fromEntries(
    Object.entries(store.getState().CMS.cmsData).filter(([key, value]) => value && value.toLowerCase().search(search.toLowerCase()) !== -1)
  )
  return Object.values(cms)
}

export const capitalize = (phrase = '') => {
  if (isNaN(phrase)) {
    return phrase
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  } else {
    return phrase
  }
}

export const findInCMS = (cms, value) => {
  if (!cms || cms.length === 0) return false
  let finalValue = false
  cms.forEach((items) => {
    items.forEach((item) => {
      if (item.key === value) finalValue = item.value
    })
  })

  return finalValue
}

export const editCMS = (value) => {
  if (!store.getState().CMS?.editMode) return false
  store.dispatch(setModalValue({ key: value, value: cms(value) }))
  store.dispatch(setCMSDefaultValue({ key: value, value: cms(value) }))
  store.dispatch(editModal(true))
}

export const checkImage = (name) => {
  if (!name) return false
  return name.match(/.(jpg|jpeg|png|gif)$/i) !== null
}

export const getColor = (name) => {
  const bootstrapColor = {
    primary: '#545cd8',
    secondary: '#424242',
    accent: '#82B1FF',
    dark: '#212529',
    danger: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    light: '#f8f9fa',
    blue: '#049FDB',
    none: '#fff',
  }
  return bootstrapColor.hasOwnProperty(name) ? bootstrapColor[name] : '#1976D2'
}

export const getFromRedux = (name) => {
  if (name) {
    const coreData = store.getState().CMS.coreData
    return findInObject(coreData, name)
  } else {
    return ''
  }
}

export const getFromUpdateApi = (data) => {
  const updateApiData = store.getState().CMS.updateApiData
  if (!updateApiData) return ''
  if (data && data.name && data.name.includes('.')) return findInObject(updateApiData, data.name)
  if (data && data.redux_data) {
    if (updateApiData[data.redux_data] && updateApiData[data.redux_data][data.data]) {
      if (typeof updateApiData[data.redux_data][data.data] === 'number' || typeof updateApiData[data.redux_data][data.data] === 'string') {
        return updateApiData[data.redux_data][data.data]
      }
      return updateApiData[data.redux_data][data.data].length
    }
    return ''
  }
  return updateApiData[data.name] ? (updateApiData[data.name][data.data] ? updateApiData[data.name][data.data] : updateApiData[data.name]) : ''
}

export const findInObject = (obj, attributes) => {
  if (!attributes || typeof attributes !== 'string') return ''
  let finalValue = obj
  attributes.split('.').map((item) => {
    finalValue = finalValue[item] || ''
  })
  return finalValue
}

export const validateForAv = (validation, type = '') => {
  let updatedValidation = {}
  validation.map((item) => {
    const newVal = getValidation(item)
    updatedValidation = newVal ? { ...updatedValidation, ...newVal } : updatedValidation
  })
  return updatedValidation
}

const getValidation = (item) => {
  switch (item.validation_type) {
    case 'required':
      return { required: { value: true, errorMessage: item.message } }
    case 'string':
      return {
        pattern: { value: '^[A-Za-z0-9@.]+$', errorMessage: item.message },
      }
    case 'number':
    case 'numeric':
      return {
        pattern: {
          value: '^[0-9.]+$',
          errorMessage: item.message,
        },
      }
    case item.validation_type.search('min:') === 0 ? item.validation_type : 'no':
      return {
        minLength: {
          value: item.validation_type.split(':')[1],
          errorMessage: item.message || 'Please enter minimum ' + item.validation_type.split(':')[1] + ' characters.',
        },
      }
    case item.validation_type.search('max:') === 0 ? item.validation_type : 'no':
      return {
        maxLength: {
          value: item.validation_type.split(':')[1],
          errorMessage: item.message || 'Please enter maximum ' + item.validation_type.split(':')[1] + ' characters.',
        },
      }
    case 'email':
      return {
        email: {
          value: true,
          errorMessage: item.message || 'Email is not valid.',
        },
      }
    case 'confirm:password':
      return {
        match: {
          value: 'password',
          errorMessage: item.message || "Password doesn't match.",
        },
      }
    default:
      break
  }
}

export const getNavFilterList = (list) => {
  let updatedList = []

  const addToList = (item) => {
    if (typeof item !== 'object') {
      return false
    }
    if (item.path) {
      updatedList.push({
        path: item.path,
        page: item.page || '',
        cms: item.cms,
        main_page: item?.main_page,
      })
    }
    if (item.items && item.items.length > 0) {
      item.items.map((item1) => {
        addToList(item1)
      })
    }
    if (item.length > 0) {
      item.map((item2) => {
        addToList(item2)
      })
    }
  }

  list?.map((item) => {
    // if (typeof item !== 'object') {
    //   updatedList.push({ path: item, page: '' })
    // }
    if (item.path) updatedList.push(item.path)
    if (item.items && item.items.length > 0) {
      item.items.map((item1) => {
        addToList(item1)
      })
    }
  })
  return updatedList
}

export const getFullNavListCms = (list) => {
  let updatedList = []
  const addToList = (item) => {
    if (item.items && item.items.length > 0) {
      updatedList.push({ cms: item.name })
      item.items.map((item1) => {
        addToList(item1)
      })
    }
    if (item.length > 0) {
      item.map((item2) => {
        addToList(item2)
      })
    }
    if (item.path) {
      updatedList.push({
        path: item.path,
        page: item.page || '',
        cms: item.cms,
      })
    }
  }

  list?.map((item) => {
    if (item.path) updatedList.push({ path: item.path, cms: item.cms })
    if (item.items && item.items.length > 0) {
      updatedList.push({ cms: item.name })
      item.items.map((item1) => {
        addToList(item1)
      })
    }
    if (typeof item === 'string') {
      updatedList.push({ cms: item })
    }
  })
  return updatedList
}

export function downloadFile(url, fileName) {
  fetch(url, { method: 'get', mode: 'no-cors' })
    .then((res) => res.blob())
    .then((res) => {
      const aElement = document.createElement('a')
      aElement.setAttribute('download', fileName)
      const href = URL.createObjectURL(res)
      aElement.href = href
      aElement.setAttribute('target', '_blank')
      aElement.click()
      URL.revokeObjectURL(href)
    })
}

export function getColumnData(custom_fields, name) {
  let value = ''
  custom_fields?.forEach((item) => {
    if (item.name === name) {
      value = item.value_text
    }
  })
  return value
}

export function download(url, name) {
  if (!url) {
    throw new Error('Resource URL not provided! You need to provide one')
  }
  fetch(url, { mode: 'no-cors' })
    .then((response) => {
      return response.blob()
    })
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = blobURL
      a.style = 'display: none'

      if (name && name.length) a.download = name
      document.body.appendChild(a)
      a.click()
    })
}

export const downloadFiles = (url, name, payload = {}) => {
  request(url, 'POST', { ...payload }, {}, {}, {}, { responseType: 'arraybuffer' }).then((response) => {
    const blob = new Blob([response.data])
    const blobUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = name
    document.body.appendChild(link)
    link.click()
  })
}

export const printPDFByContent = (response) => {
  const blob = new Blob([response.data], { type: 'application/pdf' })
  const blobUrl = window.URL.createObjectURL(blob)
  // URL of the PDF you want to print
  const pdfUrl = blobUrl

  // Open a new window with the PDF URL
  const printWindow = window.open(pdfUrl)

  // Check if the new window was successfully opened
  if (printWindow) {
    // Wait for the content to load before triggering the print dialog
    printWindow.onload = () => {
      // This might not work in all browsers due to varying load event implementations for window objects
      printWindow.focus() // Required for some browsers to bring the new window to front
      printWindow.print() // Trigger the print dialog
      printWindow.onafterprint = () => printWindow.close() // Optionally close the new window after printing
    }
  } else {
    // Inform the user that the popup was blocked or could not be opened
    alert('Unable to open the PDF in a new window. Please check your popup settings.')
  }
}

export const dataConnect = (connection, data = '', name = '', isQueue = false, noLoad = false) => {
  console.log(connection, 'connection')
  if (connection.component_name === 'open_modal') {
    store.dispatch(
      callConnectTemplateApi({
        data: {
          source_table: connection.gridData?.source_table,
          columns: [connection.gridData?.fields],
          clause_data: connection.gridData?.clause_data,
        },
        name,
        isQueue,
        noLoad,
      })
    )
    return store.getState().CMS.lastDataConnectionData.data
  }
  switch (connection.action) {
    case 'connect_api':
      store.dispatch(callConnectApi({ url: connection.api, data, name, isQueue }))
      return store.getState().CMS.lastDataConnectionData.data
    case 'connect_redux':
      // TODO for Richard: need to remove .data and have proper data from api here
      // TODO for Richard: need to put form data_connection from form to field for the field value. (issue: suppose some field required connect_api in data_conection then every field will call same api. e.g. just like "Users Inline Update" )
      const coreData = store.getState().CMS.coreData
      return coreData[connection.name]?.data
    case 'connect_template':
      store.dispatch(callConnectTemplateApi({ data, name, isQueue, noLoad }))
      return {}
    case 'connect_template_restore':
      store.dispatch(callConnectTemplateRestoreApi({ data, name, isQueue }))
      return {}
    default:
      store.dispatch(callConnectTemplateApi({ data, name, isQueue, noLoad }))
      return {}
  }
}

export const findMethod = (by, key, name) => {
  const tempaltes = store.getState().CMS.coreData.grid_selector
  if (tempaltes[key] && tempaltes[key].methods) {
    let data = ''
    tempaltes[key].methods.map((item) => {
      if (item.table === by) {
        data = item[name]
      }
    })
    return data
  }
  return null
}

export const findColumns = (table) => {
  const tempaltes = store.getState().CMS.coreData.grid_selector
  if (tempaltes[table] && tempaltes[table].columns) {
    let data = []
    tempaltes[table].columns.map((item) => {
      data.push(item.name)
    })
    return data
  }
  return []
}

export async function dataAction(connection, name = undefined) {
  if (!connection) return []
  switch (connection.component_name) {
    case 'get_radio_from_api':
      await store.dispatch(callConnectApi({ url: connection.url, method: 'get', name }))
      break
    default:
      break
  }
}

// '0_20_min', '21_40_min', '41_60_min', '60_+_min'

export const getAggregateColumnHeader = (col) => {
  switch (col) {
    case 'hr_under_bool':
      return 'HR (under)'
    case 'hr_more_bool':
      return 'HR (more)'
    case 'text_time':
      return 'Time'
    case 'completed_min':
      return 'Minutes'
    case '0_20_min':
      return '0-20 Mins'
    case '21_40_min':
      return '21-40 Mins'
    case '41_60_min':
      return '41-60 Mins'
    case '60_+_min':
      return '60+ Mins'
    case 'hr_under_percent':
      return 'HR (under)'
    case 'hr_more_percent':
      return 'HR (more)'
    case 'count':
      return 'Count'
    case 'month':
      return 'month'
    default:
      return ''
  }
}

const columnNeedToHideOnFilterBy = {
  contractor_id: ['location_report', 'group_name'],
  completed_start_date_time: ['contractor_report', 'location_report', 'group_name'],
  location_id: ['contractor_report', 'group_name'],
  'items-user_id': ['contractor_report', 'location_report'],
}

export const getTableColumns = (
  cols,
  expand_fields = [],
  triggerButtonActions = ([], [], [], undefined) => {},
  preference,
  savedFilters = null,
  tableData = [],
  content,
  pageName,
  parentPageName,
  isTab,
  isModel,
  gridRelatedData
) => {
  const columnSetting = {
    checkbox: false,
    columns: [],
    sortBy: [],
    filters: [],
  }

  const button_setting = content?.main_grid?.grid_button_setting

  const grid_button_setting = button_setting && JSON.parse(button_setting)

  if (expand_fields || content?.main_grid?.grid_expand_table?.length > 0) {
    columnSetting.columns.push({
      Header: (props) => (
        <span onClick={() => props.toggleAllRowsExpanded()}>
          {props.isAllRowsExpanded ? <IconSet icon='far fa-arrow-down' color='dark' /> : <IconSet icon='far fa-arrow-right' color='accent' />}
        </span>
      ),
      commonStyle: {
        width: '3%',
        flex: 'none',
        display: 'inline-block',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'capitalize',
        textAlign: 'center',
      },
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <IconSet icon='far fa-arrow-down' color='dark' /> : <IconSet icon='far fa-arrow-right' color='accent' />}
        </span>
      ),
    })
  }
  if (!cols) return []
  let columns = []
  const icon_cols = cols?.filter((col) => col.component_name === 'icon_col_button')
  const icon_cols_filtered = cols
    ?.filter((col) => col.component_name === 'icon_col_button')
    ?.filter((item) => !grid_button_setting?.table[item?.name])
  const check_cols = cols?.filter((col) => col?.component_name === 'check_col')

  const action_button = cols?.filter((col) => col?.component_name === 'action_button')
  const aggregate_column = cols?.filter((col) => col?.component_name === 'aggregate_column')
  const replicate_column = cols?.filter((col) => col?.component_name === 'replicate_button')
  const text_col = cols
    ?.filter((col) => col?.component_name === 'text_data_col')
    ?.filter((item) => {
      if (gridRelatedData?.activeFilterBy) {
        if (columnNeedToHideOnFilterBy[gridRelatedData?.activeFilterBy?.replace('.', '-')]?.includes(item?.field_id)) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    })

  if (check_cols?.length > 0) {
    columns = [...columns, ...check_cols]
  }
  if (icon_cols?.length > 0) {
    columns = [...columns, ...icon_cols]
  }
  if (content?.source_table === 'contractors') {
    columns = [...columns, { component_name: 'upload_column' }]
  }
  if (replicate_column?.length > 0) {
    columns = [...columns, { component_name: 'replicate_button' }]
  }
  if (text_col?.length > 0) {
    columns = [...columns, ...text_col]
  }

  if (action_button?.length > 0) {
    columns = [...columns, ...action_button]
  }

  columns.map((item, key) => {
    if (getButtonActionByIcon(item?.icon) && !checkPermission(getButtonActionByIcon(item?.icon), pageName, parentPageName, isTab)) {
      return
    }
    switch (item.component_name) {
      case 'check_col':
        if (can(item.permission) && item.display_column) {
          columnSetting.checkbox = true
        }
        break
      case 'upload_column':
        if (item.hidden) return false
        columnSetting.columns.push({
          Header: 'Upload Percentage',
          commonStyle: {
            width: '6rem',
            flex: 'none',
            editable: true,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'capitalize',
            textAlign: 'center',
            borderRight: columns[key + 1]?.component_name === 'icon_col_button' ? 'none' : '',
          },
          Cell: (row) => {
            return <UploadPercent row={row?.row} id={row?.row?.id} content={content} />
          },
        })
        break
      case 'icon_col_button':
        if (item.hidden) return false
        if (grid_button_setting?.table[item?.name]) return

        columnSetting.columns.push({
          Header: item.name.replace('users-', ''),
          commonStyle: {
            width: '5%',
            flex: 'none',
            editable: true,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'capitalize',
            textAlign: 'center',
            borderRight:
              columns[key + 1]?.component_name === 'icon_col_button' && icon_cols_filtered?.[icon_cols_filtered?.length - 1]?.name !== item?.name
                ? 'none'
                : '',
          },
          Cell: (row) => {
            return (
              <EditButton
                item={item}
                row={row.row}
                id={row.row?.id}
                triggerButtonActions={triggerButtonActions}
                content={content}
                pageName={pageName}
                parentPageName={parentPageName}
                isModel={isModel}
              />
            )
          },
        })
        break
      case 'text_data_col':
        if (item.display_column && (!preference?.field_order || preference?.field_order[item.field_id]?.display)) {
          let commonStyle
          if (item.field_id === 'id') {
            commonStyle = {
              width: '6rem',
              flex: 'none',
              display: 'flex',
            }
          }
          const data = {
            Header: (row) => {
              if (item.cms_key) {
                return (
                  <>
                    <div
                      onClick={() => editCMS(item.cms_key)}
                      className='primary_color font-weight-bold'
                      id={'toolTiptable' + key}
                      style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {cms(item.cms_key)}
                    </div>
                    {item.cms_key_tooltip !== null && (
                      <UncontrolledTooltip placement='right' target={'toolTiptable' + key}>
                        {cms(item.cms_tooltip)}
                      </UncontrolledTooltip>
                    )}
                  </>
                )
              } else {
                return <div>{tidyName(item.field_id)}</div>
              }
            },
            commonStyle,
            disableSortBy: !item.allow_sorting && store.CMS?.editMode,
            accessor: item.field_id || cms(item.cms_key),
            filter: item.filtering ? 'fuzzyText' : '',
            editable: item.inline_edit,
            colData: item,
            background_color: item.background_color || '',
            savedFilterValue: savedFilters && savedFilters['-filter-' + item.field_id] ? savedFilters['-filter-' + item.field_id] : '',
          }
          // TODO for Richard to just a FYI stuff
          columnSetting.columns.push(data)
          if (item.sort_priority) {
            columnSetting.sortBy.push({
              id: item.field_id || cms(item.cms_key),
              desc: item.sort !== 'ASC',
            })
          }
          if (item.default_filter) {
            columnSetting.filters.push({
              id: item.field_id || cms(item.cms_key),
              value: item.default_filter,
            })
          }
        }
        break
      case 'advanced_field_col':
        if (can(item.permission) && item.display_column && (!preference?.field_order || preference?.field_order[item.field_id]?.display)) {
          const data = {
            Header: (row) => {
              return (
                <>
                  <div onClick={() => editCMS(item.cms_key)} id={'toolTiptable' + key}>
                    {cms(item.cms_key)}
                  </div>
                  {item.cms_key_tooltip !== null && (
                    <UncontrolledTooltip placement='right' target={'toolTiptable' + key}>
                      {cms(item.cms_tooltip)}
                    </UncontrolledTooltip>
                  )}
                </>
              )
            },
            disableSortBy: !item.allow_sorting && store.CMS?.editMode,
            accessor: item.field_id || cms(item.cms_key),
            filter: item.filtering ? 'fuzzyText' : '',
            background_color: item.background_color || '',
            savedFilterValue: savedFilters && savedFilters['-filter-' + item.field_id] ? savedFilters['-filter-' + item.field_id] : '',
          }
          // TODO for Richard to just a FYI stuff
          columnSetting.columns.push(data)
          if (item.sort_priority) {
            columnSetting.sortBy.push({
              id: item.field_id || cms(item.cms_key),
              desc: item.sort !== 'ASC',
            })
          }
          if (item.default_filter) {
            columnSetting.filters.push({
              id: item.field_id || cms(item.cms_key),
              value: item.default_filter,
            })
          }
        }
        break
      case 'action_button':
        if (item.hidden) return false
        columnSetting.columns.push({
          Header: item.name,
          Cell: (row) => {
            return (
              <EditButton
                item={item}
                row={row.row}
                id={row.row?.id}
                triggerButtonActions={triggerButtonActions}
                content={content}
                isModel={isModel}
                pageName={pageName}
                parentPageName={parentPageName}
              />
            )
          },
        })
        break
      case 'replicate_button':
        columnSetting.columns.push({
          Header: 'Replicate',
          commonStyle: {
            width: '8%',
            flex: 'none',
            editable: true,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'capitalize',
            textAlign: 'center',
            // borderRight: columns[key + 1]?.component_name === 'icon_col_button' ? 'none' : '',
          },
          Cell: (row) => {
            return <ReplicateColumn row={row} content={content} pageName={pageName} parentPageName={parentPageName} isTab={isTab} />
          },
        })
        break

      default:
        break
    }
  })
  aggregate_column?.map((item, key) => {
    item?.options?.columns?.map((col) => {
      if (preference['aggregate_column']?.preference_options?.includes(col)) {
        return
      }
      if (col === 'edit') {
        columnSetting.columns.unshift({
          Header: getAggregateColumnHeader(col),
          field_id: col,
          accessor: col,
          commonStyle: {
            width: '5%',
            flex: 'none',
            editable: true,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'capitalize',
            textAlign: 'center',
            borderRight: columns[key + 1]?.component_name === 'icon_col_button' ? 'none' : '',
          },

          Cell: (row) => {
            return <AggregateTableCell row={row} />
          },
        })
      } else {
        let commonStyle
        if (col === 'text_time' || col === 'month') {
          commonStyle = {
            width: text_col?.length === 0 ? '10rem' : '7rem',
            flex: 'none',
            display: 'flex',
          }
        } else if (col === 'hr_under_bool' || col === 'hr_more_bool') {
          commonStyle = {
            width: '5rem',
            flex: 'none',
            display: 'flex',
          }
        } else if (item?.options?.columns?.length > 5) {
          commonStyle = {
            width: text_col?.length === 0 ? '6rem' : '4rem',
            flex: 'none',
            display: 'flex',
          }
        }
        columnSetting.columns.push({
          Header: getAggregateColumnHeader(col),
          field_id: col,
          accessor: col,
          commonStyle: { ...commonStyle },
          isAggregateColumn: true,

          filter: 'aggregate_filter',
          Cell: (row) => {
            return <AggregateTableCell row={row} />
          },
        })
      }
    })
  })

  return columnSetting
}

const getFieldValue = (value, field_type, field_id, tableOptions, source_table) => {
  if (value === 0) return 0
  if (!value) return
  if (field_type === 'dropdown') {
    const options = tableOptions[field_id]
    const selected_option = options?.filter((option) => option.value === value)
    let result = value || ''
    if (selected_option?.length > 0) {
      result = selected_option[0]?.label
    }
    return result
  } else if (field_type === 'switch') {
    if (value) {
      return <span className='badge badge-pill bg-success sidebar-text-light'>Yes</span>
    } else {
      return <span className='badge badge-pill bg-danger sidebar-text-light'>No</span>
    }
  } else if (field_type === 'datetime' || field_type === 'date-time') {
    if (value) {
      return moment(value).format('MMM D  YYYY, hh:mm')
    } else {
      return
    }
  } else if (field_type === 'date') {
    if (value) {
      return moment(value).format('Do MMM YYYY')
    } else {
      return
    }
  } else if (field_type === 'rich_text') {
    return <div dangerouslySetInnerHTML={{ __html: value }} />
  } else {
    return value
  }
}

export const getExtendArea = (data, field, editMode, tableOptions, source_table) => {
  const filtered_fields = field?.filter((item) => item?.field_setting?.field_id !== 'questionnaireExpand' && item?.field_id !== 'questionnaireExpand')

  if (filtered_fields?.length === 0) return
  return (
    <Container>
      <Row>
        <Col>
          <div className='gridSettigTable mt-3 mb-4'>
            <table>
              <thead>{/* <th colSpan={2}></th> */}</thead>
              <tbody>
                {filtered_fields?.map((col) => {
                  return (
                    <tr>
                      <td
                        style={{ width: '30%', cursor: editMode ? 'pointer' : '' }}
                        onClick={() => {
                          if (editMode) {
                            editCMS(col?.cms_key)
                          }
                        }}>
                        {cms(col?.cms_key)}
                      </td>

                      <td>
                        {getFieldValue(
                          data[`${col?.field_id}_label`] || data[col?.field_id],
                          col?.field_setting?.field || getFieldTypeByFieldId(col?.field_id, source_table),
                          col?.field_id,
                          tableOptions,
                          source_table
                        )}
                      </td>
                      {/* <td>{data[col?.field_id]}</td> */}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {/* <ListGroup>
            {field?.map((item, key) => {
              return (
                <ListGroupItem key={key}>
                  <strong onClick={() => editCMS(item.cms_key)}>{cms(item.cms_key)} : </strong> {data[item.field_id]}
                </ListGroupItem>
              )
            })}
          </ListGroup> */}
        </Col>
      </Row>
    </Container>
  )
}

export const isEqual = function (value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false

  // Compare the length of the length of the two items
  var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length
  var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) return false

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1)

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false
      } else {
        if (item1 !== item2) return false
      }
    }
  }

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false
      }
    }
  }

  // If nothing failed, return true
  return true
}

export const compareDate = (date1, date2) => {
  if (checkIsDate(date1) && checkIsDate(date2)) {
    if (new Date(date1) >= new Date(date2)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const buttonAction = (actions, data = []) => {
  actions?.map((item) => {
    switch (item.component_name) {
      case 'open_tab':
        data?.map((tab, index) => {
          if (tab.slug === item.slug) {
            store.dispatch(SetFullPage(false))
            store.dispatch(SetTab(index))
          }
        })
        break
      case 'submit_api':
        let apiData = ''
        if (item?.payload?.data === 'selected_id' || item?.payload === 'id' || item?.payload === 'selected_id') {
          apiData = { id: [data.id] }
        }
        store.dispatch(callFreeApi({ url: item.url, data: apiData }))
        break
      default:
        break
    }
  })
}

export const createUrl = (url, data) => {
  if (!data) {
    return url
  }
  let id = url.substring(url.indexOf('{') + 1, url.lastIndexOf('}'))
  if (data[id]) {
    const realId = data[id]
    return url.replace(id, realId).replace('{', '').replace('}', '')
  }
  return url
}

export const tidyName = (name) => {
  if (!name) return ''
  if (typeof name !== 'string') return name
  name = name.replace('.', ' > ')
  return capitalize(name.replace(/_/g, ' '))
}

export const getNumberOfByte = (str = '') => {
  let res = ''
  res = str
    .split('')
    .map((char) => {
      return char.charCodeAt(0).toString(2)
    })
    .join(' ')
  return Math.ceil(res.length / 8)
}

export const templateType = (type) => {
  switch (type) {
    case 'int':
      return 'Number'
    case 'date':
      return 'Date'
    case 'varchar':
      return 'text'
    case 'text':
      return 'text'
    case 'boolean':
      return 'true/false'
    case 'boolean':
      return 'true/false'
    case 'timestamp':
      return 'Time'
    case 'tinyint':
      return 'Number'
    case 'enum':
      return 'text'
    case 'dropdown':
      return 'Select option'
    case 'bigint':
      return 'large Number'
    default:
      return type
  }
}

export function covertFileIntoBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const getFileExtentionFromBase64 = (type) => {
  switch (type) {
    case 'IVBOR':
      return '.png'
    case '/9J/4':
      return '.jpg'
    case 'AAAAI':
      return '.mp4'
    case 'JVBER':
      return '.pdf'
    case 'AAABA':
      return '.ico'
    case 'UMFYI':
      return '.rar'
    case 'E1XYD':
      return '.rtf'
    case 'U1PKC':
      return '.txt'
    case 'MQOWM':
    case '77U/M':
      return '.srt'
    default:
      return '.doc'
  }
}

export const checkSingleAnswerValidation = (type, answer) => {
  if (type?.includes('required')) {
    switch (answer?.question_type) {
      case 'select':
        if (answer?.response_text && answer?.response_text?.length > 0 && answer?.answer_id) {
          return true
        } else {
          return false
        }
      case 'multy-select':
        if (answer?.answer_id && answer?.answer_id?.length > 0) {
          return true
        } else {
          return false
        }
      case 'radio':
        if (answer?.response_text && answer?.response_text?.length > 0 && answer?.answer_id) {
          return true
        } else {
          return false
        }
      case 'check':
        if (answer?.answer_id && answer?.answer_id?.length > 0) {
          return true
        } else {
          return false
        }
      case 'date':
        if (answer?.response_text && answer?.response_text !== '') {
          return true
        } else {
          return false
        }
      case 'time':
        if (answer?.response_text && answer?.response_text !== '') {
          return true
        } else {
          return false
        }
      case 'date-time':
        if (answer?.response_text && answer?.response_text !== '') {
          return true
        } else {
          return false
        }
      case 'text':
        if (answer?.response_text && answer?.response_text?.length > 0) {
          return true
        } else {
          return false
        }
      case 'rich-text':
        if (answer?.response_text && answer?.response_text?.length > 0) {
          return true
        } else {
          return false
        }
      case 'upload':
        if (answer?.upload_base64 && answer?.upload_base64?.length > 0) {
          return true
        } else {
          return false
        }
      case 'signiture_basic':
        if (answer?.image_base64 && answer?.image_base64?.length > 0) {
          return true
        } else {
          return false
        }
      case 'signiture_name':
        if (answer?.image_base64 && answer?.image_base64?.length > 0) {
          return true
        } else {
          return false
        }
      case 'signiture_select':
        if (answer?.image_base64 && answer?.image_base64?.length > 0) {
          return true
        } else {
          return false
        }
      case 'divider':
        return true
    }
  } else {
    return true
  }
}

export const checkQuestionnaireValidations = (questions, answers) => {
  const validation_array = answers
    ?.filter((item) => item?.validation?.includes('required'))
    ?.map((answer) => {
      const question = questions?.filter((question) => question?.id === answer?.item_question_id)
      const type = question && question[0]?.validations

      if (type) {
        return checkSingleAnswerValidation(type, answer)
      } else {
        return true
      }
    })

  const isValidatedArray = validation_array?.filter((item) => !item)

  if (isValidatedArray?.length === 0) {
    return true
  } else {
    return false
  }
}

export const getPageNameForCoreDataFromUrl = () => {
  const navMenu = store.getState().CMS.coreData?.nav

  const nav = getNavFilterList(navMenu)
  let name
  nav.forEach((item) => {
    if (window.location.hash.replace('#/', '') === item.path) {
      name = item?.page
    }
  })

  return name
}

export const checkPermission = (action, pageName, parentPageName, isTab = false) => {
  const permissions_data = store.getState().Permissions
  const userRole = store.getState().User?.user?.role?.name
  return true
  if (userRole === 'SuperAdmin' || userRole === 'Admin') {
    return true
  }
  let page_with_action

  // return false
  if (isTab && pageName !== '_grid' && pageName?.replace('_grid', '') !== parentPageName) {
    if (action === 'restore') {
      page_with_action = 'delete_' + pageName + '_restore'
    } else {
      page_with_action = action + '_' + pageName
    }

    return permissions_data?.includes(page_with_action)
  } else {
    if (action === 'restore') {
      page_with_action = 'delete_' + parentPageName + '_restore'
    } else {
      page_with_action = action + '_' + parentPageName
    }
    return permissions_data?.includes(page_with_action)
  }
}

export const checkThemePermission = (type) => {
  const userRole = store.getState().User?.user?.role?.name
  if (userRole === 'SuperAdmin') {
    return true
  }
  const permissions_data = store.getState().Permissions
  if (permissions_data?.includes(type)) {
    return true
  } else {
    return false
  }
}

export const checkAllThemePermission = () => {
  const userRole = store.getState().User?.user?.role?.name
  if (userRole === 'SuperAdmin') {
    return true
  }
  const permissions_data = store.getState().Permissions
  const type = [
    'enable_fixed_header',
    'enable_fixed_sidebar',
    'enable_fixed_footer',
    'enable_header_shadow',
    'header_background_color',
    'upload_logo',
    'font_family',
    'subheader_font_family',
    'body_color',
    'title_area_color',
    'enable_background_image',
    'background_image',
    'slider_title',

    'slider_subtitle',
    'login_title',
    'login_subtitle',
    'signup_title',
    'signup_subtitle',
    'forgot_page_title',
    'forgot_page_subtitle',
    'auth_title_color',
    'auth_subtitle_color',
    'primary_color',
    'enable_page_title_icon',
    'color_scheme',
  ]

  const getPermissionArray = type?.map((item) => checkThemePermission(item))
  if (getPermissionArray?.length > 0) {
    return true
  } else {
    return false
  }
}

// export const checkCMSPermission = (type) => {
//   const permissions_data = store.getState().Permissions
//   const userRole = store.getState().User?.user?.role?.name

//   if (userRole === 'SuperAdmin' || userRole === 'Admin') {
//     return true
//   }
//   if (permissions_data?.includes(type)) {
//     return true
//   } else {
//     return false
//   }
// }

export const getMainGridNameByParentPageName = (parentPageName) => {
  const pages = store.getState().CMS.coreData.page
  let pageData, mainTabName
  Object.keys(pages).map((key) => {
    if (pages[key]?.name === parentPageName) {
      pageData = pages[key]
      pageData?.component_call_buttons?.map((button) => {
        if (button?.component_name === 'hidden_tab') {
          mainTabName = button?.name
        }
      })
    }
  })
  return mainTabName
}

export const getConnectTemplateReduxDataAfterInsert = (
  connectTemplateDataRedux,
  parentPageName,
  pageName,
  source_table,
  currentRow,
  data,
  isChildData
) => {
  let connectTemplateDataReduxToUpdate = {
    ...connectTemplateDataRedux,
  }
  connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

  let newData = {}
  if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
    newData = {
      ...connectTemplateDataRedux[parentPageName]?.[pageName],
    }
  }

  if (currentRow && currentRow?.length > 0 && isChildData) {
    if (currentRow[currentRow?.length - 1]?.id) {
      newData[`${source_table}-${data?.id ? data?.id : currentRow[currentRow?.length - 1]?.id}`] = {
        ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`],
      }
      // ;[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]
      let new_key_to_add = Object.keys(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`]?.[1]?.['data']?.['data'])?.length

      newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][1]['data']['data'] = {
        ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][1]['data']['data'],
      }
      newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][1]['data']['data'][new_key_to_add + 1] = { ...data }
    } else if (currentRow[currentRow?.length - 1]?.temporary_id) {
      newData[`${source_table}-${data?.id ? data?.id : currentRow[currentRow?.length - 1]?.temporary_id}`] = {
        ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`],
      }

      let new_key_to_add = Object.keys(
        newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`]?.[1]?.['data']?.['data']
      )?.length

      newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][1]['data']['data'] = {
        ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][1]['data']['data'],
      }
      newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][1]['data']['data'][new_key_to_add + 1] = { ...data }
    }
  } else {
    let new_key_to_add = Object.keys(newData[1]['data']['data'])?.length
    newData[1]['data']['data'] = {
      ...newData[1]['data']['data'],
    }
    newData[1]['data']['data'][new_key_to_add + 1] = data
  }
  connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  return connectTemplateDataReduxToUpdate
}
export const getConnectTemplateReduxDataAfterInsertItems = (
  connectTemplateDataRedux,
  parentPageName,
  pageName,
  source_table,
  currentRow,
  data,
  isChildData
) => {
  let connectTemplateDataReduxToUpdate = {
    ...connectTemplateDataRedux,
  }
  connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

  let newData = {}
  if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
    newData = {
      ...connectTemplateDataRedux[parentPageName]?.[pageName],
    }
  }
  if (currentRow && currentRow?.length > 0 && isChildData) {
    // newData[`${source_table}-${data?.id ? data?.id : currentRow[currentRow?.length - 1]?.id}`] = {
    //   ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`],
    // }
    // // ;[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]
    // let new_key_to_add = Object.keys(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][1]['data']['data'])?.length
    // newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][1]['data']['data'] = {
    //   ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][1]['data']['data'],
    // }
    // newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][1]['data']['data'][new_key_to_add + 1] = data
  } else {
    let new_key_to_add = Object.keys(newData[1]['data']['data'])?.length
    newData[1]['data']['data'] = {
      ...newData[1]['data']['data'],
    }

    data?.map((item, index) => {
      connectTemplateDataReduxToUpdate[parentPageName][pageName][1]['data']['data'][new_key_to_add + 1 + index] = { ...item }
    })
  }
  connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  return connectTemplateDataReduxToUpdate
}

export const getConnectTemplateReduxDataAfterEditItems = (
  // connectTemplateDataRedux,
  parentPageName,
  pageName,
  source_table,
  currentRow,
  data,
  isChildData
) => {
  const connectTemplateDataRedux = store.getState().CMS.connectTemplateDataRedux
  let connectTemplateDataReduxToUpdate = {
    ...connectTemplateDataRedux,
  }
  connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

  let newData = {}
  if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
    newData = {
      ...connectTemplateDataRedux[parentPageName]?.[pageName],
    }
  }
  if (currentRow && currentRow?.length > 0 && isChildData) {
    // newData[`${source_table}-${data?.id ? data?.id : currentRow[currentRow?.length - 1]?.id}`] = {
    //   ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`],
    // }
    // const connectTemplateDataOfCurrentForm =
    //   connectTemplateDataRedux[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 2]?.id}`]
    // let pageIndex, rowIndex
    // Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
    //   Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
    //     if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === currentRow[currentRow?.length - 1]?.id) {
    //       pageIndex = key
    //       rowIndex = rowKey
    //     }
    //   })
    // })
    // ;[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]
    // newData[`${source_table}-${currentRow[currentRow?.length - 2]?.id}`][pageIndex]['data']['data'] = {
    //   ...newData[`${source_table}-${currentRow[currentRow?.length - 2]?.id}`][pageIndex]['data']['data'],
    // }
    // newData[`${source_table}-${currentRow[currentRow?.length - 2]?.id}`][pageIndex]['data']['data'][rowIndex] = data
  } else {
    const connectTemplateDataOfCurrentForm = connectTemplateDataRedux[parentPageName]?.[pageName]

    data?.map((task) => {
      let pageIndex, rowIndex
      Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
        Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
          if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === task?.id) {
            pageIndex = key
            rowIndex = rowKey
            connectTemplateDataOfCurrentForm[key].data.data[rowKey] = { ...task }
          }
        })
      })

      // if(newData[pageIndex]?.['data']?.['data']){
      //      newData[pageIndex]['data']['data'] = {
      //        ...newData[pageIndex]['data']['data'],
      //      }

      // }
    })
  }
  connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  return connectTemplateDataReduxToUpdate
}
export const getConnectTemplateReduxDataAfterEdit = (
  connectTemplateDataRedux,
  parentPageName,
  pageName,
  source_table,
  currentRow,
  data,
  isChildData
) => {
  let connectTemplateDataReduxToUpdate = {
    ...connectTemplateDataRedux,
  }
  connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

  let newData = {}
  if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
    newData = {
      ...connectTemplateDataRedux[parentPageName]?.[pageName],
    }
  }

  if (currentRow && currentRow?.length > 0 && isChildData) {
    newData[`${source_table}-${data?.id ? data?.id : currentRow[currentRow?.length - 1]?.id || currentRow[currentRow?.length - 1]?.temporary_id}`] = {
      ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id || currentRow[currentRow?.length - 1]?.temporary_id}`],
    }

    const connectTemplateDataOfCurrentForm =
      connectTemplateDataRedux[parentPageName]?.[pageName]?.[
        `${source_table}-${currentRow[currentRow?.length - 2]?.id || currentRow[currentRow?.length - 2]?.temporary_id}`
      ]

    let pageIndex, rowIndex
    Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
      Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
        if (
          (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === currentRow[currentRow?.length - 1]?.id &&
            connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id &&
            currentRow[currentRow?.length - 1]?.id) ||
          (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.temporary_id === currentRow[currentRow?.length - 1]?.temporary_id &&
            connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.temporary_id &&
            currentRow[currentRow?.length - 1]?.temporary_id)
        ) {
          pageIndex = key
          rowIndex = rowKey
        }
      })
    })

    // ;[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]
    newData[`${source_table}-${currentRow[currentRow?.length - 2]?.id || currentRow[currentRow?.length - 2]?.temporary_id}`][pageIndex]['data'][
      'data'
    ] = {
      ...newData[`${source_table}-${currentRow[currentRow?.length - 2]?.id || currentRow[currentRow?.length - 2]?.temporary_id}`][pageIndex]['data'][
        'data'
      ],
    }
    newData[`${source_table}-${currentRow[currentRow?.length - 2]?.id || currentRow[currentRow?.length - 2]?.temporary_id}`][pageIndex]['data'][
      'data'
    ][rowIndex] = data
  } else {
    const connectTemplateDataOfCurrentForm = connectTemplateDataRedux[parentPageName]?.[pageName]

    let pageIndex, rowIndex
    Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
      Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
        if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === currentRow[currentRow?.length - 1]?.id) {
          pageIndex = key
          rowIndex = rowKey
        }
      })
    })
    newData[pageIndex]['data']['data'] = {
      ...newData[pageIndex]['data']['data'],
    }
    newData[pageIndex]['data']['data'][rowIndex] = data
  }
  connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  return connectTemplateDataReduxToUpdate
}

export const getConnectTemplateReduxDataAfterEditByReactId = (
  connectTemplate,
  parentPageName,
  pageName,
  source_table,
  currentRow,
  data,
  isChildData
) => {
  const connectTemplateDataRedux = store.getState().CMS.connectTemplateDataRedux

  let connectTemplateDataReduxToUpdate = {
    ...connectTemplateDataRedux,
  }
  connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

  let newData = {}
  if (connectTemplateDataReduxToUpdate[parentPageName]?.[pageName]) {
    newData = {
      ...connectTemplateDataReduxToUpdate[parentPageName]?.[pageName],
    }
  }

  if (currentRow && currentRow?.length > 0 && isChildData) {
    // newData[`${source_table}-${data?.id ? data?.id : currentRow[currentRow?.length - 1]?.id}`] = {
    //   ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`],
    // }
    const connectTemplateDataOfCurrentForm =
      connectTemplateDataReduxToUpdate[parentPageName]?.[pageName]?.[
        `${source_table}-${currentRow[currentRow?.length - 1]?.id || currentRow[currentRow?.length - 1]?.temporary_id}`
      ]

    let pageIndex, rowIndex
    Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
      Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
        if (
          !connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id &&
          !connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.temporary_id
        )
          return
        if (
          connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === data?.id ||
          connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.temporary_id === data?.temporary_id
        ) {
          pageIndex = key
          rowIndex = rowKey
        }
      })
    })

    // ;[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]
    newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id || currentRow[currentRow?.length - 1]?.temporary_id}`][pageIndex]['data'][
      'data'
    ] = {
      ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id || currentRow[currentRow?.length - 1]?.temporary_id}`][pageIndex]['data'][
        'data'
      ],
    }
    newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id || currentRow[currentRow?.length - 1]?.temporary_id}`][pageIndex]['data'][
      'data'
    ][rowIndex] = data
  } else {
    const connectTemplateDataOfCurrentForm = connectTemplateDataRedux[parentPageName]?.[pageName]

    let pageIndex, rowIndex
    Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
      Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
        if (
          !connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id &&
          !connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.temporary_id
        )
          return
        if (
          connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === data?.id ||
          connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.temporary_id === data?.temporary_id
        ) {
          pageIndex = key
          rowIndex = rowKey
        }
      })
    })
    newData[pageIndex]['data']['data'] = {
      ...newData[pageIndex]['data']['data'],
    }
    newData[pageIndex]['data']['data'][rowIndex] = data
  }
  connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  return connectTemplateDataReduxToUpdate
}
export const getConnectTemplateReduxDataAfterInlineEdit = (
  connectTemplateDataRedux,
  parentPageName,
  pageName,
  source_table,
  currentRow,
  data,
  isChildData,
  gridRelatedData
) => {
  let connectTemplateDataReduxToUpdate = {
    ...connectTemplateDataRedux,
  }
  connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

  let newData = {}
  if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
    newData = {
      ...connectTemplateDataRedux[parentPageName]?.[pageName],
    }
  }
  if (currentRow && currentRow?.length > 0 && isChildData) {
    newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`] = {
      ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`],
    }
    const connectTemplateDataOfCurrentForm =
      connectTemplateDataRedux[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`]

    let pageIndex, rowIndex
    Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
      Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
        if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === data?.id) {
          pageIndex = key
          rowIndex = rowKey
        }
      })
    })

    // ;[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]
    newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageIndex]['data']['data'] = {
      ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageIndex]['data']['data'],
    }
    newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageIndex]['data']['data'][rowIndex] = data
  } else {
    const connectTemplateDataOfCurrentForm = connectTemplateDataRedux[parentPageName]?.[pageName]

    let pageIndex, rowIndex
    Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
      Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
        if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === data?.id) {
          pageIndex = key
          rowIndex = rowKey
        }
      })
    })
    newData[pageIndex]['data']['data'] = {
      ...newData[pageIndex]['data']['data'],
    }
    if (process.env.REACT_APP_PRODUCT_CODE === 'Locker') {
      newData[pageIndex]['data']['data']['reportsEdit'] = [...newData[pageIndex]['data']['data']['reportsEdit'], data]
      if (gridRelatedData?.editedReportsData) {
        gridRelatedData?.setEditedReportsData([...gridRelatedData?.editedReportsData, { ...data, row_id: data?.id, updated_data: JSON.stringify(data) }])
      } else {
        gridRelatedData?.setEditedReportsData([{ ...data, row_id: data?.id }])
      }
    } else {
      newData[pageIndex]['data']['data'][rowIndex] = data
    }
  }
  connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  return connectTemplateDataReduxToUpdate
}

export const getConnectTemplateReduxDataAfterInlineEditByReactId = (
  connectTemplateDataRedux,
  parentPageName,
  pageName,
  source_table,
  currentRow,
  data,
  isChildData
) => {
  let connectTemplateDataReduxToUpdate = {
    ...connectTemplateDataRedux,
  }
  connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

  let newData = {}
  if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
    newData = {
      ...connectTemplateDataRedux[parentPageName]?.[pageName],
    }
  }
  if (currentRow && currentRow?.length > 0 && isChildData) {
    if (!currentRow[currentRow?.length - 1]?.id && currentRow[currentRow?.length - 1]?.temporary_id) {
      newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`] = {
        ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`],
      }
      const connectTemplateDataOfCurrentForm =
        connectTemplateDataRedux[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`]

      let pageIndex, rowIndex
      Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
        Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
          if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.temporary_id === data?.temporary_id) {
            pageIndex = key
            rowIndex = rowKey
          }
        })
      })

      // ;[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]
      newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][pageIndex]['data']['data'] = {
        ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][pageIndex]['data']['data'],
      }
      newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][pageIndex]['data']['data'][rowIndex] = data
    } else {
      newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`] = {
        ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`],
      }
      const connectTemplateDataOfCurrentForm =
        connectTemplateDataRedux[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`]

      let pageIndex, rowIndex
      Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
        Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
          if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.temporary_id === data?.temporary_id) {
            pageIndex = key
            rowIndex = rowKey
          }
        })
      })

      // ;[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]
      newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageIndex]['data']['data'] = {
        ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageIndex]['data']['data'],
      }
      newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageIndex]['data']['data'][rowIndex] = data
    }
  } else {
    const connectTemplateDataOfCurrentForm = connectTemplateDataRedux[parentPageName]?.[pageName]

    let pageIndex, rowIndex
    Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
      Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
        if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.temporary_id === data?.temporary_id) {
          pageIndex = key
          rowIndex = rowKey
        }
      })
    })
    newData[pageIndex]['data']['data'] = {
      ...newData[pageIndex]['data']['data'],
    }
    newData[pageIndex]['data']['data'][rowIndex] = data
  }
  connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  return connectTemplateDataReduxToUpdate
}
export const getConnectTemplateReduxDataAfterDelete = (
  connectTemplateDataRedux,
  parentPageName,
  pageName,
  source_table,
  currentRow,
  isChildTable,
  ids
) => {
  let connectTemplateDataReduxToUpdate = {
    ...connectTemplateDataRedux,
  }
  connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

  let newData = {}
  if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
    newData = {
      ...connectTemplateDataRedux[parentPageName]?.[pageName],
    }
  }
  if (isChildTable) {
    newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`] = {
      ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`],
    }
    const connectTemplateDataOfCurrentForm =
      connectTemplateDataRedux[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 2]?.id}`]

    Object.keys(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`])?.map((pageKey) => {
      if (newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageKey]?.data?.data) {
        Object.keys(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageKey]?.data?.data)?.map((rowKey) => {
          if (ids.includes(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageKey]?.data?.data[rowKey]?.id)) {
            delete newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageKey]?.data?.data[rowKey]
          }
        })
      }
    })

    connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  } else {
    Object.keys(newData)?.map((pageKey) => {
      if (newData[pageKey]?.data?.data) {
        Object.keys(newData[pageKey]?.data?.data)?.map((rowKey) => {
          if (ids.includes(newData[pageKey]?.data?.data[rowKey]?.id)) {
            delete newData[pageKey]?.data?.data[rowKey]
          }
        })
      }
    })

    connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  }
  return connectTemplateDataReduxToUpdate
}
export const getConnectTemplateReduxDataAfterDeleteClientRow = (
  connectTemplateDataRedux,
  parentPageName,
  pageName,
  source_table,
  currentRow,
  isChildTable,
  ids
) => {
  let connectTemplateDataReduxToUpdate = {
    ...connectTemplateDataRedux,
  }
  connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

  let newData = {}
  if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
    newData = {
      ...connectTemplateDataRedux[parentPageName]?.[pageName],
    }
  }
  if (isChildTable) {
    newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`] = {
      ...newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`],
    }
    const connectTemplateDataOfCurrentForm =
      connectTemplateDataRedux[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 2]?.id}`]

    if (!currentRow[currentRow?.length - 1]?.id && currentRow[currentRow?.length - 1]?.temporary_id) {
      Object.keys(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`])?.map((pageKey) => {
        if (newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][pageKey]?.data?.data) {
          Object.keys(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][pageKey]?.data?.data)?.map((rowKey) => {
            if (
              ids.includes(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][pageKey]?.data?.data[rowKey]?.temporary_id)
            ) {
              delete newData[`${source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][pageKey]?.data?.data[rowKey]
            }
          })
        }
      })
    } else {
      Object.keys(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`])?.map((pageKey) => {
        if (newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageKey]?.data?.data) {
          Object.keys(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageKey]?.data?.data)?.map((rowKey) => {
            if (ids.includes(newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageKey]?.data?.data[rowKey]?.temporary_id)) {
              delete newData[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`][pageKey]?.data?.data[rowKey]
            }
          })
        }
      })
    }
    connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  } else {
    Object.keys(newData)?.map((pageKey) => {
      if (newData[pageKey]?.data?.data) {
        Object.keys(newData[pageKey]?.data?.data)?.map((rowKey) => {
          if (ids.includes(newData[pageKey]?.data?.data[rowKey]?.temporary_id)) {
            delete newData[pageKey]?.data?.data[rowKey]
          }
        })
      }
    })

    connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  }
  return connectTemplateDataReduxToUpdate
}

export const isDirectUpdateConnectTemplate = (source_table, forms, isModel) => {
  let isInstantUpdate
  if (isModel) {
    return true
  }

  forms?.map((form) => {
    if (form?.instant_update) {
      isInstantUpdate = true
    }
    if (form?.grid_tab_table === 'uploads' || form?.grid_tab_method === 'uploads' || form?.grid_tab_table === 'messages') {
      isInstantUpdate = true
    }
  })
  const list = ['users']
  if (isInstantUpdate) {
    return true
  } else {
    return false
  }
}

export const getDeletedIdsToUpdateInRedux = (deletedRowIdRedux, parentPageName, pageName, source_table, currentRow, isChildTable, ids) => {
  let deletedRowIdReduxToUpdate = {
    ...deletedRowIdRedux,
  }
  deletedRowIdReduxToUpdate[parentPageName] = { ...deletedRowIdReduxToUpdate[parentPageName] }

  let newData = []
  if (isChildTable) {
    deletedRowIdReduxToUpdate[parentPageName][pageName] = { ...deletedRowIdReduxToUpdate[parentPageName]?.[pageName] }
    if (deletedRowIdReduxToUpdate[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`]) {
      deletedRowIdReduxToUpdate[parentPageName][pageName][`${source_table}-${currentRow[currentRow?.length - 1]?.id}`] = [
        ...deletedRowIdReduxToUpdate[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`],
        ...ids,
      ]
    } else {
      deletedRowIdReduxToUpdate[parentPageName][pageName][`${source_table}-${currentRow[currentRow?.length - 1]?.id}`] = [...ids]
    }
  } else {
    if (deletedRowIdReduxToUpdate[parentPageName]?.[pageName]) {
      newData = [...deletedRowIdReduxToUpdate[parentPageName]?.[pageName], ...ids]
    } else {
      newData = [...ids]
    }
    deletedRowIdReduxToUpdate[parentPageName][pageName] = newData
  }

  return deletedRowIdReduxToUpdate
}

export const getFileteredRowAfterRemovingDeletedRow = (data, parentPageName, pageName, source_table, isChild) => {
  const deletedRowIdRedux = store.getState().CMS.deletedRowIdRedux
  const currentRow = store.getState().CMS.currentRow
  let newData = []

  if (isChild) {
    if (deletedRowIdRedux[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`]) {
      const deletedIdsArray = deletedRowIdRedux[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`]
      data?.map((item) => {
        if (!deletedIdsArray?.includes(item?.id)) {
          newData.push(item)
        }
      })
    } else {
      newData = [...data]
    }
  } else {
    if (deletedRowIdRedux[parentPageName]?.[pageName]) {
      const deletedIdsArray = deletedRowIdRedux[parentPageName]?.[pageName]
      data?.map((item) => {
        if (!deletedIdsArray?.includes(item?.id)) {
          newData.push(item)
        }
      })
    } else {
      newData = [...data]
    }
  }

  return newData
}

export const clearDeletedIdsRedux = (parentPageName, pageName, source_table, isChild) => {
  const deletedRowIdRedux = store.getState().CMS.deletedRowIdRedux
  const deletedRowIdReduxToUpdate = { ...deletedRowIdRedux }
  const currentRow = store.getState().CMS.currentRow

  if (isChild) {
    if (deletedRowIdReduxToUpdate[parentPageName]?.[pageName]?.[`${source_table}-${currentRow[currentRow?.length - 1]?.id}`]) {
      deletedRowIdReduxToUpdate[parentPageName][pageName][`${source_table}-${currentRow[currentRow?.length - 1]?.id}`] = []
    }
  } else {
    if (deletedRowIdRedux[parentPageName]?.[pageName]) {
      deletedRowIdRedux[parentPageName][pageName] = []
    }
  }

  store.dispatch(setDeletedRowIdInRedux(deletedRowIdRedux))
}

export const getGridFormByGridId = (gridId) => {
  const gridForm = store.getState().CMS.gridForm
  if (gridForm[gridId]) {
    return [...gridForm[gridId]]
  } else {
    return null
  }
}

export const updateGridFormInReduxByGridId = (form, gridId) => {
  const gridForm = store.getState().CMS.gridForm
  const gridFormToUpdate = { ...gridForm }
  gridFormToUpdate[gridId] = form
  store.dispatch(setGridFormInRedux(gridFormToUpdate))
}

export const addChangedFieldIdInReduxByFormName = (formName, currentRowId, field_id) => {
  const pageName = window.location.hash.split('/')[1]?.split('-')[0]
  const changedField = store.getState().CMS?.changedField

  const changedFieldInReduxToUpdate = { ...changedField }
  changedFieldInReduxToUpdate[pageName] = changedFieldInReduxToUpdate[pageName] ? { ...changedFieldInReduxToUpdate[pageName] } : {}
  changedFieldInReduxToUpdate[pageName][currentRowId] = changedFieldInReduxToUpdate[pageName]?.[currentRowId]
    ? { ...changedFieldInReduxToUpdate[pageName]?.[currentRowId] }
    : {}

  changedFieldInReduxToUpdate[pageName][currentRowId][formName] = changedFieldInReduxToUpdate[pageName]?.[currentRowId]?.[formName]
    ? [...changedFieldInReduxToUpdate[pageName]?.[currentRowId]?.[formName]]
    : []

  if (!changedFieldInReduxToUpdate[pageName]?.[currentRowId]?.[formName]?.includes(field_id)) {
    changedFieldInReduxToUpdate[pageName][currentRowId][formName] = [...changedFieldInReduxToUpdate[pageName]?.[currentRowId]?.[formName], field_id]
  }
  store.dispatch(addChangedFieldIdByFormName(changedFieldInReduxToUpdate))
}

export const removeChangedFieldIdInReduxByFormName = (formName, currentRowId, field_id) => {
  const pageName = window.location.hash.split('/')[1]?.split('-')[0]
  const changedField = store.getState().CMS?.changedField
  const changedFieldInReduxToUpdate = { ...changedField }
  changedFieldInReduxToUpdate[pageName] = changedFieldInReduxToUpdate[pageName] ? { ...changedFieldInReduxToUpdate[pageName] } : {}
  changedFieldInReduxToUpdate[pageName][currentRowId] = changedFieldInReduxToUpdate[pageName]?.[currentRowId]
    ? { ...changedFieldInReduxToUpdate[pageName][currentRowId] }
    : {}
  changedFieldInReduxToUpdate[pageName][currentRowId][formName] = changedFieldInReduxToUpdate[pageName]?.[currentRowId]?.[formName]
    ? [...changedFieldInReduxToUpdate[pageName][currentRowId][formName]]
    : []
  if (changedFieldInReduxToUpdate[pageName][currentRowId][formName]?.includes(field_id)) {
    const index = changedFieldInReduxToUpdate[pageName][currentRowId][formName].indexOf(field_id)
    changedFieldInReduxToUpdate[pageName][currentRowId][formName].splice(index, 1)
  }
  store.dispatch(addChangedFieldIdByFormName(changedFieldInReduxToUpdate))
}

export const deleteChangedFieldInReduxByCurrentRowId = (currentRowId) => {
  const pageName = window.location.hash.split('/')[1]?.split('-')[0]
  const changedField = store.getState().CMS?.changedField
  const changedFieldInReduxToUpdate = { ...changedField }
  changedFieldInReduxToUpdate[pageName] = { ...changedFieldInReduxToUpdate[pageName] }
  if (changedFieldInReduxToUpdate[pageName][currentRowId]) {
    delete changedFieldInReduxToUpdate[pageName][currentRowId]
  }
  store.dispatch(addChangedFieldIdByFormName(changedFieldInReduxToUpdate))
}

export const checkFieldExistInSyncToUpdate = (formName, currentRowId, field_id) => {
  const pageName = window.location.hash.split('/')[1]?.split('-')[0]
  const changedField = store.getState().CMS?.submittedChangedField

  if (changedField[pageName]?.[currentRowId]?.[formName]?.includes(field_id)) {
    return true
  } else {
    return false
  }
}

export const updateSyncRowId = (parentPageName, pageName, ids) => {
  const updatedRowSyncId = store.getState().CMS.updatedRowSyncId
  const newUpdatedRowSyncId = { ...updatedRowSyncId }
  newUpdatedRowSyncId[parentPageName] = newUpdatedRowSyncId[parentPageName] ? { ...newUpdatedRowSyncId[parentPageName] } : {}
  newUpdatedRowSyncId[parentPageName][pageName] = newUpdatedRowSyncId[parentPageName][pageName]
    ? [...newUpdatedRowSyncId[parentPageName][pageName], ...ids]
    : [...ids]
  store.dispatch(updateRowSyncIdInRedux(newUpdatedRowSyncId))
}

export const getSyncRowIdFromRedux = (parentPageName, pageName) => {
  const updatedRowSyncId = store.getState().CMS.updatedRowSyncId
  if (updatedRowSyncId?.[parentPageName]?.[pageName]) {
    return updatedRowSyncId?.[parentPageName]?.[pageName]
  } else {
    return []
  }
}

export const setCompletedQuestionnaireRowByGridId = (gridId, rowId) => {
  const ids = store.getState().CMS.completedQuestionnaireRow
  const new_ids = { ...ids }
  new_ids[gridId] = new_ids[gridId] ? [...new_ids[gridId], rowId] : [rowId]
  store.dispatch(setCompletedQuestionnaireRow(new_ids))
}

export const clearCompletedQuestionnaireByGridId = (gridId) => {
  const ids = store.getState().CMS.completedQuestionnaireRow
  const new_ids = { ...ids }
  delete new_ids[gridId]
  store.dispatch(setCompletedQuestionnaireRow(new_ids))
}

export const checkQuestionnaireCompleted = (gridId, rowId) => {
  const ids = store.getState().CMS.completedQuestionnaireRow
  const new_ids = { ...ids }

  if (new_ids?.[gridId]?.includes(rowId)) {
    return true
  } else {
    return false
  }
}

export const storeUserSecurityOverviewDropdown = (field_id, value) => {
  const currentRow = store.getState().CMS.currentRow
  const userSecurityOverviewDropdown = store.getState().CMS.userSecurityOverviewDropdown
  if (currentRow[currentRow?.length - 1]?.id) {
    let updatedUserSecurityOverviewDropdown = { ...userSecurityOverviewDropdown }
    updatedUserSecurityOverviewDropdown[currentRow[currentRow?.length - 1]?.id] = {
      ...updatedUserSecurityOverviewDropdown[currentRow[currentRow?.length - 1]?.id],
    }
    updatedUserSecurityOverviewDropdown[currentRow[currentRow?.length - 1]?.id][field_id] = value
    store.dispatch(setUserSecurityOverviewDropdown(updatedUserSecurityOverviewDropdown))
  }
}
export const storeUserSecurityOverviewDropdownPnSubmit = (data) => {
  const currentRow = store.getState().CMS.currentRow
  const userSecurityOverviewDropdown = store.getState().CMS.userSecurityOverviewDropdown
  if (currentRow[currentRow?.length - 1]?.id) {
    let updatedUserSecurityOverviewDropdown = { ...userSecurityOverviewDropdown }
    updatedUserSecurityOverviewDropdown[currentRow[currentRow?.length - 1]?.id] = {
      ...updatedUserSecurityOverviewDropdown[currentRow[currentRow?.length - 1]?.id],
    }
    updatedUserSecurityOverviewDropdown[currentRow[currentRow?.length - 1]?.id] = { ...data }
    store.dispatch(setUserSecurityOverviewDropdown(updatedUserSecurityOverviewDropdown))
  }
}

export const getUserSecurityOverviewDropdown = () => {
  const currentRow = store.getState().CMS.currentRow
  const userSecurityOverviewDropdown = store.getState().CMS.userSecurityOverviewDropdown
  if (userSecurityOverviewDropdown && currentRow && currentRow?.length > 0) {
    return userSecurityOverviewDropdown[currentRow[currentRow?.length - 1]?.id]
  }
}

export const setPagePermissionDataByUserIdInRedux = (data, parentPageName, currentRowId) => {
  const permissionPageDataByUserId = store.getState().CMS.permissionPageDataByUserId
  let permissionPageDataByUserIdRedux = { ...permissionPageDataByUserId }
  permissionPageDataByUserIdRedux[parentPageName] = { ...permissionPageDataByUserIdRedux[parentPageName] }
  if (parentPageName && currentRowId) {
    permissionPageDataByUserIdRedux[parentPageName][currentRowId] = { ...permissionPageDataByUserIdRedux[parentPageName][currentRowId] }

    permissionPageDataByUserIdRedux[parentPageName][currentRowId]['data'] = data
    permissionPageDataByUserIdRedux[parentPageName][currentRowId]['updated_at'] = new Date()
  }

  if (data) store.dispatch(setPagePermissionDataByUserId(permissionPageDataByUserIdRedux))
}

export const getPagePermissionDataByUserId = (parentPageName, id) => {
  const permissionPageDataByUserId = store.getState().CMS.permissionPageDataByUserId
  if (permissionPageDataByUserId?.[parentPageName]?.[id]) {
    return permissionPageDataByUserId[parentPageName][id]['data']
  }
}
export const getPagePermissionUpdatedTimeByUserId = (parentPageName, id) => {
  const permissionPageDataByUserId = store.getState().CMS.permissionPageDataByUserId
  if (permissionPageDataByUserId?.[parentPageName]?.[id]) {
    return permissionPageDataByUserId[parentPageName][id]['updated_at']
  }
}

export const clearPagePermissionData = () => {
  store.dispatch(setPagePermissionDataByUserId({}))
}

export const getButtonActionByIcon = (icon) => {
  if (icon?.includes('export')) {
    return 'export'
  } else if (icon?.includes('recycle')) {
    return 'trash'
  } else if (icon?.includes('fa-plus')) {
    return 'add'
  } else if (icon?.includes('trash-restore')) {
    return 'restore'
  } else if (icon?.includes('pe-7s-trash')) {
    return 'delete'
  } else if (icon?.includes('edit')) {
    return 'edit'
  }
}

export const fetchPermissions = () => {
  request('permissions').then((response) => {
    if (response?.data) {
      store.dispatch(setPermissions(response.data))
      store.dispatch(setPermissionUpdatedTime())
    }
  })
}

export const setExpandTableDataByFilterInRedux = (pageName, filterBy, id, data) => {
  //used in ar time by page
  const expandTableDataByFilter = store.getState().CMS.expandTableDataByFilter
  const expandTableDataByFilterRedux = {
    ...expandTableDataByFilter,
  }
  expandTableDataByFilterRedux[pageName] = {
    ...expandTableDataByFilterRedux[pageName],
  }
  expandTableDataByFilterRedux[pageName][filterBy] = {
    ...expandTableDataByFilterRedux[pageName][filterBy],
  }
  expandTableDataByFilterRedux[pageName][filterBy][id] = data
  store.dispatch(setExpandTableDataByFilter(expandTableDataByFilterRedux))
}

export const getExpandTableDataByFilter = (pageName, filterBy, id) => {
  const expandTableDataByFilter = store.getState().CMS.expandTableDataByFilter

  if (expandTableDataByFilter?.[pageName]?.[filterBy]?.[id]) {
    return [...expandTableDataByFilter?.[pageName]?.[filterBy]?.[id]]
  } else {
    return
  }
}

export function getDatesInRange(startDate = new Date(), endDate = new Date()) {
  const date = new Date(startDate.getTime())

  const dates = []

  while (date <= endDate) {
    dates.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return dates
}

export function diffMonthsBetweenDates(from, to) {
  var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  var arr = []
  var datFrom = new Date(from)
  var datTo = new Date(to)
  var fromYear = datFrom.getFullYear()
  var toYear = datTo.getFullYear()
  var diffYear = 12 * (toYear - fromYear) + datTo.getMonth()

  for (var i = datFrom.getMonth(); i <= diffYear; i++) {
    arr.push(monthNames[i % 12] + ' ' + Math.floor(fromYear + i / 12))
  }

  return arr
}

export const checkIsDate = (inputValue) => {
  if (
    inputValue === undefined ||
    !inputValue ||
    inputValue === '' ||
    inputValue === '0000-00-00 00:00:00' ||
    inputValue === '0000-00-00' ||
    isNaN(Date.parse(inputValue))
  ) {
    return false
  } else {
    return true
  }
}
export const trimNameForId = (name) => {
  if (!name) return 'unknown_name'
  return name?.replaceAll('(', '_')?.replaceAll(')', '_')?.replaceAll('.', '')
}
export function diffMinutesBetweenDates(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= 60
  return Math.abs(Math.round(diff))
}

export const createFieldByFieldIdNtype = (id, type, action) => {
  const field = {
    component_type: 'fields',
    component_name: type,
    name: id,
    is_layout: null,
    row_index: null,
    block_index: null,
    col: 6,
    title: tidyName(id),
    section_rowspan: null,
    field_setting: {
      import: true,
      export: true,
      col: 6,
      field: action?.field || 'input',
    },
    type: type,
    cms_value: null,
    permission: 'no_permission',
    field_id: id,
    cms_key: '',
    cms_key_tooltip: null,
    cms_key_place_holder: null,
    cms_tooltip: null,
    selected_value: null,
    disabled: false,
    hidden: false,
    hide_from_grid: false,
    background_color: false,
    component_call_validation: null,
    component_call_actions: null,
    component_call_fields: null,
    format: {
      size: 12,
    },
    ...action,
  }

  return field
}

export const getFieldTypeByFieldId = (field_id, source_table) => {
  const grid_selector = store.getState().CMS.coreData?.grid_selector
  const field_data = grid_selector?.[source_table]?.columns?.find(
    (col) => col?.name === field_id || col?.field_id === field_id || col?.title === field_id
  )

  return field_data?.field || field_data?.type
}

export const removeInsertDataQueueByReactId = (id, source_table, parentTable, currentRow) => {
  const queues = store.getState().Queue.queues
  const newQueues = []

  if (parentTable && currentRow?.length > 0) {
    queues?.forEach((queue) => {
      if (queue.url === 'insert-repeating-grid-data') {
        if (queue.source_table === parentTable) {
          if (currentRow?.[currentRow?.length - 1]?.temporary_id && !currentRow?.[currentRow?.length - 1]?.id) {
            const newRowToUpdate = []
            queue.payload?.data?.data?.[parentTable]?.forEach((row) => {
              if (row?.temporary_id === currentRow?.[currentRow?.length - 1]?.temporary_id) {
                const newDataToUpdate = []
                row?.[source_table]?.map((item) => {
                  if (item?.temporary_id !== id) {
                    newDataToUpdate?.push(item)
                  }
                })
                row[source_table] = newDataToUpdate
                newRowToUpdate?.push(row)

                // no code because reactId may be available and may be key itself is not there
              } else {
                newRowToUpdate?.push(row)
              }
            })

            queue.payload.data.data[parentTable] = newRowToUpdate
            newQueues?.push({ ...queue })
          }
        } else if (queue.source_table === source_table) {
          const formDataArray = queue.payload?.data?.data?.[source_table]
          const newFormDataArray = []
          formDataArray?.forEach((item) => {
            if (item?.temporary_id === id) {
            } else {
              newFormDataArray?.push(item)
            }
          })
          if (newFormDataArray?.length > 0) {
            queue.payload.data.data[source_table] = newFormDataArray
            newQueues?.push({ ...queue })
          }
        } else {
          newQueues?.push({ ...queue })
        }
      } else {
        newQueues?.push({ ...queue })
      }
    })
  } else {
    queues?.forEach((queue) => {
      if (queue.url === 'insert-repeating-grid-data') {
        if (queue.source_table === source_table) {
          const formDataArray = queue.payload?.data?.data?.[source_table]
          const newFormDataArray = []
          formDataArray?.forEach((item) => {
            if (item?.temporary_id === id) {
            } else {
              newFormDataArray?.push(item)
            }
          })
          if (newFormDataArray?.length > 0) {
            queue.payload.data.data[source_table] = newFormDataArray
            newQueues?.push({ ...queue })
          }
        } else {
          newQueues?.push({ ...queue })
        }
      } else {
        newQueues?.push({ ...queue })
      }
    })
  }

  store.dispatch(setQueueAfterCRUDops(newQueues))
}

export const insertChildTableAddedDataInSync = (id, main_source_table, source_table, data) => {
  const queues = store.getState().Queue.queues
  const newQueues = []
  queues?.forEach((queue) => {
    if (queue.url === 'insert-repeating-grid-data') {
      if (queue.source_table === main_source_table) {
        const newDataToUpdate = []
        queue.payload?.data?.data?.[main_source_table]?.forEach((item) => {
          if (item?.temporary_id === id) {
            item[source_table] = item[source_table] ? [...item[source_table], { ...data }] : [{ ...data }]
            newDataToUpdate?.push({ ...item })
          } else {
            newDataToUpdate?.push({ ...item })
          }
        })
        newQueues?.push({ ...queue })
      } else {
        newQueues?.push({ ...queue })
      }
    } else {
      newQueues?.push({ ...queue })
    }
  })

  store.dispatch(setQueueAfterCRUDops(newQueues))
}
export const updateChildTableAddedDataInSync = (id, main_source_table, source_table, data) => {
  const queues = store.getState().Queue.queues
  const newQueues = []

  queues?.forEach((queue) => {
    if (queue.url === 'insert-repeating-grid-data') {
      if (queue.source_table === main_source_table) {
        const newDataToUpdate = []
        queue.payload?.data?.data?.[main_source_table]?.forEach((item) => {
          if (item?.temporary_id === id) {
            let newChildData = []
            item[source_table]?.map((itemData) => {
              if (itemData?.temporary_id === data?.temporary_id) {
                newChildData?.push(data)
              } else {
                newChildData?.push(itemData)
              }
            })

            item[source_table] = newChildData
            newDataToUpdate?.push({ ...item })
          } else {
            newDataToUpdate?.push({ ...item })
          }
        })
        newQueues?.push({ ...queue })
      } else {
        newQueues?.push({ ...queue })
      }
    } else {
      newQueues?.push({ ...queue })
    }
  })

  store.dispatch(setQueueAfterCRUDops(newQueues))
}

export const updateSyncForInlineUpdateByReactId = (id, source_table, parentTable, currentRow, field_id, value) => {
  const queues = store.getState().Queue.queues
  const newQueues = []

  if (parentTable && currentRow?.length > 0) {
    queues?.forEach((queue) => {
      if (queue.url === 'insert-repeating-grid-data') {
        if (queue.source_table === parentTable) {
          const dataToUpdate = []
          queue.payload?.data?.data?.[parentTable]?.forEach((item) => {
            if (item?.temporary_id === currentRow?.[currentRow?.length - 1]?.temporary_id) {
              const newDataToUpdate = []
              item?.[source_table]?.map((item) => {
                if (item?.temporary_id !== id) {
                  newDataToUpdate?.push(item)
                } else {
                  const newData = { ...item }
                  newData[field_id] = value

                  newDataToUpdate.push(newData)
                }
              })

              item[source_table] = newDataToUpdate
              dataToUpdate?.push(item)

              // no code because reactId may be available and may be key itself is not there
            } else {
              dataToUpdate?.push(item)
            }
          })
          queue.payload.data.data[parentTable] = dataToUpdate
          newQueues?.push({ ...queue })
        } else if (queue.source_table === source_table) {
          const dataToUpdate = []
          queue.payload?.data?.data?.[source_table]?.forEach((item) => {
            if (item?.temporary_id === id) {
              item[field_id] = value
              dataToUpdate?.push(item)
            } else {
              dataToUpdate?.push(item)
            }
          })

          newQueues?.push({ ...queue })
        } else {
          newQueues?.push({ ...queue })
        }
      } else {
        newQueues?.push({ ...queue })
      }
    })
  } else {
    queues?.forEach((queue) => {
      if (queue.url === 'insert-repeating-grid-data') {
        if (queue.source_table === source_table) {
          const dataToUpdate = []
          queue.payload?.data?.data?.[source_table]?.forEach((item) => {
            if (item?.temporary_id === id) {
              item[field_id] = value
              dataToUpdate?.push(item)
            } else {
              dataToUpdate?.push(item)
            }
          })

          newQueues?.push({ ...queue })
        } else {
          newQueues?.push({ ...queue })
        }
      } else {
        newQueues?.push({ ...queue })
      }
    })
  }

  store.dispatch(setQueueAfterCRUDops(newQueues))
}

export const updateSyncForFormUpdateByReactId = (id, main_source_table, source_table, values) => {
  const queues = store.getState().Queue.queues
  const newQueues = []

  queues?.forEach((queue) => {
    if (queue.url === 'insert-repeating-grid-data') {
      if (queue.source_table === main_source_table) {
        const dataToUpdate = []
        queue.payload?.data?.data?.[main_source_table]?.forEach((item) => {
          if (item?.temporary_id === id) {
            if (main_source_table === source_table) {
              item = {
                ...item,
                ...values,
              }
            }
            dataToUpdate?.push(item)
          } else {
            dataToUpdate?.push(item)
          }
        })
        queue.payload.data.data[main_source_table] = dataToUpdate
        newQueues?.push({ ...queue })
      } else {
        newQueues?.push({ ...queue })
      }
    } else {
      newQueues?.push({ ...queue })
    }
  })

  store.dispatch(setQueueAfterCRUDops(newQueues))
}

export const updateSaveDefaultFormData = (id, source_table, form_name, queue) => {
  const queues = store.getState().Queue.queues
  const newQueues = []
  let isSameQueueFound = false
  queues?.forEach((q) => {
    if (
      q.url === 'save-default-form-data' &&
      q?.payload?.data?.form_name === form_name &&
      q?.payload?.data?.source_table === source_table &&
      q?.payload?.data?.values?.id === id
    ) {
      newQueues?.push({ ...queue })
      isSameQueueFound = true
    } else {
      newQueues?.push({ ...q })
    }
  })

  if (!isSameQueueFound) {
    newQueues?.push({ ...queue })
  }

  store.dispatch(setQueueAfterCRUDops(newQueues))
}

export const callInsertRepeatingGridData = (
  submittedFormData,
  source_table,
  addDataDetail,
  isModel,
  domain_id,
  parent_table,
  parent_table_row_id,
  parent_table_temporary_id
) => {
  const queues = store.getState().Queue.queues
  const newQueues = []
  let updatedInExistingQueue

  queues?.forEach((queue) => {
    if (queue.url === 'insert-repeating-grid-data') {
      if (queue.source_table === source_table && (!parent_table_row_id || queue?.parent_table_row_id === parent_table_row_id)) {
        updatedInExistingQueue = true
        let updatedQueue = { ...queue }
        let data = []
        let isUpdatingData
        updatedQueue?.payload?.data?.data?.[source_table]?.map((item) => {
          if (
            (item?.id === submittedFormData[source_table]?.id && item?.id && submittedFormData[source_table]?.id) ||
            (item?.temporary_id === submittedFormData[source_table]?.temporary_id && item?.temporary_id)
          ) {
            isUpdatingData = true
            data?.push({
              ...item,
              ...submittedFormData[source_table],
            })
          } else {
            data?.push(item)
          }
        })
        if (!isUpdatingData) {
          data?.push({
            ...submittedFormData[source_table],
          })
        }

        updatedQueue.payload.data.data[source_table] = data

        newQueues?.push({ ...updatedQueue })
      } else {
        newQueues?.push({ ...queue })
      }
    } else {
      newQueues?.push({ ...queue })
    }
  })

  if (!updatedInExistingQueue) {
    let formData = {}
    formData[source_table] = [submittedFormData[source_table]]
    newQueues?.push({
      url: 'insert-repeating-grid-data',
      payload: {
        data: {
          data: formData,
          is_nested: true,
          parent_table,
          parent_table_row_id,
          domain_id,
          clause_data: isModel && addDataDetail?.tableGrid?.main_grid?.where_clause,
        },
      },
      parent_table,
      parent_table_row_id,
      source_table,
    })
  }

  store.dispatch(setQueueAfterCRUDops(newQueues))
}

const compareTwoValueByFieldType = (val1, val2, type) => {
  switch (type) {
    case 'date':
      const a = new Date(val1)
      const b = new Date(val2)
      a.setHours(0, 0, 0, 0)
      b.setHours(0, 0, 0, 0)

      if (a > b) {
        return false
      } else {
        return true
      }
    case 'time':
      const a_h = val1?.split(':')?.[0]
      const a_m = val1?.split(':')?.[1]
      const b_h = val2?.split(':')?.[0]
      const b_m = val2?.split(':')?.[1]

      const a_value = parseInt(a_h) * 60 * 60 + parseInt(a_m) * 60
      const b_value = parseInt(b_h) * 60 * 60 + parseInt(b_m) * 60

      if (a_value > b_value) {
        return false
      } else {
        return true
      }

    default:
      return true
  }
}

const checkUniqueInRange = (start_range, end_range, start_range_value, end_range_value, formData, queueData, range) => {
  if (!start_range || !start_range_value || !end_range || !end_range_value) return true
  const start_range_date = new Date(start_range)
  const end_range_date = new Date(end_range)
  const start_range_value_date = new Date(start_range_value)
  const end_range_value_date = new Date(end_range_value)

  //check date exist or not in queue
  let isDateFoundInRange = false
  if (
    (start_range_value_date >= start_range_date && start_range_value_date <= end_range_date) ||
    (end_range_value_date >= start_range_date && end_range_value_date <= end_range_date) ||
    (start_range_date >= start_range_value_date && end_range_date <= end_range_value_date)
  ) {
    isDateFoundInRange = true
  }

  //check day exist in queue or not
  let isDayFound = false

  if (range?.has_days) {
    const queueDays = checkSelectedDay(queueData)
    const formDays = checkSelectedDay(formData)

    formDays?.forEach((day) => {
      if (queueDays?.includes(day)) {
        isDayFound = true
      }
    })
  }

  //check time exist or not in queue
  let isTimeFoundInRange = false

  if (range?.has_time && range?.has_time?.start_range && range?.has_time?.end_range) {
    const queue_h_start = queueData[range?.has_time?.start_range]?.split(':')?.[0]
    const queue_m_start = queueData[range?.has_time?.start_range]?.split(':')?.[1]
    const form_h_start = queueData[range?.has_time?.start_range]?.split(':')?.[0]
    const form_m_start = queueData[range?.has_time?.start_range]?.split(':')?.[1]

    const queue_h_end = queueData[range?.has_time?.end_range]?.split(':')?.[0]
    const queue_m_end = queueData[range?.has_time?.end_range]?.split(':')?.[1]
    const form_h_end = queueData[range?.has_time?.end_range]?.split(':')?.[0]
    const form_m_end = queueData[range?.has_time?.end_range]?.split(':')?.[1]

    const queue_value_start = parseInt(queue_h_start) * 60 * 60 + parseInt(queue_m_start) * 60
    const queue_value_end = parseInt(queue_h_end) * 60 * 60 + parseInt(queue_m_end) * 60
    const form_value_start = parseInt(form_h_start) * 60 * 60 + parseInt(form_m_start) * 60
    const form_value_end = parseInt(form_h_end) * 60 * 60 + parseInt(form_m_end) * 60

    if (
      (form_value_start <= queue_value_start && form_value_end >= queue_value_end) ||
      (form_value_start >= queue_value_start && form_value_start <= queue_value_end) ||
      (form_value_end >= queue_value_start && form_value_end <= queue_value_end)
    ) {
      isTimeFoundInRange = true
    }
  }

  if (isDateFoundInRange && isTimeFoundInRange && isDayFound) {
    return false
  }
  return true
}

export const dataValidationForField = async (formData, validation, parent_table_row_id, parent_table, source_table, field_id, type, id) => {
  const queues = store.getState().Queue.queues

  if (validation?.component_name === 'unique_in_range') {
    let payload = {
      parent_table_row_id,
      parent_table,
      source_table,
      id,
      data: {},
      component_name: 'unique_in_range',
    }

    let selected_range

    validation?.range?.map((range) => {
      if (range['start_range'] === field_id || range['end_range'] === field_id) {
        selected_range = range
      }
    })

    payload = {
      ...payload,
      range: selected_range,
    }

    if (!selected_range || (selected_range['start_range'] !== field_id && selected_range['end_range'] !== field_id)) {
      return {
        validation: true,
        message: '',
      }
    }

    const field_array = [selected_range['start_range'], selected_range['end_range']]
    let rangeData = {}

    rangeData[selected_range['start_range']] = formData[selected_range['start_range']]
    rangeData[selected_range['end_range']] = formData[selected_range['end_range']]
    if (!formData[selected_range['end_range']] || !formData[selected_range['start_range']]) {
      return {
        validation: true,
        message: '',
      }
    }
    let uniqueValidationFailedFromClient = false

    queues?.forEach((queue) => {
      if (queue.source_table === source_table) {
        queue?.payload?.data?.data?.[source_table]?.forEach((form) => {
          const start_range_data = form[selected_range['start_range']]
          const end_range_data = form[selected_range['end_range']]

          if (
            !checkUniqueInRange(
              start_range_data,
              end_range_data,
              formData[selected_range['start_range']],
              formData[selected_range['end_range']],
              formData,
              form
            )
          ) {
            uniqueValidationFailedFromClient = true
          }
        })
      }
    })

    if (uniqueValidationFailedFromClient) {
      return {
        validation: false,
        message: 'Oops! It seems that this data already exists. Please check your entry and make sure its unique',
        field_id: field_array,
      }
    }

    payload = {
      ...payload,
      data: rangeData,
    }

    const result = await request('unique-validation', 'POST', payload)
    let resultToSend = {
      validation: true,
      message: '',
      field_id: field_array,
    }

    if (result.data?.success) {
    } else {
      resultToSend['validation'] = false
      resultToSend['message'] = result.data?.message
    }

    return resultToSend
  } else if (validation?.component_name === 'unique_with') {
    let payload = {
      parent_table_row_id,
      parent_table,
      source_table,
      data: {},
      id,
      component_name: 'unique_with',
      validation_on: validation?.validation_on,
    }

    if (!validation?.validation_on?.includes(field_id)) {
      return {
        validation: true,
        message: '',
      }
    }
    let all_values = true
    validation?.validation_on?.map((item) => {
      payload['data'][item] = formData[item]
      if (!formData[item]) {
        all_values = false
      }
    })
    if (!all_values) {
      return {
        validation: true,
        message: '',
      }
    }

    let uniqueValidationFailedFromClient = false

    queues?.forEach((queue) => {
      if (queue.source_table === source_table) {
        queue?.payload?.data?.data?.[source_table]?.forEach((form) => {
          validation?.validation_on?.map((item) => {
            if (payload['data'][item] === form[item]) {
              uniqueValidationFailedFromClient = true
            }
          })
        })
      }
    })

    if (uniqueValidationFailedFromClient) {
      return {
        validation: false,
        message: 'Oops! It seems that this data already exists. Please check your entry and make sure its unique',
        field_id: validation?.validation_on,
      }
    }

    const result = await request('unique-validation', 'POST', payload)
    let resultToSend = {
      validation: true,
      message: '',
      field_id: validation?.validation_on,
    }

    if (result.data?.success) {
    } else {
      resultToSend['validation'] = false
      resultToSend['message'] = result.data?.message
    }

    return resultToSend
  } else if (validation?.component_name === 'pair_with') {
    const fields = validation?.validation_on[type]
    if (!fields?.includes(field_id)) {
      return {
        validation: true,
        message: '',
      }
    }

    const field1Value = formData[fields?.[0]]
    const field2Value = formData[fields?.[1]]

    if (field1Value && field2Value) {
      const isDateValidated = compareTwoValueByFieldType(field1Value, field2Value, type)

      if (isDateValidated) {
        return {
          validation: true,
          message: '',
        }
      } else {
        return {
          validation: false,
          message: `${tidyName(fields?.[0])} should be less than ${tidyName(fields?.[1])} `,
          field_id: fields,
        }
      }
    } else {
      return {
        validation: true,
        message: '',
      }
    }
  }

  return {
    validation: true,
    message: '',
  }
}

const checkSelectedDay = (formData, days) => {
  const data = days || ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'bank']
  const selectedDay = []
  data?.forEach((day) => {
    if (formData[day]) {
      selectedDay?.push(day)
    }
  })
  return selectedDay
}

export const FormDataValidation = async (formData, validation, parent_table_row_id, parent_table, source_table, id) => {
  const queues = store.getState().Queue.queues

  if (validation?.component_name === 'unique_in_range') {
    let payload = {
      parent_table_row_id,
      parent_table,
      source_table,
      id,
      data: {},
      component_name: 'unique_in_range',
    }

    const promises = validation?.range?.map(async (range) => {
      let selected_range = range

      payload = {
        ...payload,
        range: selected_range,
      }

      const field_array = [selected_range['start_range'], selected_range['end_range']]
      let rangeData = {}

      rangeData[selected_range['start_range']] = formData[selected_range['start_range']]
      rangeData[selected_range['end_range']] = formData[selected_range['end_range']]
      if (
        !formData[selected_range['end_range']] ||
        !formData[selected_range['start_range']] ||
        (range?.has_days && checkSelectedDay(formData)?.length === 0)
      ) {
        return {
          validation: true,
          message: '',
        }
      }

      if (range?.has_days && checkSelectedDay(formData)?.length > 0) {
        payload = {
          ...payload,
          days: checkSelectedDay(formData),
        }
      }
      console.log('promise result::::::;', formData, validation, parent_table_row_id, parent_table, source_table, id)
      if (range?.has_time && range?.has_time?.start_range && range?.has_time?.end_range) {
        if (!formData[range?.has_time?.start_range] || !formData[range?.has_time?.end_range]) {
          return {
            validation: true,
            message: '',
          }
        }
        payload = {
          ...payload,

          has_time: {
            start_range: formData[range?.has_time?.start_range],
            end_range: formData[range?.has_time?.end_range],
          },
        }
      }

      let uniqueValidationFailedFromClient = false

      queues?.forEach((queue) => {
        if (queue.source_table === source_table) {
          queue?.payload?.data?.data?.[source_table]?.forEach((form) => {
            const start_range_data = form[selected_range['start_range']]
            const end_range_data = form[selected_range['end_range']]

            if (
              !checkUniqueInRange(
                start_range_data,
                end_range_data,
                formData[selected_range['start_range']],
                formData[selected_range['end_range']],
                formData,
                form,
                range
              )
            ) {
              uniqueValidationFailedFromClient = true
            }
          })
        }
      })

      if (uniqueValidationFailedFromClient) {
        return {
          validation: false,
          message: 'Oops! It seems that this data already exists. Please check your entry and make sure its unique',
          field_id: field_array,
        }
      }

      payload = {
        ...payload,
        data: rangeData,
      }

      const result = await request('unique-validation', 'POST', payload)
      let resultToSend = {
        validation: true,
        message: '',
        field_id: field_array,
      }

      if (result.data?.success) {
      } else {
        resultToSend['validation'] = false
        resultToSend['message'] = result.data?.message
      }

      return resultToSend
    })

    if (promises) {
      const results = Promise.all(promises)

      return results
    }
  } else if (validation?.component_name === 'unique_with') {
    let payload = {
      parent_table_row_id,
      parent_table,
      source_table,
      data: {},
      id,
      component_name: 'unique_with',
      validation_on: validation?.validation_on,
    }

    let all_values = true
    validation?.validation_on?.map((item) => {
      payload['data'][item] = formData[item]
      if (!formData[item]) {
        all_values = false
      }
    })
    if (!all_values) {
      return {
        validation: true,
        message: '',
      }
    }

    let uniqueValidationFailedFromClient = false

    queues?.forEach((queue) => {
      if (queue.source_table === source_table) {
        queue?.payload?.data?.data?.[source_table]?.forEach((form) => {
          validation?.validation_on?.map((item) => {
            if (payload['data'][item] === form[item]) {
              uniqueValidationFailedFromClient = true
            }
          })
        })
      }
    })

    if (uniqueValidationFailedFromClient) {
      return {
        validation: false,
        message: 'Oops! It seems that this data already exists. Please check your entry and make sure its unique',
        field_id: validation?.validation_on,
      }
    }

    const result = await request('unique-validation', 'POST', payload)
    let resultToSend = {
      validation: true,
      message: '',
      field_id: validation?.validation_on,
    }

    if (result.data?.success) {
    } else {
      resultToSend['validation'] = false
      resultToSend['message'] = result.data?.message
    }

    return resultToSend
  } else if (validation?.component_name === 'pair_with') {
    if (validation?.validation_on && Object.keys(validation?.validation_on)?.length > 0) {
      const results = Object.keys(validation?.validation_on)?.map((type) => {
        const fields = validation?.validation_on[type]

        const field1Value = formData[fields?.[0]]
        const field2Value = formData[fields?.[1]]

        if (field1Value && field2Value) {
          const isDateValidated = compareTwoValueByFieldType(field1Value, field2Value, type)

          if (isDateValidated) {
            return {
              validation: true,
              message: '',
            }
          } else {
            return {
              validation: false,
              message: `${tidyName(fields?.[0])} should be less than ${tidyName(fields?.[1])} `,
              field_id: fields,
            }
          }
        } else {
          return {
            validation: true,
            message: '',
          }
        }
      })

      return results
    } else {
      return {
        validation: true,
        message: '',
      }
    }
  } else {
    return {
      validation: true,
      message: '',
    }
  }
}

export const FormDataValidationOnSubmit = (formData, validations) => {
  const result = validations?.map((validation) => {
    if (validation?.component_name === 'required_one') {
      let isDataExist = false
      validation?.validation_on?.forEach((field) => {
        if (formData?.[field]) {
          isDataExist = true
        }
      })
      if (isDataExist) {
        return {
          validation: true,
          message: '',
        }
      } else {
        return {
          validation: false,
          message: 'Oops! it seems day is not selected. Please select atleast one day',
        }
      }
    } else {
      return {
        validation: true,
        message: '',
      }
    }
  })
  return result
}
export const checkAndClearQueueForGivenSourceTable = (source_table) => {
  if (!source_table) return
  const queues = store.getState().Queue.queues
  const newQueues = []

  const promise = queues?.forEach((queue) => {
    if (queue?.source_table === source_table) {
      const result = request(queue?.url, 'POST', queue?.payload?.data)
    } else {
      newQueues?.push({ ...queue })
    }
  })

  store.dispatch(setQueueAfterCRUDops(newQueues))
}

export const getFormTypeToRemoveByName = (name) => {
  const grid = ['form_grid', 'basic_grid', 'advanced_main_grid', 'advanced_edit_main_grid', 'advanced_grid']
  const form = ['static_form', 'dynamic_form']

  if (grid?.includes(name)) {
    return 'grid'
  } else if (grid?.includes(name)) {
    return 'form'
  }
}

export const addDropdownOptionRefetchTable = (source_table) => {
  const tables = store.getState().CMS.dropdownOptionRefetchTable
  const new_tables = [...tables]
  new_tables?.push(source_table)
  store.dispatch(setDropdownOptionRefetchTable(new_tables))
}

export const removeDropdownOptionRefetchTable = (source_table) => {
  const tables = store.getState().CMS.dropdownOptionRefetchTable
  const new_tables = []
  tables?.forEach((table) => {
    if (table !== source_table) {
      new_tables?.push(table)
    }
  })
  store.dispatch(setDropdownOptionRefetchTable(new_tables))
}

export const getHomePageDropdownOptions = () => {
  const nav = store.getState().CMS.coreData.nav

  const options = []

  nav?.forEach((item) => {
    if (typeof item === 'object') {
      item?.items?.forEach((page) => {
        options?.push({
          value: page?.page,
          label: cms(page?.cms),
        })
      })
    }
  })

  return options
}
export const getCustomColorByUuid = (value) => {
  if (value && value.includes('uuid-')) {
    const theme_colors = store.getState().CMS.themeColors
    const sel_color = theme_colors.find((data) => `uuid-${data.uuid}` == value)
    if (sel_color) return sel_color
  }
  return false
}

export const renderBlockColorClass = (colorClass) => {
  const color = getCustomColorByUuid(colorClass)
  return color ? (color.is_dark ? 'sidebar-text-light' : 'sidebar-text-dark') : colorClass
}

export const renderTextColorClass = (colorClass) => {
  const color = getCustomColorByUuid(colorClass)
  return color ? '' : colorClass
}

export const renderBlockStyle = (colorUuid) => {
  const blockColor = getCustomColorByUuid(colorUuid)
  return blockColor ? { backgroundColor: blockColor.color } : {}
}

export const renderBlockColorStyle = (colorUuid = 'bg-euro sidebar-text-light') => {
  const blockColor = getCustomColorByUuid(colorUuid)
  return blockColor ? { color: blockColor.color } : {}
}

export const setPageCrashedStatus = (status) => {
  store.dispatch(changePageCrashedStatus(status))
}

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}

// {
//     "edit_labels": [
//         {
//             "permission_id": "3e3d1e50-746c-4dc2-a4f4-5995a5eaf88b",
//             "name": "edit_labels_view",
//             "permissible_type": "App\\Models\\Cms\\CmsSetting",
//             "permissible_name": "edit_labels",
//             "permission": "view",
//             "enabled": true
//         }
//     ],
//     "edit_cms": [
//         {
//             "permission_id": "ba200ead-ad14-4bf3-8317-fd1f38699702",
//             "name": "edit_cms_view",
//             "permissible_type": "App\\Models\\Cms\\CmsSetting",
//             "permissible_name": "edit_cms",
//             "permission": "view",
//             "enabled": true
//         }
//     ],
//     "manage_permission": [
//         {
//             "permission_id": "dd942489-ba7b-4868-a438-e8f37ba0c22b",
//             "name": "manage_permission_view",
//             "permissible_type": "App\\Models\\Cms\\CmsSetting",
//             "permissible_name": "manage_permission",
//             "permission": "view",
//             "enabled": true
//         }
//     ]
// }

export const checkCMSPermission = () => {
  const user = store?.getState()?.User?.user
  const publicUser = store?.getState()?.User?.publicUser

  let currentUserPermission = publicUser?.cmsSettingPermission || user?.cmsSettingPermission

  let permission = currentUserPermission?.edit_cms?.find((item) => item?.permission === 'view')

  if (permission?.enabled) {
    return true
  } else {
    return false
  }
}

export const checkEditLabelPermission = () => {
  const user = store?.getState()?.User?.user
  const publicUser = store?.getState()?.User?.publicUser

  let currentUserPermission = publicUser?.cmsSettingPermission || user?.cmsSettingPermission

  let permission = currentUserPermission?.edit_labels?.find((item) => item?.permission === 'view')

  if (permission?.enabled) {
    return true
  } else {
    return false
  }
}

export const checkPermissionToEditPermission = () => {
  const user = store?.getState()?.User?.user
  const publicUser = store?.getState()?.User?.publicUser

  let currentUserPermission = publicUser?.cmsSettingPermission || user?.cmsSettingPermission

  let permission = currentUserPermission?.manage_permission?.find((item) => item?.permission === 'view')

  if (permission?.enabled) {
    return true
  } else {
    return false
  }
}


export function getObjectDifference(obj1, obj2) {
  const differences = {}
  const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)])
  keys.forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      differences[key] = { obj1: obj1[key], obj2: obj2[key] }
    }
  })
  return differences
}



export function isUUID(str) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidRegex.test(str)
}

export function isNumeric(str) {
  // Regular expression to check if string contains only digits
  return /^[0-9]+$/.test(str)
}
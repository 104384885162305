import { useEffect, useState } from 'react'

import CMSRichText from '../richText'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Button, Collapse, ListGroup, ListGroupItem, UncontrolledCollapse } from 'reactstrap'
import { updatePublicPageCMS } from '../../../Helper/publicPageHelper'
import { tidyName } from '../../../helper'

const CMSCard = ({ setCardEditedContent, card, index }) => {
  const [formData, setFormData] = useState([])

  useEffect(() => {
    const newState = card?.child_widget_data
      ?.map((item) => {
        let type = 'rich_text'
        if (item?.component_name === 'button_text' || item?.component_name === 'icon') {
          type = 'input'
        }
        if (item?.component_name === 'image' || !item?.component_name || item?.component_name === 'single_card') {
          return
        }
        return {
          component_name: item?.component_name,
          value: item?.value,
          uuid: item?.uuid,
          type,
          title: tidyName(item?.component_name),
        }
      })
      ?.filter((item) => item)

    setCardEditedContent((data) => {
      data[index] = newState
      return data
    })

    setFormData(newState)
  }, [])

  useEffect(() => {
    setCardEditedContent((oldData) => {
      oldData[index] = formData
      return oldData
    })
  }, [JSON.stringify(formData)])

  const onChangeValue = (uuid, v) => {
    setFormData((od) => {
      let newData = od?.map((oldData) => {
        if (oldData?.uuid !== uuid) {
          return oldData
        } else {
          return {
            ...oldData,
            value: v,
          }
        }
      })

      return newData
    })
  }
  return (
    <div className='p-4' style={{ border: '1px solid #00000044', borderBottom: 'none' }}>
      {formData?.map((item) => {
        if (item?.type === 'rich_text') {
          return (
            <CMSRichText
              data={{ field_id: item?.uuid }}
              id={item?.uuid}
              title={item?.title}
              value={item?.value}
              onChange={(v) => {
                onChangeValue(item?.uuid, v)
                // setFormData((val) => ({
                //   ...val,
                //   heading: v,
                // }))
              }}
            />
          )
        } else {
          return (
            <AvField name={item?.uuid} label={item?.title} value={item?.value} onChange={(e) => onChangeValue(item?.uuid, e.target.value)}></AvField>
          )
        }
      })}
    </div>
  )
}

const CMSCarousal = ({ widget_uuid, data, toggleModal }) => {
  const [formData, setFormData] = useState([])
  const [cardData, setCardData] = useState([])
  const [cardEditedContent, setCardEditedContent] = useState([])
  const [isCollapseOpen, setIsCollapseOpen] = useState('edit_cms_carousal')

  useEffect(() => {
    const newCardState = data?.filter((item) => item?.component_name === 'text')

    setCardData(newCardState)
  }, [data])

  const onSave = () => {
    let dataToSend = []
    cardEditedContent?.map((item) => {
      if (Array.isArray(item)) {
        dataToSend = [...dataToSend, ...item]
      }
    })
    let payload = {
      uuid: widget_uuid,
      data: dataToSend,
    }
    updatePublicPageCMS(payload)
  }
  console.log('cms card content:::::::::', data, formData)
  return (
    <div>
      <div className='d-flex justify-conent-between'>
        <div className='font-weight-bold'>Edit Content</div>
      </div>
      <hr></hr>
      <AvForm>
        <ListGroup>
          <div className='p-1 mt-2 mb-2'>
            {cardData?.map((card, index) => {
              return (
                <>
                  <ListGroupItem id={'card_cms_edit' + index} className='editCMSMainTitle font-weight-bold'>
                    Carousal {index + 1}
                  </ListGroupItem>
                  <UncontrolledCollapse toggler={'#' + 'card_cms_edit' + index}>
                    <CMSCard card={card} setCardEditedContent={setCardEditedContent} index={index} />
                  </UncontrolledCollapse>
                </>
              )
            })}
          </div>
        </ListGroup>
      </AvForm>
      <div className='d-flex justify-content-end mt-4'>
        <Button
          outline
          color='danger'
          className='mr-2'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal()
          }}>
          Discard
        </Button>
        <Button color='info' onClick={() => onSave()}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default CMSCarousal

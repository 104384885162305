import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../Redux/Sagas/requests/api'
import { storePublicPageData } from '../../Redux/Reducers/publicPageData'

const fetchPublicPageData = async (page_uuid, publicPageData) => {
  if (publicPageData[page_uuid]) {
    return publicPageData[page_uuid]
  } else {
    const res = await request(`get-page/${page_uuid}`)
    if (res && res.status === 200) {
      return res.data
    } else {
      throw new Error('Failed to fetch public page data')
    }
  }
}

export const useFetchPage = (page_uuid) => {
  const dispatch = useDispatch()
  const publicPageData = useSelector((state) => state.publicPageData)

  return useQuery(['publicPageData', page_uuid], () => fetchPublicPageData(page_uuid, publicPageData), {
    onSuccess: (data) => {
      let newData = publicPageData ? { ...publicPageData } : {}
      newData[page_uuid] = data
      dispatch(storePublicPageData(newData))
    },
  })
}

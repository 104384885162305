import { useEffect, useRef } from 'react'
import useOutsideClick from '../../CustomHook/useOutsideClick'
import './cmsWidget.scss'

const CMSWidgetModal = ({ children, sx = {}, sxArrow = {}, toggleModal = () => {} }) => {
  const modalRef = useRef(null)

  useOutsideClick(modalRef, () => {
    toggleModal()
  })

  useEffect(() => {
    const element = document.getElementById('cms-edit-modal')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
      // element.scrollTo(0, 1000)
    }
  }, [])

  let { ...modalStyle } = sx

  return (
    <div
      className='shadow-lg p-3 bg-white rounded position-absolute'
      style={{ ...modalStyle, zIndex: 100, borderRadius: '10px' }}
      ref={modalRef}
      id='cms-edit-modal'>
      <div className='arrow'></div>
      {children}
    </div>
  )
}

export default CMSWidgetModal

import { useQuery } from 'react-query'
import axios from 'axios'
import { request } from '../../Redux/Sagas/requests/api'
import { store } from '../../Redux/Config/configureStore'
import { updateEmailTemplateWidgetDesign } from '../../Redux/Reducers/emailTemplate'

const fetchData = async (postData) => {
  // const emailTemplateDesign = store.getState().EmailTemplate?.email_template_widget_design
  // if (emailTemplateDesign[postData]) {
  //   return emailTemplateDesign[postData]
  // }
  // const response = await axios.post('https://api.example.com/data', postData)
  const response = await request('email-preview', 'POST', { uuid: postData })
  // if (response.data) {
  //   let data = {
  //     uuid: postData,
  //     data: response?.data,
  //   }
  //   store.dispatch(updateEmailTemplateWidgetDesign(data))
  // }

  return response.data?.data
}

const useGetTemplateDesign = (postData) => {
  const query = useQuery(['fetchEmailTemplateDesign', postData], () => fetchData(postData), {
    enabled: !!postData,
    onSuccess: (data) => {
      
    },
  })

  return {
    data: query.data,
    error: query.error,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
  }
}

export default useGetTemplateDesign

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { primary_color } from '../../../Helper/uiHelper'
import PageHeader from '../../Permissions/PageHeader'

const RefundSuccess = () => {
  const history = useHistory()
  return (
    <div>
      <PageHeader text={'Refund'}></PageHeader>
      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '20rem' }}>
        <div style={{ width: '30rem' }}>
          <div className='success-pulse mb-5'>
            <i className={'position-absolute ' + 'fa-solid fa-check'} style={{ color: 'white', scale: '0.45' }}></i>
          </div>
          <div className='font-weight-bold body1 text-center mb-3' style={{ color: primary_color }}>
            Successful !
          </div>
          <div className='text-muted text-center subtitle1'>
            The refund request has been successfully approved, and an email has been sent to the customer.
          </div>
          <div className='d-flex justify-content-center align-items-center mt-4'>
            <button className='btn text-white rounded-pill mt-2' type='submit' style={{ background: primary_color, fontWeight: 700, width: '14rem' }} onClick={()=>{
              history.push('/purchase-list')
            }}>
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RefundSuccess

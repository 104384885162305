import { primary_color } from "../../Helper/uiHelper";

const RowTitle = ({ title }) => {
  return (
    <div className={`permission-header`} style={{ color: primary_color }}>
      {title}
    </div>
  )
}

export default RowTitle;
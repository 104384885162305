import React from 'react'
import { LoginForm } from './PublicLogin'
import { primary_color } from '../../../Helper/uiHelper'

const Login = ({ showValidation, setShowValidation }) => {
  // return <LoginForm hideForgotPassword={true}></LoginForm>
  return (
    <div>
      <div className='h4 font-weight-bold mb-4 h4' style={{ color: primary_color }}>
        Login now!
      </div>
      <LoginForm showValidation={showValidation} setShowValidation={setShowValidation} hideForgotPassword={true}></LoginForm>
    </div>
  )
}

export default Login

export const SET_NAV = 'SET_NAV'
export const CLEAR_NAV = 'CLEAR_NAV'

export const setNav = (navigations) => ({
  type: SET_NAV,
  navigations,
})

export const clearNav = () => ({
  type: CLEAR_NAV,
})

const initialState = {
  FooterNav: [],
  HeaderNav: [],
  InnerSidebarNav: [],
  lastUpdated: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_NAV:
      console.log('set nav:::::::::', action)
      const { navigations } = action
      return { ...navigations, lastUpdated: new Date() }
    case CLEAR_NAV:
      return initialState
    default:
  }
  return state
}

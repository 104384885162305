import { useState } from 'react'
import { useFetchLogsByTable } from '../../../Queries/Logs/useFetchLogsByTable'
import CreatedLog from './createdLog'
import UpdatedLog from './updatedLog'
import UpdatedRole from './updatedRole'
import Loader from 'react-loaders'

const renderLog = (type, data, onExpand, expandedId, openRoleDetails) => {
  switch (type) {
    case 'create':
      return <CreatedLog data={data} onExpand={onExpand} expandedId={expandedId} openRoleDetails={openRoleDetails}></CreatedLog>
    case 'update':
      return <UpdatedLog data={data} onExpand={onExpand} expandedId={expandedId}></UpdatedLog>
    case 'role_update':
      return <UpdatedRole data={data} onExpand={onExpand} expandedId={expandedId} openRoleDetails={openRoleDetails}></UpdatedRole>
    default:
      return
  }
}

const PermissionLogs = ({ role_id, setActiveTab }) => {
  const { data, isLoading } = useFetchLogsByTable(role_id, 'roles_with_permisson')

  const [expandedId, setExpandedId] = useState()
  console.log('logs data::::::::::', expandedId)

  const onExpand = (id) => {
    setExpandedId(id)
  }
    if (!isLoading && data?.data?.length === 0)
      return (
        <div style={{ marginTop: '3rem' }} className='text-center font-weight-bold'>
          Logs not available!
        </div>
      )
    if (data?.data?.length !== 0 && isLoading)
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '10rem', alignItems: 'center' }}>
          <Loader type='ball-pulse' color='blue' />
        </div>
      )
  return (
    <div>
      {data?.data?.map((item, index) => {
        if (!expandedId) {
          return (
            <div key={index} className='p-3'>
              {renderLog(item?.type, item, onExpand, null, setActiveTab)}
            </div>
          )
        } else {
          if (expandedId === item?.id) {
            return (
              <div key={index} className='p-3'>
                {renderLog(item?.type, item, onExpand, expandedId)}
              </div>
            )
          } else {
            return <></>
          }
        }
      })}
    </div>
  )
}

export default PermissionLogs

import { useState } from 'react'
import { primary_color } from '../../Helper/uiHelper'
import './changepassword.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { request } from '../../Redux/Sagas/requests/api'
import ProfileBanner from './profileBanner'

const ChangePassword = ({ setPage }) => {
  const dispatch = useDispatch()
  const publicUser = useSelector((state) => state.User.publicUser)

  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()

  const onSubmitPassword = () => {
    if (password !== confirmPassword) {
      dispatch(setNotification({ type: 'error', message: 'Password & confirm password should be same' }))
      return
    } else {
      request('/change-password','POST', { password, confirm_password: confirmPassword, email: publicUser?.email })
    }
  }
  return (
    <>
      <ProfileBanner heading='<h2>Change Password</h2>' />
      <div className='d-flex align-items-center justify-content-center p-5' style={{ height: '50vh', width: '100vw', overflowX: 'hidden' }}>
        <div className='d-flex' style={{ flexDirection: 'column', gap: '30px', width: '40vw' }}>
          <h6 className='h6' style={{ fontWeight: 'bolder', color: primary_color }}>
            Reset Password
          </h6>
          <div className='d-flex' style={{ flexDirection: 'column', gap: '40px' }}>
            <div class='group'>
              <input placeholder='' className='input subtitle1' required type='text' onChange={(e) => setPassword(e.target.value)} />
              <span class='highlight'></span>
              <span class='bar'></span>
              <label className='label subtitle1'>New Password</label>
            </div>
            <div class='group'>
              <input placeholder='' className='input subtitle1' required type='text' onChange={(e) => setConfirmPassword(e.target.value)} />
              <span class='highlight'></span>
              <span class='bar'></span>
              <label className='label subtitle1'>Confirm Password</label>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <button onClick={() => setPage('default')} className='btn btn-outline-danger rounded-pill' style={{paddingInline:'4rem'}}>
              Cancel
            </button>
            <button
              style={{ background: primary_color, color: '#fff', paddingInline:'4rem' }}
              className='btn btn-primary rounded-pill'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onSubmitPassword()
              }}>
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangePassword

import { useState } from 'react'
import { primary_color } from '../../Helper/uiHelper'

const ProfilePicDropdown = ({ isProfilePicUploaded, onRemoveProfilePic }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div>
      {!isProfilePicUploaded ? (
        <i
          className='fa-solid fa-pencil text-light rounded-circle fs-6 cursor-pointer position-absolute'
          style={{ background: primary_color, padding: '0.7rem', top: 0, right: 0 }}></i>
      ) : (
        <>
          {isDropdownOpen ? (
            <>
              {' '}
              <i
                className='fa-solid fa-chevron-up text-light rounded-circle fs-6 cursor-pointer position-absolute'
                style={{ background: primary_color, padding: '0.7rem', top: 0, right: 0 }}
                onClick={(e) => {
                  setIsDropdownOpen(false)
                  e.stopPropagation()
                  e.preventDefault()
                }}></i>
              <div
                className='d-flex flex-column justify-content-center align-items-center p-4 position-absolute shadow'
                style={{ background: '#fff', borderRadius: '10px', top: 30, right: -100 }}>
                <div
                  className='mb-3 cursor-pointer'
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsDropdownOpen(false)
                    onRemoveProfilePic()
                  }}>
                  Remove
                </div>
                <div
                  className='mb-3 cursor-pointer'
                  onClick={(e) => {
                    setIsDropdownOpen(false)
                    // e.stopPropagation()
                    // e.preventDefault()
                    // const element = document.getElementById('')
                  }}>
                  Crop
                </div>
                <div
                  className='mb-3 cursor-pointer'
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsDropdownOpen(false)
                    const element = document.getElementById('upload_profile_pic_direct')
                    element?.click()
                  }}>
                  Replace
                </div>
              </div>
            </>
          ) : (
            <>
              <i
                className='fa-solid fa-chevron-down text-light rounded-circle fs-6 cursor-pointer position-absolute'
                style={{ background: primary_color, padding: '0.7rem', top: 0, right: 0 }}
                onClick={(e) => {
                  setIsDropdownOpen(true)
                  e.stopPropagation()
                  e.preventDefault()
                }}></i>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ProfilePicDropdown

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../Helper/cookieHelper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'

const EditPurchase = () => {
  const [showIframe, setShowIframe] = useState(false)
  const [pageUUID, setPageUUID] = useState()

  const dispatch = useDispatch()
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const navigation = useSelector((state) => state.Navigations)
  const user = useSelector((state) => state.User.user)

  let domain_id, payment_id
  if (currentRow?.[0]) {
    domain_id = currentRow?.[0]?.domain_id
    payment_id = currentRow?.[0]?.id
  }

  useEffect(() => {
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
    let current_storage_timer = storage_timer?.[domain_id]
    let bookingPage = navigation?.HeaderNav?.find((item) => item?.route === 'book-my-locker-list')
    request(`get-page/${bookingPage?.page_uuid}`).then((res) => {
      let id = res?.data?.child_pages?.[0]?.uuid
      setPageUUID(id)

      if (current_storage_timer?.session_uuid) {
        request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET').then((res) => {
          createReservation()
        })
      } else {
        createReservation()
      }
    })

    return () => {
      request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET')
      removeLocalStorageItem('timer')
    }
  }, [])

  const createReservation = () => {
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
    let current_storage_timer = storage_timer?.[domain_id]

    let data = {}
    const new_session_id = 'BOOKING-SESSION-' + uuidv4()
    let payload = {
      session_key: new_session_id,
      reservation_widget: 12,
      domain_id: domain_id,
      payment_id,
    }
    let current_row_timer = {
      session_id: new_session_id,
    }
    request('create-reservation', 'POST', payload).then((res) => {
      current_row_timer = {
        ...current_row_timer,
        time: new Date().toString(),
        session_uuid: res?.data?.data?.uuid,
        duration: 72000,
      }
      data[domain_id] = current_row_timer
      setLocalStorageItem('timer', JSON.stringify(data))
      setShowIframe(true)
      // let interval = setInterval(() => {
      //   setTimeLeft((prevTimeLeft) => {
      //     if (prevTimeLeft <= 0) {
      //       request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET')
      //       clearInterval(interval)
      //       onTimeExpire()
      //       return null
      //     }
      //     return prevTimeLeft - 1
      //   })
      // }, 1000)
    })
  }
  let link = `${window.location.origin}/book-my-locker-list?parent_id=${domain_id}&page_id=${pageUUID}&isShowOnlyContent=true&payment_id=${payment_id}&edit_booking=true`
  if (currentRow?.[0]?.customer_email !== user?.email && (user?.role?.name === 'Admin' || user?.role?.name == 'SuperAdmin')) {
    link = `${window.location.origin}/book-my-locker-list?parent_id=${domain_id}&page_id=${pageUUID}&isShowOnlyContent=true&payment_id=${payment_id}&edit_booking=true&is_admin=true`
  }
  if (!showIframe) return
  return (
    <div className='p-5'>
      <iframe style={{ height: '80vh', width: '100%', border: 'none' }} src={link} title='description'></iframe>
    </div>
  )
}

export default EditPurchase

import { useEffect, useRef, useState } from 'react'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import { cms } from '../../../helper'
import { Button } from 'reactstrap'
import { formatTimeFromSeconds } from '../../../Helper/paymentHelper'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { request } from '../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { useSelector } from 'react-redux'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSResendEmail from '../../CMSWidget/EmailDetails/cmsResendEmail'

const dummy_subheading = 'You can resend mail after '
const ResendEmail = ({ data, pageData, setActivePage, widget_uuid }) => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const payment_uuid = urlParams.get('payment_uuid')

  const editMode = useSelector((state) => state.CMS.editMode)
  const sectionRef = useRef()

  const [modalPosition, setModalPosition] = useState()

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        top: 250,
        // left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '40vw',
      })
    }
  }

  const onButtonClick = (item) => {
    let payload = {
      payment_uuid,
      component_name: 'email_button',
    }
    request('generate-pdf-and-create-message-log', 'POST', payload, {}, {}, {}, { responseType: 'arraybuffer' }).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
        setNotification({
          type: 'success',
          message: res?.data?.message || 'Email sent succeessfully',
        })
        setEmailTimer(180)
        // setActivePage(activePage.child_pages[0])
      }
    })
  }

  const isDesktop = useMediaQuery('(min-width:768px)')
  const [emailTimer, setEmailTimer] = useState(180)
  useEffect(() => {
    if (emailTimer > 0)
      setTimeout(() => {
        setEmailTimer((prev) => prev - 1)
      }, 1000)
  }, [emailTimer])

   const onCloseModal = () => {
     let element = sectionRef?.current
     if (element) {
       element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
     }
     setModalPosition(null)
   }

  let heading = data?.heading || '<h5><b>Still didn’t receive email ?</b></h5>'
  return (
    <div className='' style={{ marginTop: '3rem', padding: isDesktop ? '0 0 0 6rem' : '1rem' }} ref={sectionRef} onClick={handleSectionClick}>
      <div
        className=' text-left font-weight-bold body1 mb-4'
        style={{ color: primary_color, fontSize: '24px' }}
        dangerouslySetInnerHTML={{ __html: heading }}></div>
      <div className='subtitle1' style={{ color: '#8E8E8E' }}>
        {data?.sub_heading ? cms(data?.sub_heading) : dummy_subheading}{' '}
        <span className='font-weight-bold' style={{ color: primary_color }}>
          {formatTimeFromSeconds(emailTimer)}
        </span>
      </div>
      <div>
        <Button
          className='my-2 mt-4 mr-2 font-weight-bold primary_button subtitle1 rounded-pill'
          disabled={emailTimer > 0 ? true : false}
          style={{
            backgroundColor: primary_color,
            // borderColor: primary_color,
            borderRadius: '30px',
            paddingInline: '3.5rem',
            // fontSize: '14px',
            width: isDesktop ? '17rem' : '95%',
          }}
          onClick={() => onButtonClick('email')}>
          <i className='fa-solid mr-2 fa-envelope'></i> Resend email
        </Button>
      </div>
      <div>
        <Button
          className='my-5 mr-2 font-weight-bold primary_button subtitle1 rounded-pill'
          onClick={() => {
            setActivePage(pageData)
            // history.push('/home-page')
          }}
          style={{
            backgroundColor: primary_color,
            borderColor: primary_color,
            borderRadius: '30px',
            paddingInline: '5rem',
            width: isDesktop ? '17rem' : '95%',
            color: '#fff',
          }}>
          Go back <i class='fa-solid fa-arrow-right ml-2'></i>
        </Button>
      </div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSResendEmail data={data} toggleModal={() => onCloseModal()} widget_uuid={widget_uuid} />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default ResendEmail

import moment from 'moment'
import { primary_color } from '../../../Helper/uiHelper'

const CreatedLog = ({ data, openRoleDetails }) => {
  const updatedData = data?.updated_data && JSON.parse(data?.updated_data)
  return (
    <div className='d-flex align-items-center'>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            openRoleDetails(0)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>{`The role ${updatedData?.name} is created by ${data?.updated_by}`}</div>
      {data?.updated_at && <div>{moment(data?.updated_at).format('MMM D  YYYY, hh:mm')}</div>}
    </div>
  )
}

export default CreatedLog

import { AvCheckbox, AvCheckboxGroup } from 'availity-reactstrap-validation'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { BiFilter } from 'react-icons/bi'
import { Form } from 'reactstrap'
import { cms, tidyName } from '../../helper'
import MultiCheckBox from './WidgetFilterType/multiCheckBox'
import { primary_color } from '../../Helper/uiHelper'
import CMSWidgetModal from '../CMSWidget/cmsWidgetModal'
import CMSHeroSearch from '../CMSWidget/HeroSearch/cmsHeroSearch'
import CMSSidebarFilter from '../CMSWidget/SidebarWidget/cmsSidebarFilter'
import './Scss/filter.scss'
import { useSelector } from 'react-redux'

const RenderFilters = ({ searchData, groupData, form, filterData, setFilterData }) => {
  const type = groupData?.component_name

  switch (type) {
    case 'multi_checkbox':
      return <MultiCheckBox searchData={searchData} data={groupData} form={form} filterData={filterData} setFilterData={setFilterData} />
    default:
      return null
  }
}

const SearchFilters = ({ searchData, data, filterData, setFilterData, widget_uuid }) => {
  const sectionRef = useRef(null)

  const [modalPosition, setModalPosition] = useState()
  const editMode = useSelector((state) => state.CMS.editMode)

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        //  top: rect.top + window.scrollY - 50,
        top: rect.height + 30,

        //  left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '60vw',
      })
    }
  }
  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  return (
    <Fragment>
      <div
        className={`d-flex flex-column pt-3 pb-3 mt-4 filter-container ${modalPosition && 'active-cms-edit'}`}
        style={{ flex: '1', background: '#ffffff', minHeight: '0', borderRadius: '10px' }}
        ref={sectionRef}
        onClick={handleSectionClick}>
        <div className='d-flex align-items-center mb-2 ' style={{ color: primary_color }}>
          <div>
            {' '}
            <i className={data?.icon}></i>
          </div>

          <div className='ml-3 subtitle1 filter-header' dangerouslySetInnerHTML={{ __html: data?.heading }}></div>
          {/* <span className='ml-3 subtitle1'>Filter</span> */}
        </div>
        <div className='p-1 mb-2'>
          {data?.form?.[0]?.component_call_fields?.map((field, index) => {
            return (
              <RenderFilters
                searchData={searchData}
                groupData={field}
                key={index}
                form={data?.form?.[0]}
                filterData={filterData}
                setFilterData={setFilterData}
              />
            )
          })}
        </div>
        {modalPosition && (
          <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
            <CMSSidebarFilter
              data={data}
              toggleModal={() => {
                onCloseModal()
              }}
              widget_uuid={widget_uuid}
            />
          </CMSWidgetModal>
        )}
      </div>
    </Fragment>
  )
}

export default SearchFilters

import IconSet from '../../CMSComponent/icon'
import { primary_color } from '../../Helper/uiHelper'

const PageHeader = ({ text, icon }) => {
  return (
    <div
      className='page-header d-flex align-items-center w-100'
      style={{ background: `url(https://test-api.ionicbox.com/storage/files/2/bgimg.jpg)`, backgroundSize: 'contain', minHeight: 168 }}>
      <div className='page-header-inner' style={{ backgroundColor: 'rgba(255,255,255,0.6' }}>
        {/* <i class='fa-duotone fa-solid fa-file-chart-pie'></i> */}
        <IconSet icon={icon ? icon : 'fa-solid fa-file-chart-pie'} color={'blue'} style={{ fontSize: 25 }} />
        <h1 className='h1' style={{ color: primary_color, fontWeight: 800 }}>
          {text}
        </h1>
      </div>
    </div>
  )
}

export default PageHeader

import useMediaQuery from '../../../CustomHook/useMediaQuery'
import SuccessImg from '../../../assets/utils/payment-success.png'
const PaymentSuccessImage = ({ data }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  return (
    <div style={{ padding: isDesktop ? '0 0 0 20%' : '0', margin: isDesktop ? '1.5rem 0 0 0' : '1.5rem 0rem 2rem 0' }}>
      {data?.image ? (
        <img src={data?.image} style={{ width: '100%', height: 'auto' }} className='my-4' />
      ) : (
        <img src={SuccessImg} style={{ width: '100%', height: 'auto' }} className='my-4' />
      )}
    </div>
  )
}

export default PaymentSuccessImage
